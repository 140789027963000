import { type AutocompleteRenderGetTagProps, Checkbox, TextField, useTheme } from "@mui/material";
import MuiAutocomplete, { type AutocompleteRenderInputParams } from "@mui/material/Autocomplete";
import { type AutocompleteRenderOptionState } from "@mui/material/Autocomplete/Autocomplete";
import { omit } from "ramda";
import React, { type ReactNode } from "react";
import { type AutocompleteOptionModel } from "../types/generic";

/**
 * Types
 */
export interface AutocompleteProps {
  label: string;
  multiple?: boolean;
  error?: boolean;
  helperText?: string;
  value?: AutocompleteOptionModel | AutocompleteOptionModel[] | null;
  options: AutocompleteOptionModel[];
  onChange: (option: AutocompleteOptionModel[] | AutocompleteOptionModel | null) => void;
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: AutocompleteOptionModel,
    state: AutocompleteRenderOptionState,
  ) => React.ReactNode;
  disableCloseOnSelect?: boolean;
  limitTags?: number;
  onInputChange?: (event: React.ChangeEvent<any>, value: string, reason: string) => void;
  noOptionsText?: string;
  groupBy?: (option: AutocompleteOptionModel) => string;
  renderTags?: (
    value: AutocompleteOptionModel[],
    getTagProps: AutocompleteRenderGetTagProps,
  ) => ReactNode;
}

const Autocomplete: React.FC<AutocompleteProps> = ({
  multiple,
  label,
  options,
  value,
  onChange,
  error,
  helperText,
  renderOption,
  disableCloseOnSelect,
  limitTags,
  onInputChange,
  noOptionsText,
  groupBy,
  renderTags,
}: AutocompleteProps) => {
  const theme = useTheme();

  const inputValue = typeof value === "string" ? value : undefined;

  const renderers = {
    label: (option: AutocompleteOptionModel) => {
      return option?.title ?? option?.name ?? option.id;
    },
    input: (params: AutocompleteRenderInputParams) => {
      return (
        <TextField
          {...params}
          error={error}
          helperText={helperText}
          label={label}
          variant="outlined"
          InputProps={omit(["endAdornment"], params.InputProps)}
        />
      );
    },
  };

  const handleGroupSelect = (groupName: string, isSelected: boolean) => {
    const optionsInGroup = options.filter((option) => option.groupName === groupName);

    const addGroupToSelection = (currentValue: AutocompleteOptionModel[]) => {
      return [...currentValue, ...optionsInGroup];
    };

    const removeGroupFromSelection = (currentValue: AutocompleteOptionModel[]) => {
      return currentValue.filter((option) => !optionsInGroup.some((o) => o.id === option.id));
    };

    let newSelectedOptions: AutocompleteOptionModel[] | AutocompleteOptionModel | null = [];

    if (Array.isArray(value)) {
      newSelectedOptions = isSelected
        ? addGroupToSelection(value)
        : removeGroupFromSelection(value);
    } else {
      newSelectedOptions = isSelected ? optionsInGroup[0] : null;
    }

    onChange(newSelectedOptions);
  };

  return (
    <MuiAutocomplete
      disablePortal
      multiple={multiple}
      options={options}
      value={value}
      inputValue={inputValue}
      renderInput={renderers.input}
      getOptionLabel={renderers.label}
      renderOption={renderOption ?? undefined}
      isOptionEqualToValue={(option, value) => {
        if (Array.isArray(option) || Array.isArray(value)) {
          return false;
        }
        return option.id === value.id;
      }}
      onChange={(_, selectedOptions) => {
        // @ts-expect-error This is not array of arrays
        onChange(selectedOptions);
      }}
      disableCloseOnSelect={disableCloseOnSelect}
      limitTags={limitTags}
      onInputChange={onInputChange}
      noOptionsText={noOptionsText}
      renderTags={renderTags}
      groupBy={groupBy ?? undefined}
      renderGroup={(params) => {
        const isSelected = Array.isArray(value)
          ? options
              .filter((option) => option.groupName === params.group)
              .every((option) => value.some((v) => v.id === option.id))
          : value?.groupName === params.group;

        return (
          <div key={params.key}>
            <li
              style={{
                backgroundColor: theme.palette.primary.dark,
                color: theme.palette.secondary.contrastText,
              }}
            >
              <Checkbox
                checked={isSelected}
                color="secondary"
                id={params.group}
                onChange={(e) => {
                  handleGroupSelect(params.group, e.target.checked);
                }}
              />
              {params.group}
            </li>
            {params.children}
          </div>
        );
      }}
    />
  );
};

export default Autocomplete;
