import { Box, CircularProgress } from "@mui/material";
import { not } from "ramda";
import React from "react";

import { Spacing } from "../types/enum";
import { useAdminGetGroupedOccupationsQuery } from "../types/graphql";

import UsersFilters from "../components/UsersFilters";

interface Props {
  filters: any;
  loading: boolean;
  expanded: boolean;
  clearSelection: () => void;
}

const AdvancedSearch: React.FC<Props> = ({ filters, loading, expanded, clearSelection }: Props) => {
  const { data, loading: loadingOccupations } = useAdminGetGroupedOccupationsQuery({
    fetchPolicy: "cache-and-network",
  });

  const occupationOptions = data?.adminGetGroupedOccupations
    ? data?.adminGetGroupedOccupations.map((occupation) => ({
        id: occupation?.id,
        name: occupation?.name,
        groupName: occupation.industry?.name,
      }))
    : [];

  if (loadingOccupations) {
    return <CircularProgress />;
  }

  if (not(expanded)) {
    return null;
  }

  return (
    <Box mb={Spacing.l}>
      <UsersFilters
        filters={filters}
        loading={loading}
        occupationOptions={occupationOptions}
        clearSelection={clearSelection}
      />
    </Box>
  );
};

export default AdvancedSearch;
