import { yupResolver } from "@hookform/resolvers/yup";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Select,
  SvgIcon,
  TextField,
  type Theme,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import get from "get-value";
import { useSnackbar } from "notistack";
import { isNil, not } from "ramda";
import React, { useEffect } from "react";
import { Trash2 as DeleteIcon } from "react-feather";
import {
  Controller,
  type ControllerRenderProps,
  type SubmitHandler,
  type UseFormStateReturn,
  useForm,
} from "react-hook-form";
import { useNavigate } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import * as yup from "yup";

import {
  AdminGetBannerDocument,
  AdminGetSlideDocument,
  ImageAlignment,
  type SliderModel,
  TextColour,
  TitleStyle,
  useAdminDeleteSliderMutation,
  useAdminEditSlideMutation,
} from "src/types/graphql";
import { Sizing, Spacing } from "../types/enum";
import { type AttachmentModel } from "../types/generic";

import FileDropzone from "../components/FileDropzone";
import SliderPageSlideTag from "../components/SliderPageSliderTag";

/**
 * Types
 */
interface Props {
  slide: SliderModel;
  slideNumber?: number;
  loading: boolean;
}

/**
 * Constants
 */
const DEFAULT_SLIDE_NUMBER = 1;

const OPTIONS_TYPE = new Map<string, any>([
  ["imageAlignment", ImageAlignment],
  ["textColour", TextColour],
  ["titleStyle", TitleStyle],
]);

/**
 * Schema
 */
const schema = yup.object().shape({
  background: yup
    .object({
      mobile: yup.string().required(),
      desktop: yup.string().required(),
    })
    .required(),
  buttonText: yup.string().nullable(),
  buttonURL: yup.string().nullable(),
  featureImage: yup.string().nullable(),
  imageAlignment: yup.string().required(),
  subtitle: yup.string().nullable(),
  textColour: yup.string().required(),
  title: yup.string().nullable(),
  titleStyle: yup.string().required(),
});
type FormValues = yup.InferType<typeof schema>;

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) => ({
  delete: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
  stretch: {
    height: "100%",
  },
}));

const EditSlideFormCont: React.FC<Props> = ({ slide, loading, slideNumber }: Props) => {
  const classes = useStyles();

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [deleteSlider] = useAdminDeleteSliderMutation({
    refetchQueries: [
      {
        query: AdminGetBannerDocument,
        variables: {
          id: slide.banner.id,
        },
      },
    ],
  });

  const [editSlide] = useAdminEditSlideMutation({
    refetchQueries: [
      {
        query: AdminGetSlideDocument,
        variables: {
          id: slide.id,
        },
      },
    ],
  });

  const deleteSlide = async () => {
    const variables = {
      id: slide.id,
    };

    try {
      await deleteSlider({ variables });
      navigate(`/sliders/${slide.banner.id}/edit`);
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const defaultValues = {
    background: slide.background,
    buttonText: slide.buttonText,
    buttonURL: slide.buttonURL,
    featureImage: slide.featureImage,
    imageAlignment: slide.imageAlignment,
    subtitle: slide.subtitle,
    textColour: slide.textColour,
    title: slide.title,
    titleStyle: slide.titleStyle,
  };

  const { register, control, handleSubmit, formState, trigger } = useForm<FormValues>({
    mode: "all",
    resolver: yupResolver(schema),
    // @ts-expect-error GraphQL types have to be narrowed
    defaultValues,
  });

  const onSubmit: SubmitHandler<FormValues> = async (form): Promise<void> => {
    const variables = {
      id: slide.id,
      input: {
        background: form?.background,
        buttonText: form?.buttonText,
        buttonURL: form?.buttonURL,
        featureImage: form?.featureImage,
        imageAlignment: form?.imageAlignment,
        subtitle: form?.subtitle,
        textColour: form?.textColour,
        title: form?.title,
        titleStyle: form?.titleStyle,
      },
    };

    try {
      await editSlide({ variables });
      navigate(-1);
      enqueueSnackbar("Slide was updated successfully", { variant: "success" });
    } catch (_error) {
      enqueueSnackbar("There was a problem updating the slide", { variant: "error" });
    }
  };

  useEffect(() => {
    void trigger();
  }, [trigger]);

  if (loading) {
    return <CircularProgress />;
  }

  const renderers = {
    select: <TName extends keyof FormValues>({
      field: { value, onChange, name },
    }: {
      field: ControllerRenderProps<FormValues, TName>;
    }) => {
      const options = Object.values<string>(OPTIONS_TYPE.get(name));
      return (
        <Select
          fullWidth
          value={value as string}
          variant="outlined"
          onChange={({ target }) => {
            onChange(target.value);
          }}
        >
          {options.map((option: string) => (
            <MenuItem value={option} key={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      );
    },
    placeholder: () => {
      return (
        <Box>
          <Typography variant="h5" color="textPrimary" align="center">
            Select files
          </Typography>
          <Typography variant="body2" color="textSecondary" align="center">
            Drag and drop file here
          </Typography>
          <Typography variant="body2" color="textSecondary" align="center">
            Format is 4:3 ratio
          </Typography>
        </Box>
      );
    },
    file: <TName extends "background.desktop" | "background.mobile" | "featureImage">({
      field: { value, onChange, name },
      formState,
    }: {
      field: ControllerRenderProps<FormValues, TName>;
      formState: UseFormStateReturn<FormValues>;
    }) => {
      const isMissing = not(isNil(get(formState?.errors, name)));
      return (
        <FileDropzone
          preview
          showFileContained
          placeholder={renderers.placeholder()}
          fileName={value ?? undefined}
          error={isMissing ? "This field is required" : undefined}
          onDropFile={(attachment: AttachmentModel) => {
            onChange(attachment?.url);
          }}
        />
      );
    },
  };

  return (
    <>
      <Box mb={Spacing.m}>
        <Box display="flex" alignItems="center">
          <Link color="inherit" component={RouterLink} to={`/sliders/${slide.banner.id}/edit`}>
            <Box mr={Spacing.sm}>
              <ArrowBackIcon />
            </Box>
          </Link>
          <Box ml={Spacing.sm} display="flex" justifyContent="space-between">
            <Typography variant="h3" color="textPrimary">
              {slide.banner.title}
            </Typography>
          </Box>
        </Box>
      </Box>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={Spacing.m}>
          <Grid item xs={Sizing.Full} lg={Sizing.TwoThirds}>
            <Card className={classes.stretch}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box p={Spacing.m}>
                  <SliderPageSlideTag slideNumber={slideNumber ?? DEFAULT_SLIDE_NUMBER} />
                </Box>
                <Box mr={Spacing.m}>
                  <IconButton onClick={deleteSlide} size="large">
                    <SvgIcon fontSize="small">
                      <DeleteIcon />
                    </SvgIcon>
                  </IconButton>
                </Box>
              </Box>

              <Divider />
              <Box p={Spacing.l}>
                <Typography variant="h5" color="textPrimary">
                  Title
                </Typography>
                <Box mt={Spacing.s} mb={Spacing.l}>
                  <FormControl fullWidth>
                    <TextField
                      variant="outlined"
                      {...register("title")}
                      placeholder="Title"
                      error={not(isNil(formState?.errors?.title))}
                      helperText={
                        not(isNil(formState?.errors?.title)) ? "This field is required" : undefined
                      }
                    />
                  </FormControl>
                </Box>

                <Typography variant="h5" color="textPrimary">
                  Subtitle
                </Typography>
                <Box mt={Spacing.s} mb={Spacing.l}>
                  <FormControl fullWidth>
                    <TextField
                      variant="outlined"
                      {...register("subtitle")}
                      placeholder="Subtitle"
                      error={not(isNil(formState?.errors?.subtitle))}
                      helperText={
                        not(isNil(formState?.errors?.title)) ? "This field is required" : undefined
                      }
                    />
                  </FormControl>
                </Box>

                <Grid container spacing={Spacing.m}>
                  <Grid item xs={Sizing.Full} lg={Sizing.Half}>
                    <Typography variant="h5" color="textPrimary">
                      Button Text
                    </Typography>
                    <Box mt={Spacing.s} mb={Spacing.l}>
                      <FormControl fullWidth>
                        <TextField
                          variant="outlined"
                          {...register("buttonText")}
                          placeholder="Button Text(leave it blank if button is not required)"
                        />
                      </FormControl>
                    </Box>
                  </Grid>

                  <Grid item xs={Sizing.Full} lg={Sizing.Half}>
                    <Typography variant="h5" color="textPrimary">
                      Button URL
                    </Typography>
                    <Box mt={Spacing.s} mb={Spacing.l}>
                      <FormControl fullWidth>
                        <TextField
                          variant="outlined"
                          {...register("buttonURL")}
                          placeholder="Button URL"
                        />
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>

                <Grid container spacing={Spacing.m}>
                  <Grid item xs={Sizing.Full} lg={Sizing.Half}>
                    <Typography variant="h5" color="textPrimary">
                      Background - Desktop
                    </Typography>
                    <Box mt={Spacing.s}>
                      <Controller
                        name="background.desktop"
                        control={control}
                        render={renderers.file}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={Sizing.Full} lg={Sizing.Half}>
                    <Typography variant="h5" color="textPrimary">
                      Background - Mobile
                    </Typography>
                    <Box mt={Spacing.s}>
                      <Controller
                        name="background.mobile"
                        control={control}
                        render={renderers.file}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Grid>

          <Grid item xs={Sizing.Full} lg={Sizing.OneThird}>
            <Card className={classes.stretch}>
              <Box p={Spacing.l}>
                <Typography variant="h5" color="textPrimary">
                  Feature Image
                </Typography>
                <Box mt={Spacing.s} mb={Spacing.l}>
                  <Controller name="featureImage" control={control} render={renderers.file} />
                </Box>

                <Typography variant="h5" color="textPrimary">
                  Title Style
                </Typography>
                <Box mt={Spacing.s}>
                  <Typography variant="body2" color="textSecondary">
                    Choose standard or bold
                  </Typography>
                </Box>

                <Box mt={Spacing.s} mb={Spacing.l}>
                  <Controller name="titleStyle" control={control} render={renderers.select} />
                </Box>

                <Typography variant="h5" color="textPrimary">
                  Text Colour
                </Typography>
                <Box mt={Spacing.s}>
                  <Typography variant="body2" color="textSecondary">
                    Choose light or dark text
                  </Typography>
                </Box>

                <Box mt={Spacing.s} mb={Spacing.l}>
                  <Controller name="textColour" control={control} render={renderers.select} />
                </Box>

                <Typography variant="h5" color="textPrimary">
                  Image Alignment
                </Typography>
                <Box mt={Spacing.s}>
                  <Typography variant="body2" color="textSecondary">
                    Choose feature image side
                  </Typography>
                </Box>

                <Box mt={Spacing.s}>
                  <Controller name="imageAlignment" control={control} render={renderers.select} />
                </Box>
              </Box>
            </Card>
          </Grid>

          <Grid item xs={Sizing.Full}>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              disabled={not(formState.isValid)}
              fullWidth
            >
              SAVE SLIDE
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default EditSlideFormCont;
