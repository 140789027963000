import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import { not } from "ramda";
import React from "react";
import { type SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";

import { CREATE_COVER } from "../gql/mutations/cover";
import { QUERY_COVERS } from "../gql/queries/cover";

import { Spacing } from "../types/enum";
import { type CreateCoverInput } from "../types/graphql";

/**
 * Types
 */
interface Props {
  open: boolean;
  onClose: () => void;
  limit: number;
  offset: number;
}

interface FormValues {
  title: string;
}

/**
 * Schema
 */
const schema = yup.object().shape({
  title: yup.string().required(),
});

const CoversPageCreateCont: React.FC<Props> = ({ open, onClose, limit, offset }: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const { register, formState, handleSubmit } = useForm<FormValues>({
    mode: "all",
    resolver: yupResolver(schema),
  });

  const [createCover, { loading }] = useMutation(CREATE_COVER, {
    refetchQueries: [{ query: QUERY_COVERS, variables: { filters: { limit, offset } } }],
  });

  const onSubmit: SubmitHandler<FormValues> = async (form: CreateCoverInput) => {
    const variables = {
      input: {
        title: form.title,
      },
    };
    try {
      await createCover({ variables });
      enqueueSnackbar("Cover successfully added", { variant: "success" });
      onClose();
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return (
    <Dialog maxWidth="lg" open={open} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Box mb={Spacing.sm}>
            <Typography variant="body1" color="textPrimary">
              Cover
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Please input the title of the cover that you would like to add.
            </Typography>
          </Box>
          <FormControl fullWidth>
            <TextField label="Title" variant="outlined" {...register("title")} />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit" disabled={not(formState.isValid) || loading}>
            Create
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CoversPageCreateCont;
