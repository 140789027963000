import { isNil } from "ramda";
import React from "react";
import { Trash2 as DeleteIcon, Edit as EditIcon } from "react-feather";
import { Link as RouterLink } from "react-router-dom";

import {
  Box,
  Grid,
  IconButton,
  Link,
  SvgIcon,
  TableCell,
  TableRow,
  type Theme,
  Typography,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { Sizing, Spacing } from "../types/enum";
import { type CoverModel, type ResponsiveType } from "../types/graphql";

import { useOnOffSwitch } from "../helpers/hooks";
import { truncateWithEllipses } from "../helpers/utils";

import ConfirmationModal from "./ConfirmationModal";
import Label from "./Label";

/**
 * Types
 */
interface Props {
  cover: CoverModel;
  onDelete: (coverId: string) => void;
}

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) => ({
  image: {
    width: "auto",
    height: "100%",
    display: "block",
    borderRadius: theme.shape.borderRadius,
  },
  placeholder: {
    width: "200px",
    height: "120px",
    padding: theme.spacing(Spacing.ml),
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.action.disabled}`,
  },
  item: {
    maxWidth: "250px",
  },
  box: {
    width: "200px",
    height: "120px",
    overflow: "hidden",
    objectFit: "cover",
    borderRadius: theme.shape.borderRadius,
  },
}));

const CoversPageCoverDetails: React.FC<Props> = ({ cover, onDelete }: Props) => {
  const [open, onOpen, onClose] = useOnOffSwitch();

  const classes = useStyles();
  const variants = [cover?.primary, cover?.secondary, cover?.tertiary];

  const renderers = {
    image: (image: string | null | undefined) => {
      if (isNil(image)) {
        return (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            className={classes.placeholder}
          >
            <Label variant="error">Missing image</Label>
          </Box>
        );
      }
      return (
        <Box className={classes.box}>
          <img src={image} alt={image} className={classes.image} />
        </Box>
      );
    },
    variant: (variant?: ResponsiveType | null | undefined) => {
      return (
        <Grid spacing={Spacing.s} container>
          <Grid item>
            <Typography variant="caption">Desktop</Typography>
            <Box mt={Spacing.s}>{renderers.image(variant?.desktop)}</Box>
          </Grid>

          <Grid item>
            <Typography variant="caption">Mobile</Typography>
            <Box mt={Spacing.s}>{renderers.image(variant?.mobile)}</Box>
          </Grid>
        </Grid>
      );
    },
  };

  return (
    <TableRow>
      <TableCell>
        <Label variant="info">{truncateWithEllipses(cover?.title)}</Label>
      </TableCell>

      <TableCell>
        <Grid spacing={Spacing.s} container>
          {variants.map((variant, index) => (
            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
            <Grid key={index} xs={Sizing.Full} md={Sizing.OneThird} className={classes.item} item>
              {renderers.variant(variant)}
            </Grid>
          ))}
        </Grid>
      </TableCell>

      <TableCell align="right">
        <Link component={RouterLink} to={`/covers/${cover.id}/edit`}>
          <IconButton size="large">
            <SvgIcon fontSize="small">
              <EditIcon />
            </SvgIcon>
          </IconButton>
        </Link>
        <IconButton onClick={onOpen} size="large">
          <SvgIcon fontSize="small">
            <DeleteIcon />
          </SvgIcon>
        </IconButton>
      </TableCell>

      <ConfirmationModal
        open={open}
        onSubmit={() => {
          onDelete(cover?.id);
        }}
        onClose={onClose}
      />
    </TableRow>
  );
};

export default CoversPageCoverDetails;
