import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  TextField,
  type Theme,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useLogout } from "src/hooks/useLogout";
import * as yup from "yup";
import { Spacing } from "../types/enum";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    minHeight: "100vh",
    flexDirection: "column",
    backgroundColor: theme.palette.background.dark,
  },
  card: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(Spacing.ml),
  },
}));

/**
 * Schema
 */
const schema = yup.object().shape({
  token: yup.string().length(6).required(),
});

interface FormValues {
  token: string;
}

export default function LoginWithSecondFactorPage() {
  const classes = useStyles();
  const navigate = useNavigate();
  const logoutMutation = useLogout();

  const submitTokenMutation = useMutation({
    mutationFn: async (token: string) => {
      const response = await fetch(`${import.meta.env.VITE_AUTH_URL}/login-otp`, {
        method: "POST",
        body: JSON.stringify({ token }),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message);
      }
      return response;
    },
    onSuccess: () => {
      navigate("/users");
    },
  });

  const { register, handleSubmit } = useForm<FormValues>({
    mode: "all",
    resolver: yupResolver(schema),
  });

  const onSubmit = async (form: any, _event: any): Promise<void> => {
    submitTokenMutation.mutate(form.token);
  };

  const tokenField = register("token");

  return (
    <div className={classes.root}>
      <Container maxWidth="sm">
        <Box mt={Spacing.xxl} display="flex" justifyContent="center" flexDirection="column">
          <Card>
            <CardContent className={classes.card}>
              <Box
                alignItems="center"
                display="flex"
                justifyContent="space-between"
                mb={Spacing.sm}
              >
                <Box>
                  <Typography color="textPrimary" gutterBottom variant="h2">
                    Verify
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Verify with authenticator app
                  </Typography>
                </Box>
              </Box>

              <form onSubmit={handleSubmit(onSubmit)}>
                <TextField
                  fullWidth
                  {...tokenField}
                  label="Token"
                  margin="normal"
                  type="string"
                  variant="outlined"
                />

                <Box mt={Spacing.sm} display="flex" justifyContent="space-between">
                  <Button
                    color="secondary"
                    disabled={submitTokenMutation.isPending}
                    fullWidth
                    size="large"
                    type="button"
                    variant="outlined"
                    onClick={() => logoutMutation.mutate()}
                  >
                    Cancel
                  </Button>

                  <Button
                    color="secondary"
                    disabled={submitTokenMutation.isPending}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    sx={{ marginLeft: 2 }}
                  >
                    Verify
                  </Button>
                </Box>

                {submitTokenMutation.isError ? (
                  <Box mt={Spacing.sm}>
                    <Alert severity="error">{(submitTokenMutation.error as any)?.message}</Alert>
                  </Box>
                ) : null}
              </form>
            </CardContent>
          </Card>
        </Box>
      </Container>
    </div>
  );
}
