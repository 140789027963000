import { Box, Divider, FormControl, FormLabel } from "@mui/material";
import React from "react";

import { Spacing } from "../types/enum";

const LessonEditPageSectionDivider: React.FC = () => {
  return (
    <FormControl fullWidth>
      <Box mb={Spacing.s}>
        <FormLabel>Divider</FormLabel>
      </Box>

      <Divider />
    </FormControl>
  );
};

export default LessonEditPageSectionDivider;
