import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import { not } from "ramda";
import React from "react";
import { Controller, type ControllerRenderProps, useForm } from "react-hook-form";
import * as yup from "yup";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import { CREATE_FLAG } from "../gql/mutations/flag";
import { QUERY_FLAGS } from "../gql/queries/flag";

import { Sizing, Spacing } from "../types/enum";
import { type CreateFeatureFlagInput } from "../types/graphql";

/**
 * Types
 */
interface Props {
  open: boolean;
  onClose: () => void;
  limit: number;
  offset: number;
}

/**
 * Schema
 */
const schema = yup.object().shape({
  label: yup.string().required(),
  active: yup.boolean().required(),
});
type FormValues = yup.InferType<typeof schema>;

const FlagsPageCreateCont: React.FC<Props> = ({ open, onClose, limit, offset }: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const { register, control, formState, handleSubmit } = useForm<FormValues>({
    mode: "all",
    resolver: yupResolver(schema),
  });

  const [createFlag, { loading }] = useMutation(CREATE_FLAG, {
    refetchQueries: [
      {
        query: QUERY_FLAGS,
        variables: {
          filters: {
            limit,
            offset,
          },
        },
      },
    ],
  });

  const renderers = {
    switch: ({
      field: { value, onChange },
    }: { field: ControllerRenderProps<FormValues, "active"> }) => {
      return (
        <Switch
          edge="start"
          checked={value}
          color="secondary"
          onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
            onChange(target.checked);
          }}
        />
      );
    },
  };

  const onSubmit = async (form: CreateFeatureFlagInput) => {
    const variables = {
      input: {
        label: form.label,
        active: form.active,
      },
    };
    try {
      await createFlag({ variables });
      enqueueSnackbar("Flag successfully added", { variant: "success" });
      onClose();
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return (
    <Dialog maxWidth="lg" open={open} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={Spacing.m}>
            <Grid item xs={Sizing.Full}>
              <Typography variant="body1" color="textPrimary">
                Flag
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Please input the label of the feature flag that you would like to add.
              </Typography>

              <Box mt={Spacing.sm}>
                <TextField label="Label" variant="outlined" {...register("label")} fullWidth />
              </Box>
            </Grid>

            <Grid item xs={Sizing.Full}>
              <Typography variant="h5" color="textPrimary">
                Active
              </Typography>
              <Typography variant="body2" color="textSecondary">
                This will mark the feature flag as active
              </Typography>

              <Controller name="active" control={control} render={renderers.switch} />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit" disabled={not(formState.isValid) || loading}>
            Create
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default FlagsPageCreateCont;
