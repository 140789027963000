import { useMutation, useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";

import { DELETE_THEME } from "src/gql/mutations/theme";
import { FILTER_THEMES } from "../gql/queries/theme";

import { DEFAULT_OFFSET, ROWS_PER_PAGE_DEFAULT, ROWS_PER_PAGE_OPTIONS } from "../helpers/const";

import { Spacing } from "../types/enum";
import { type ThemeModel } from "../types/graphql";

import { useOnOffSwitch, usePagination } from "../helpers/hooks";

import ThemesPageThemeDetails from "../components/ThemesPageThemeDetails";
import ThemesPageCreateThemeCont from "../containers/ThemesPageCreateThemeCont";

interface Props {
  isStandard?: boolean;
  isTopicLibrary?: boolean;
  isResource?: boolean;
  isGrammarLibrary?: boolean;
}

const ThemesPage: React.FC<Props> = ({
  isStandard,
  isTopicLibrary,
  isGrammarLibrary,
  isResource,
}) => {
  const [open, onOpen, onClose] = useOnOffSwitch();
  const [offset, setOffset] = useState<number>(DEFAULT_OFFSET);
  const [limit, setLimit] = useState<number>(ROWS_PER_PAGE_DEFAULT);

  const filters = {
    isStandard,
    isTopicLibrary,
    isGrammarLibrary,
    isResource,
    limit,
    offset,
  };

  const [deleteTheme] = useMutation(DELETE_THEME, {
    refetchQueries: [{ query: FILTER_THEMES, variables: { filters } }],
  });

  const { enqueueSnackbar } = useSnackbar();

  const { loading, data } = useQuery(FILTER_THEMES, {
    fetchPolicy: "network-only",
    variables: {
      filters,
    },
  });
  const { page, rowsPerPage, items, onChangePage, onChangeRowsPerPage } = usePagination<ThemeModel>(
    data?.adminListPaginatedThemes.items,
  );

  useEffect(() => {
    setOffset(page * rowsPerPage);
  }, [page, rowsPerPage]);

  useEffect(() => {
    setLimit(rowsPerPage);
  }, [rowsPerPage]);

  if (loading) {
    return <CircularProgress />;
  }

  const onDelete = async (themeId: string): Promise<any> => {
    const variables = {
      id: themeId,
    };

    try {
      await deleteTheme({ variables });
      enqueueSnackbar("Theme successfully deleted", { variant: "success" });
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return (
    <>
      <Box mb={Spacing.m} display="flex" alignItems="center" justifyContent="space-between">
        <Button variant="contained" color="secondary" onClick={onOpen}>
          Create theme
        </Button>
      </Box>

      <Card>
        {loading ? (
          <Box display="flex" justifyContent="center" p={Spacing.xxl}>
            <CircularProgress />
          </Box>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox />
                </TableCell>
                <TableCell>Title</TableCell>
                <TableCell>ID</TableCell>
                <TableCell>Active</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((theme: ThemeModel) => (
                <ThemesPageThemeDetails
                  onDelete={() => onDelete(theme.id)}
                  key={theme.id}
                  theme={theme}
                />
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  page={page}
                  count={data?.adminListPaginatedThemes.total}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={onChangeRowsPerPage}
                  rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                  onPageChange={onChangePage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        )}
      </Card>

      <ThemesPageCreateThemeCont
        open={open}
        onClose={onClose}
        limit={limit}
        offset={0}
        isStandard={filters.isStandard}
        isTopicLibrary={filters.isTopicLibrary}
        isResource={filters.isResource}
        isGrammarLibrary={filters.isGrammarLibrary}
      />
    </>
  );
};

export default ThemesPage;
