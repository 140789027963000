import { useQuery } from "@apollo/client";
import { Box, CircularProgress, Divider, Tab, Tabs } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";
import { Outlet, useMatch } from "react-router-dom";

import { Spacing } from "../types/enum";

import { QUERY_ARTICLES } from "../gql/queries/article";
import { ArticleOrderBy } from "../types/graphql";

const ArticleLayout: React.FC = () => {
  const navigate = useNavigate();
  const match = useMatch("/articles/:pageName/*");
  const pageName = match?.params.pageName ?? "published";

  const { loading } = useQuery(QUERY_ARTICLES, {
    variables: {
      filters: {
        isDraft: false,
      },
      order: {
        by: ArticleOrderBy.Published,
        sortAsc: false,
      },
      fetchPolicy: "network-only",
    },
  });

  const onChange = (_: React.ChangeEvent<unknown>, nextPageName: string) => {
    navigate(`/articles/${nextPageName}`);
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Box mt={Spacing.m}>
        <Tabs
          scrollButtons="auto"
          variant="scrollable"
          textColor="secondary"
          value={pageName}
          onChange={onChange}
        >
          <Tab label="Published" value="published" />
          <Tab label="Drafts" value="drafts" />
          <Tab label="Edit" value="edit" disabled />
        </Tabs>
      </Box>

      <Divider />

      <Box mt={Spacing.m}>
        <Outlet />
      </Box>
    </>
  );
};

export default ArticleLayout;
