import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import React from "react";
import { type UserModel, useGetInvitedFriendsStatsQuery } from "src/types/graphql";

/**
 * Types
 */
interface Props {
  user: UserModel;
}

const UserPageDetailsReferralActivityCard: React.FC<Props> = ({ user }: Props) => {
  const { data, loading } = useGetInvitedFriendsStatsQuery({
    variables: {
      userId: user.id,
    },
  });

  if (loading) {
    return <CircularProgress />;
  }

  const stats = data?.getInvitedFriendsStats;

  return (
    <Card>
      <CardHeader title="Referral Activity" />

      <Divider />

      <CardContent>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell variant="head">Trial offers accepted</TableCell>
              <TableCell>{stats?.invitedFriendsTotal ?? 0}</TableCell>
              <TableCell variant="head">Trials converted to paid</TableCell>
              <TableCell>{stats?.invitedPaidFriends ?? 0}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default UserPageDetailsReferralActivityCard;
