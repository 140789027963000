import React from "react";
import { useParams } from "react-router";
import { type BannerModel, useAdminGetBannerQuery } from "src/types/graphql";

import EditBannerFormCont from "../containers/EditBannerFormCont";

const BannerPage: React.FC = () => {
  const params = useParams<Record<string, string>>();

  const { data, loading } = useAdminGetBannerQuery({
    fetchPolicy: "network-only",
    variables: {
      id: params?.sliderId ?? "",
    },
  });

  if (data?.adminGetBanner) {
    return <EditBannerFormCont banner={data.adminGetBanner as BannerModel} loading={loading} />;
  }

  return <></>;
};

export default BannerPage;
