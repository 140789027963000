import React from "react";
import * as yup from "yup";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormLabel,
  TextField,
  Typography,
} from "@mui/material";

import CopyLinkToElement from "src/components/Topics/CopyLinkToElement.tsx";
import { useOnOffSwitch, useSortableList } from "../helpers/hooks";
import { Spacing } from "../types/enum";
import { type QuestionModel } from "../types/generic";

/**
 * Types
 */
interface Props {
  value: QuestionModel[];
  setValue: (questions: QuestionModel[]) => void;
  id: string;
  topicId: string;
  lessonPath: string;
  step?: number | null;
}

/**
 * Schema
 */
const schema = yup.object().shape({
  question: yup.string().required(),
  answer: yup.string(),
});

const LessonEditPageSectionQuestions: React.FC<Props> = ({
  value,
  setValue,
  id,
  topicId,
  lessonPath,
  step,
}: Props) => {
  const [open, onOpen, onClose] = useOnOffSwitch();

  const { renderer, register, handleSubmit, onSubmit } = useSortableList<QuestionModel>({
    schema,
    data: value,
    render: (item) => {
      return (
        <Box display="flex" flexDirection="column">
          <Box mb={Spacing.s}>
            <Typography variant="body2" color="textPrimary">
              {item.question}
            </Typography>
          </Box>
          <Typography variant="body2" color="textSecondary">
            {item.answer}
          </Typography>
        </Box>
      );
    },
    onAfterSubmit: onClose,
    setValue,
    keyFn: (item) => item.question + item.answer,
  });

  return (
    <FormControl fullWidth>
      <Box mb={Spacing.s} display="flex" alignItems="center" justifyContent="space-between">
        <FormLabel>Questions</FormLabel>
        <CopyLinkToElement id={id} topicId={topicId} lessonPath={lessonPath} step={step} />
      </Box>

      {renderer}

      <Box mt={Spacing.s}>
        <Button fullWidth variant="contained" onClick={onOpen}>
          Create question
        </Button>
      </Box>

      <Dialog open={open} onClose={onClose}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Box mb={Spacing.sm}>
              <Typography variant="body1" color="textPrimary">
                Question
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Please input the question that you would like the user to answer.
              </Typography>
            </Box>

            <Box mb={Spacing.sm}>
              <TextField
                fullWidth
                helperText="Required"
                variant="outlined"
                label="Question"
                {...register("question")}
              />
            </Box>

            <TextField
              fullWidth
              variant="outlined"
              helperText="Optional"
              label="Answer"
              {...register("answer")}
            />
          </DialogContent>

          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="submit">Create</Button>
          </DialogActions>
        </form>
      </Dialog>
    </FormControl>
  );
};

export default LessonEditPageSectionQuestions;
