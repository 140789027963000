import { useQuery } from "@apollo/client";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, CircularProgress, Link, Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router";
import { Link as RouterLink } from "react-router-dom";

import { QUERY_THEME_AND_COVERS } from "../gql/queries/theme";

import { truncateWithEllipses } from "../helpers/utils";
import { Spacing } from "../types/enum";

import ThemeEditPageFormCont from "../containers/ThemeEditPageFormCont";

const ThemeEditPage: React.FC = () => {
  const params = useParams<Record<string, string>>();

  const { data, loading } = useQuery(QUERY_THEME_AND_COVERS, {
    variables: {
      id: params?.themeId,
    },
    fetchPolicy: "network-only",
  });

  if (loading) {
    return <CircularProgress />;
  }

  const theme = data?.adminGetTheme;
  const covers = data?.adminListCovers;

  return (
    <>
      <Box display="flex" alignItems="center" mb={Spacing.m}>
        <Link color="inherit" component={RouterLink} to="/themes">
          <Box mr={Spacing.sm}>
            <ArrowBackIcon />
          </Box>
        </Link>

        <Typography variant="h3" color="textPrimary">
          {truncateWithEllipses(theme?.title)}
        </Typography>
      </Box>

      <ThemeEditPageFormCont theme={theme} covers={covers} />
    </>
  );
};

export default ThemeEditPage;
