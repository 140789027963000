import { yupResolver } from "@hookform/resolvers/yup";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Select,
  SvgIcon,
  Switch,
  type Theme,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useSnackbar } from "notistack";
import { inc } from "ramda";
import React from "react";
import { Trash2 as DeleteIcon, Edit as EditIcon } from "react-feather";
import {
  Controller,
  type ControllerRenderProps,
  type SubmitHandler,
  useForm,
} from "react-hook-form";
import { useNavigate } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import {
  AdminGetBannerDocument,
  AdminListPaginatedBannersDocument,
  type BannerModel,
  EventPermissions,
  useAdminCreateSliderMutation,
  useAdminDeleteBannerMutation,
  useAdminDeleteSliderMutation,
  useAdminEditBannerMutation,
  useAdminOrderSliderMutation,
} from "src/types/graphql";
import * as yup from "yup";

import {
  DEFAULT_OFFSET,
  MoveElement,
  ROWS_PER_PAGE_DEFAULT,
  SLIDER_AUDIENCE,
} from "../helpers/const";
import { useOnOffSwitch } from "../helpers/hooks";
import { Sizing, Spacing } from "../types/enum";

import ConfirmationModal from "../components/ConfirmationModal";
import SliderPageSlideTag from "../components/SliderPageSliderTag";

/**
 * Types
 */
interface Props {
  banner: BannerModel;
  loading: boolean;
}

/**
 * Schema
 */
const schema = yup.object().shape({
  isActive: yup.bool().required(),
  audience: yup
    .array()
    .required()
    .of(yup.mixed<EventPermissions>().oneOf(Object.values(EventPermissions)).required()),
  sliders: yup
    .array()
    .required()
    .of(
      yup.object().shape({
        id: yup.string().required(),
      }),
    ),
});
type FormValues = yup.InferType<typeof schema>;

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) => ({
  delete: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
  stretched: {
    height: "100%",
  },
}));

const EditBannerFormCont: React.FC<Props> = ({ banner, loading }: Props) => {
  const classes = useStyles();

  const [isOpen, open, close] = useOnOffSwitch();
  const history = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [deleteBanner] = useAdminDeleteBannerMutation({
    refetchQueries: [
      {
        query: AdminListPaginatedBannersDocument,
        variables: {
          filters: {
            limit: ROWS_PER_PAGE_DEFAULT,
            offset: DEFAULT_OFFSET,
          },
        },
      },
    ],
  });

  const [editBanner] = useAdminEditBannerMutation({
    refetchQueries: [
      {
        query: AdminGetBannerDocument,
        variables: {
          id: banner.id,
        },
      },
    ],
  });

  const [deleteSlider] = useAdminDeleteSliderMutation({
    refetchQueries: [
      {
        query: AdminGetBannerDocument,
        variables: {
          id: banner.id,
        },
      },
    ],
  });

  const [createSlider] = useAdminCreateSliderMutation({
    refetchQueries: [
      {
        query: AdminGetBannerDocument,
        variables: {
          id: banner.id,
        },
      },
    ],
  });

  const [editSliderOrder, { loading: loadingOrder }] = useAdminOrderSliderMutation({
    refetchQueries: [
      {
        query: AdminGetBannerDocument,
        variables: {
          id: banner.id,
        },
      },
    ],
  });

  const defaultValues = {
    isActive: banner.isActive,
    audience: banner.audience ?? [],
    sliders: banner.sliders?.map((slider) => ({ id: slider.id })) ?? [],
  };

  const { control, handleSubmit } = useForm<FormValues>({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onSubmit: SubmitHandler<FormValues> = async (form): Promise<void> => {
    const variables = {
      id: banner.id,
      input: {
        isActive: form.isActive,
        audience: form.audience,
      },
    };

    try {
      await editBanner({ variables });
      enqueueSnackbar("Banner was updated successfully", { variant: "success" });
    } catch (_error) {
      enqueueSnackbar("There was a problem updating the banner", { variant: "error" });
    }
  };

  const onDelete = async () => {
    const variables = {
      id: banner.id,
    };

    try {
      await deleteBanner({ variables });
      history("/sliders");
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const deleteSlide = async (id: string) => {
    const variables = {
      id,
    };

    try {
      await deleteSlider({ variables });
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const addSlide = async () => {
    const variables = {
      id: banner.id,
    };

    try {
      await createSlider({ variables });
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const onSort = async (sliderId: string, position: MoveElement) => {
    const variables = {
      id: sliderId,
      input: position,
    };

    try {
      await editSliderOrder({ variables });
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const renderers = {
    slider: (index: number, sliderId: string, title: string | undefined | null) => {
      const total = banner?.sliders?.length ?? 0;
      return (
        <Card>
          <Box display="flex" justifyContent="space-between" alignItems="center" p={Spacing.sm}>
            <Box display="flex" alignItems="center">
              <SliderPageSlideTag slideNumber={index + 1} title={title} />
            </Box>
            <Box display="flex" alignItems="center">
              <Box mr={Spacing.ml}>
                {index ? (
                  <IconButton
                    size="small"
                    onClick={() => onSort(sliderId, MoveElement.UP)}
                    disabled={loadingOrder}
                  >
                    <ArrowUpwardIcon fontSize="small" />
                  </IconButton>
                ) : null}
                {inc(index) < total ? (
                  <IconButton
                    size="small"
                    onClick={() => onSort(sliderId, MoveElement.DOWN)}
                    disabled={loadingOrder}
                  >
                    <ArrowDownwardIcon fontSize="small" />
                  </IconButton>
                ) : null}
              </Box>
              <Link component={RouterLink} to={`/slide/${index + 1}/${sliderId}/edit`}>
                <IconButton size="large">
                  <SvgIcon fontSize="small">
                    <EditIcon />
                  </SvgIcon>
                </IconButton>
              </Link>
              <IconButton onClick={() => deleteSlide(sliderId)} size="large">
                <SvgIcon fontSize="small">
                  <DeleteIcon />
                </SvgIcon>
              </IconButton>
            </Box>
          </Box>
        </Card>
      );
    },
    switch: ({
      field: { value, onChange },
    }: { field: ControllerRenderProps<FormValues, "isActive"> }) => {
      return (
        <Switch
          edge="start"
          checked={value}
          color="secondary"
          onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
            onChange(target.checked);
          }}
        />
      );
    },
    audience: ({
      field: { value, onChange },
    }: { field: ControllerRenderProps<FormValues, "audience"> }) => {
      return (
        <Select
          fullWidth
          value={JSON.stringify(value)}
          variant="outlined"
          onChange={({ target }) => {
            onChange(JSON.parse(target.value));
          }}
        >
          {Array.from(SLIDER_AUDIENCE.entries()).map(([key, optionValue]) => (
            <MenuItem value={JSON.stringify(key)} key={optionValue}>
              {optionValue}
            </MenuItem>
          ))}
        </Select>
      );
    },
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Box mb={Spacing.m}>
              <Box display="flex" alignItems="center">
                <Link color="inherit" component={RouterLink} to={"/sliders"}>
                  <Box mr={Spacing.sm}>
                    <ArrowBackIcon />
                  </Box>
                </Link>
                <Box ml={Spacing.sm} display="flex" justifyContent="space-between">
                  <Typography variant="h3" color="textPrimary">
                    {banner.title}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item>
            <Box display="flex" flexDirection="row">
              <Box>
                <Button
                  className={classes.delete}
                  onClick={open}
                  variant="contained"
                  color="secondary"
                >
                  Delete
                </Button>
              </Box>
              <Box ml={Spacing.sm}>
                <Button variant="contained" color="secondary" type="submit" disabled={loading}>
                  Save
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={Spacing.m}>
          <Grid item xs={Sizing.Full} md={Sizing.TwoThirds}>
            {banner.sliders?.map((slider, index) => (
              <Box mb={Spacing.sm} key={slider.id}>
                {renderers.slider(index, slider.id, slider.title)}
              </Box>
            ))}
            <Grid item xs={Sizing.Full}>
              <Button variant="contained" color="secondary" fullWidth onClick={addSlide}>
                ADD SLIDE
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={Sizing.Full} md={Sizing.OneThird}>
            <Card className={classes.stretched}>
              <Box p={Spacing.m}>
                <Typography variant="h5" color="textPrimary">
                  Active
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Display this slider on homepage
                </Typography>
                <Controller name="isActive" control={control} render={renderers.switch} />
              </Box>
              <Box p={Spacing.m}>
                <Typography variant="h5" color="textPrimary">
                  Audience
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Who should see this sliders
                </Typography>
                <Box mt={Spacing.s}>
                  <Controller name="audience" control={control} render={renderers.audience} />
                </Box>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </form>

      <ConfirmationModal open={isOpen} onClose={close} onSubmit={onDelete} />
    </>
  );
};

export default EditBannerFormCont;
