import { useSnackbar } from "notistack";
import React, { useState, useEffect } from "react";

import {
  Box,
  Button,
  Card,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";

import { DEFAULT_OFFSET, ROWS_PER_PAGE_DEFAULT, ROWS_PER_PAGE_OPTIONS } from "../helpers/const";
import { useOnOffSwitch, usePagination } from "../helpers/hooks";

import { Spacing } from "../types/enum";
import {
  AdminListPaginatedVideoSetsDocument,
  type VideoSetModel,
  useAdminDeleteVideoSetMutation,
  useAdminListPaginatedVideoSetsQuery,
} from "../types/graphql";

import VideosPageVideoSetDetails from "../components/VideosPageVideoSetDetails";
import VideosPageCreateUpdateVideoSetCont from "../containers/VideosPageCreateUpdateVideoSetCont";

/**
 * Constants
 */
const NO_RESULTS = 0;

const VideosPage: React.FC = () => {
  const [open, onOpen, onClose] = useOnOffSwitch();
  const [offset, setOffset] = useState<number>(DEFAULT_OFFSET);
  const [limit, setLimit] = useState<number>(ROWS_PER_PAGE_DEFAULT);
  const { enqueueSnackbar } = useSnackbar();

  const [deleteVideoSet] = useAdminDeleteVideoSetMutation({
    refetchQueries: [
      {
        query: AdminListPaginatedVideoSetsDocument,
        variables: {
          filters: {
            limit,
            offset,
          },
        },
      },
    ],
  });

  const { data, loading } = useAdminListPaginatedVideoSetsQuery({
    fetchPolicy: "network-only",
    variables: {
      filters: {
        limit,
        offset,
      },
    },
  });

  const { page, rowsPerPage, items, onChangePage, onChangeRowsPerPage } =
    usePagination<VideoSetModel>(data?.adminListPaginatedVideoSets?.items as VideoSetModel[]);

  const onDelete = async (id: string) => {
    const variables = {
      id,
    };

    try {
      await deleteVideoSet({ variables });
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  useEffect(() => {
    setOffset(page * rowsPerPage);
  }, [page, rowsPerPage]);

  useEffect(() => {
    setLimit(rowsPerPage);
  }, [rowsPerPage]);

  return (
    <>
      <Box mb={Spacing.m} display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <Typography variant="h3" color="textPrimary">
            Home Page Videos
          </Typography>
          <Typography variant="body2">
            Sets are displayed on the home page in the order they are listed below, to the audience
            you select for each set.
          </Typography>
        </Box>

        <Button variant="contained" color="secondary" onClick={onOpen}>
          Add Set
        </Button>
      </Box>

      <Card>
        {loading ? (
          <Box display="flex" justifyContent="center" p={Spacing.xxl}>
            <CircularProgress />
          </Box>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell size="medium">Title</TableCell>
                <TableCell align="right">Status</TableCell>
                <TableCell align="right">Last Saved</TableCell>
                <TableCell align="right">Change Order</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items?.map((videoSet: VideoSetModel, index) => (
                <VideosPageVideoSetDetails
                  key={videoSet?.id}
                  index={index}
                  total={data?.adminListPaginatedVideoSets?.items?.length || 0}
                  videoSet={videoSet}
                  onDelete={onDelete}
                  limit={limit}
                  offset={offset}
                />
              ))}
            </TableBody>

            <TableFooter>
              <TableRow>
                <TablePagination
                  page={page}
                  count={data?.adminListPaginatedVideoSets.total ?? NO_RESULTS}
                  onPageChange={onChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={onChangeRowsPerPage}
                  rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                />
              </TableRow>
            </TableFooter>
          </Table>
        )}
      </Card>

      {open ? (
        <VideosPageCreateUpdateVideoSetCont
          open={open}
          onClose={onClose}
          limit={limit}
          offset={0}
        />
      ) : null}
    </>
  );
};

export default VideosPage;
