import { Grid, type Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { isEmpty, not } from "ramda";
import React, { useCallback } from "react";

import { GridColumns } from "../helpers/const";
import { Sizing, Spacing } from "../types/enum";
import { type Element } from "../types/graphql";

import LessonEditPageSectionGrid from "./LessonEditPageSectionGrid";

/**
 * Types
 */
interface Props {
  value: Element[];
  setValue: (elements?: Array<Element | undefined>) => void;
  setElementMeta: (key: string, value: unknown) => void;
  parent?: Element;
  index: number;
  sectionId: string;
  showGridDescription?: boolean;
  id: string;
  topicId: string;
  lessonPath: string;
  step?: number | null;
}

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    borderWidth: "1px",
    borderStyle: "dashed",
    borderRadius: theme.shape.borderRadius,
    borderColor: theme.palette.action.disabled,
    padding: theme.spacing(Spacing.m),
  },
  option: {
    "&:hover": {
      background: theme.palette.action.active,
    },
  },
}));

const LessonEditPageSectionChooseLayout: React.FC<Props> = ({
  value,
  setValue,
  setElementMeta,
  parent,
  index,
  sectionId,
  showGridDescription,
  id,
  topicId,
  lessonPath,
  step,
}: Props) => {
  const classes = useStyles();

  const columnsLayout = parent?.meta?.columnsLayout;

  const updateLayout = useCallback(
    (chosenLayout: GridColumns) => {
      setElementMeta("columnsLayout", chosenLayout);
    },
    [setElementMeta],
  );

  if (not(isEmpty(value)) || columnsLayout) {
    return (
      <Grid container spacing={Spacing.m}>
        <Grid item xs={Sizing.Full}>
          <LessonEditPageSectionGrid
            setValue={setValue}
            value={value}
            columns={columnsLayout}
            index={index}
            sectionId={sectionId}
            showGridDescription={showGridDescription}
            id={id}
            topicId={topicId}
            lessonPath={lessonPath}
            step={step}
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <Grid container spacing={Spacing.m}>
        <Grid item xs={Sizing.Full}>
          <Typography variant="h5" color="textPrimary">
            Choose Layout
          </Typography>
        </Grid>
        <Grid item xs={Sizing.Full} lg={Sizing.Half}>
          <Grid
            container
            item
            onClick={() => {
              updateLayout(GridColumns.TwoEqualColumns);
            }}
            className={classes.option}
          >
            <Grid item xs={Sizing.Half} className={classes.container}>
              <Typography variant="h5" color="textPrimary" align="center">
                1
              </Typography>
            </Grid>
            <Grid item xs={Sizing.Half} className={classes.container}>
              <Typography variant="h5" color="textPrimary" align="center">
                1
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            onClick={() => {
              updateLayout(GridColumns.TreeEqualColumns);
            }}
            className={classes.option}
          >
            <Grid item xs={Sizing.OneThird} className={classes.container}>
              <Typography variant="h5" color="textPrimary" align="center">
                1
              </Typography>
            </Grid>
            <Grid item xs={Sizing.OneThird} className={classes.container}>
              <Typography variant="h5" color="textPrimary" align="center">
                1
              </Typography>
            </Grid>
            <Grid item xs={Sizing.OneThird} className={classes.container}>
              <Typography variant="h5" color="textPrimary" align="center">
                1
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            onClick={() => {
              updateLayout(GridColumns.OneThirdTwoThirds);
            }}
            className={classes.option}
          >
            <Grid item xs={Sizing.OneThird} className={classes.container}>
              <Typography variant="h5" color="textPrimary" align="center">
                1
              </Typography>
            </Grid>
            <Grid item xs={Sizing.TwoThirds} className={classes.container}>
              <Typography variant="h5" color="textPrimary" align="center">
                2
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={Sizing.Full} lg={Sizing.Half}>
          <Grid
            container
            item
            onClick={() => {
              updateLayout(GridColumns.TwoThirdsOneThird);
            }}
            className={classes.option}
          >
            <Grid item xs={Sizing.TwoThirds} className={classes.container}>
              <Typography variant="h5" color="textPrimary" align="center">
                2
              </Typography>
            </Grid>
            <Grid item xs={Sizing.OneThird} className={classes.container}>
              <Typography variant="h5" color="textPrimary" align="center">
                1
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            onClick={() => {
              updateLayout(GridColumns.OneFourthThreeFourths);
            }}
            className={classes.option}
          >
            <Grid item xs={Sizing.OneFourth} className={classes.container}>
              <Typography variant="h5" color="textPrimary" align="center">
                1
              </Typography>
            </Grid>
            <Grid item xs={Sizing.ThreeFourths} className={classes.container}>
              <Typography variant="h5" color="textPrimary" align="center">
                3
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            onClick={() => {
              updateLayout(GridColumns.ThreeFourthsOneFourth);
            }}
            className={classes.option}
          >
            <Grid item xs={Sizing.ThreeFourths} className={classes.container}>
              <Typography variant="h5" color="textPrimary" align="center">
                3
              </Typography>
            </Grid>
            <Grid item xs={Sizing.OneFourth} className={classes.container}>
              <Typography variant="h5" color="textPrimary" align="center">
                1
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default LessonEditPageSectionChooseLayout;
