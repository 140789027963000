import { Box, type Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useDrag } from "react-dnd";

import { ELEMENT_TEMPLATES, ELEMENT_TEMPLATES_TYPE } from "../helpers/const";

import { Spacing } from "../types/enum";
import { type ElementKind } from "../types/graphql";

/**
 * Types
 */
interface Props {
  title: string;
  icon?: JSX.Element;
  kind: ElementKind;
}

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    userSelect: "none",
    cursor: "-webkit-grab",
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(Spacing.s),
    border: `1px solid ${theme.palette.action.disabled}`,
    backgroundColor: theme.palette.background.default,
    "&:hover": {
      backgroundColor: theme.palette.background.dark,
    },
    "&:active": {
      cursor: "-webkit-grabbing",
    },
  },
}));

const LessonEditPageTemplatesTemplate: React.FC<Props> = ({ kind, icon, title }: Props) => {
  const classes = useStyles();

  const [, ref] = useDrag({
    type: ELEMENT_TEMPLATES_TYPE,
    item: ELEMENT_TEMPLATES.get(kind),
  });

  return (
    <div className={classes.root} ref={ref}>
      {icon}
      <Box ml={Spacing.s} display="flex" flexDirection="column">
        <Typography variant="h5" color="textPrimary">
          {title}
        </Typography>
      </Box>
    </div>
  );
};

export default LessonEditPageTemplatesTemplate;
