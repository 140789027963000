import { gql } from "@apollo/client";

export const CREATE_THEME = gql`
  mutation adminCreateTheme($input: CreateThemeInput!) {
    adminCreateTheme(input: $input) {
      id
      title
      description
      isGrammarLibrary
      isTopicLibrary
      isResource
      isStandard
      isCurrent
      cover {
        id
        title
        primary {
          mobile
          desktop
        }
        secondary {
          mobile
          desktop
        }
        tertiary {
          mobile
          desktop
        }
      }
    }
  }
`;

export const EDIT_THEME = gql`
  mutation adminEditTheme($id: String!, $input: EditThemeInput!) {
    adminEditTheme(id: $id, input: $input) {
      id
      title
      description
      isCurrent
      isStandard
      isGrammarLibrary
      isTopicLibrary
      cover {
        id
        title
        primary {
          mobile
          desktop
        }
        secondary {
          mobile
          desktop
        }
        tertiary {
          mobile
          desktop
        }
      }
    }
  }
`;

export const DELETE_THEME = gql`
  mutation adminDeleteTheme($id: String!) {
    adminDeleteTheme(id: $id) {
      status
    }
  }
`;
