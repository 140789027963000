import { gql } from "@apollo/client";

export const DELETE_EVENT = gql`
  mutation adminDeleteEvent($id: String!) {
    adminDeleteEvent(id: $id) {
      status
    }
  }
`;

export const EDIT_EVENT = gql`
  mutation adminEditEvent($id: String!, $input: EditEventInput!) {
    adminEditEvent(id: $id, input: $input) {
      status
    }
  }
`;

export const REPUBLISH_EVENT = gql`
  mutation adminRepublishEvent($eventId: String!) {
    adminRepublishEvent(eventId: $eventId) {
      status
    }
  }
`;

export const VALIDATE_USERS_FOR_EMAIL_BULK_INVITE = gql`
  mutation validateUsersForEmailBulkInvite($eventId: String!, $input: BulkInviteWithEmailsInput!) {
    validateUsersForEmailBulkInvite(eventId: $eventId, input: $input) {
      userIds
      warningMessages
    }
  }
`;

export const BULK_INVITE_WITH_USER_IDS = gql`
  mutation bulkInviteWithUserIds($eventId: String!, $input: BulkInviteWithUserIdsInput!) {
    bulkInviteWithUserIds(eventId: $eventId, input: $input) {
      warningMessages
    }
  }
`;

export const VALIDATE_FOR_SELECTION_BULK_INVITE = gql`
  mutation validateForSelectionBulkInvite($eventId: String!, $filters: AdminFilterUsersInput!) {
    validateForSelectionBulkInvite(eventId: $eventId, filters: $filters) {
      eventTitle
      userIds
      warningMessages
    }
  }
`;
