import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  type Theme,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useDropzone } from "react-dropzone";
import { useTriggerJob } from "src/hooks/triggerJob";
import { Spacing } from "src/types/enum";
import {
  useAssignAllExtraReferralBadgesMutation,
  useIssueCompleteProfileNotificationMutation,
  useTriggerBackupEventsParticipantsMutation,
  useTriggerChatServiceSyncMutation,
  useTriggerRecreateDailyMeetingsMutation,
  useTriggerUserDeletionAfterRetentionMutation,
  useTriggerUserPermissionPromotionMutation,
  useUploadActiveDaysMutation,
} from "src/types/graphql";

const useStyles = makeStyles<Theme>((theme) => ({
  fileInput: {
    display: "inline-block",
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: theme.shape.borderRadius,
    borderColor: theme.palette.action.disabled,
    "&:hover": {
      borderColor: theme.palette.action.active,
    },
    width: 200,
    padding: 6,
    overflow: "auto",
  },
}));

const MaintenancePage: React.FC = () => {
  const classes = useStyles();
  const [mutateBackupEvents] = useTriggerBackupEventsParticipantsMutation();
  const [triggerBackupEventsParticipants] = useTriggerJob(
    mutateBackupEvents,
    "triggerBackupEventsParticipants",
    {
      successMsg: "Event participants backup job started",
      failMsg: "Event participants backup job failed.",
    },
  );

  const [mutateUserPermissionPromotion] = useTriggerUserPermissionPromotionMutation();
  const [triggerUserPermissionPromotion] = useTriggerJob(
    mutateUserPermissionPromotion,
    "triggerUserPermissionPromotion",
    {
      successMsg: "User permission promotion job started.",
      failMsg: "User permission promotion job failed.",
    },
  );

  const [mutateUserDeletionAfterRetention] = useTriggerUserDeletionAfterRetentionMutation();
  const [triggerUserDeletionAfterRetention] = useTriggerJob(
    mutateUserDeletionAfterRetention,
    "triggerUserDeletionAfterRetention",
    {
      successMsg: "Job to delete users after retention days started",
      failMsg: "Job to delete users after retention days has failed to start",
    },
  );

  const [mutateChatServiceSync] = useTriggerChatServiceSyncMutation();
  const [triggerChatServiceSync] = useTriggerJob(mutateChatServiceSync, "triggerChatServiceSync", {
    successMsg: "Job to synchronize chat service started",
    failMsg: "Job to synchronize chat service failed to start",
  });

  const [mutateRecreateDailyMeetings] = useTriggerRecreateDailyMeetingsMutation();
  const [triggerRecreateDailyMeetings] = useTriggerJob(
    mutateRecreateDailyMeetings,
    "triggerRecreateDailyMeetings",
    {
      successMsg: "Job to synchronize Daily meetings started",
      failMsg: "Job to synchronize Daily meetings failed to start",
    },
  );

  const [mutateIssueCompleteProfileNotification] = useIssueCompleteProfileNotificationMutation();
  const [triggerIssueCompleteProfileNotification] = useTriggerJob(
    mutateIssueCompleteProfileNotification,
    "issueCompleteProfileNotification",
    {
      successMsg: "Notification to complete profile was send to all users",
      failMsg: "Job to synchronize Daily meetings failed to start",
    },
  );

  const [mutateUploadActiveDays] = useUploadActiveDaysMutation();
  const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: false,
    accept: {
      "text/csv": [],
    },
  });
  const onUploadActiveDays = () => {
    void mutateUploadActiveDays({
      variables: {
        input: { file: acceptedFiles[0] },
      },
    });
  };

  const [assignAllExtraReferralBadgesMutation] = useAssignAllExtraReferralBadgesMutation();
  const [triggerAssignAllExtraReferralBadgesMutation] = useTriggerJob(
    assignAllExtraReferralBadgesMutation,
    "assignAllExtraReferralBadges",
    {
      successMsg: "Extra referral badges have been assigned and users notified",
      failMsg: "Failed to assign extra referral badges or notify users",
    },
  );

  return (
    <>
      <Box mb={Spacing.m} display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h3" color="textPrimary">
          Maintenance tasks
        </Typography>
      </Box>

      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Trigger backup event participants</TableCell>
            <TableCell>
              <Button onClick={triggerBackupEventsParticipants}>Start backup</Button>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Trigger user event permission promotion</TableCell>
            <TableCell>
              <Button onClick={triggerUserPermissionPromotion}>Start promotion</Button>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Trigger user account deletion after retention days</TableCell>
            <TableCell>
              <Button onClick={triggerUserDeletionAfterRetention}>Start deletion</Button>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Trigger chat service synchronization job</TableCell>
            <TableCell>
              <Button onClick={triggerChatServiceSync}>Start chat sync</Button>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Trigger republish Daily events job</TableCell>
            <TableCell>
              <Button onClick={triggerRecreateDailyMeetings}>Republish events</Button>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Issue complete profile notification for all users</TableCell>
            <TableCell>
              <Button onClick={triggerIssueCompleteProfileNotification}>Notify users</Button>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              Upload <em>PaidSubscriptionSummary</em> to update subscription active days
            </TableCell>
            <TableCell>
              <Box alignItems="center" display="flex">
                <div {...getRootProps({ className: classes.fileInput })}>
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <>Drop the csv file here ...</>
                  ) : acceptedFiles?.[0] ? (
                    <> {acceptedFiles[0].name} </>
                  ) : (
                    <>Choose file...</>
                  )}
                </div>
                <Button onClick={onUploadActiveDays}>Upload</Button>
              </Box>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Assign extra referral badges and send notifications</TableCell>
            <TableCell>
              <Button onClick={triggerAssignAllExtraReferralBadgesMutation}>
                Assign extra referral badges
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

export default MaintenancePage;
