import { Box, Divider, Tab, Tabs, Typography } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router";
import { Outlet } from "react-router-dom";

import { Spacing } from "../types/enum";

const ReportsLayout: React.FC = () => {
  const navigate = useNavigate();
  const { pageName } = useParams<Record<string, string>>();

  const onChange = (_: React.ChangeEvent<unknown>, nextPageName: string) => {
    navigate(`/reports/${nextPageName}`);
  };

  return (
    <>
      <Box mb={Spacing.m}>
        <Typography variant="h3" color="textPrimary">
          All Reports
        </Typography>
      </Box>

      <Box>
        <Tabs
          scrollButtons="auto"
          variant="scrollable"
          textColor="secondary"
          value={pageName || "users"}
          onChange={onChange}
        >
          <Tab label="Users" value="users" />
          {/* {<Tab label="Events" value="events" disabled/> */}
        </Tabs>
      </Box>

      <Divider />

      <Box mt={Spacing.m}>
        <Outlet />
      </Box>
    </>
  );
};

export default ReportsLayout;
