import CheckIcon from "@mui/icons-material/Check";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  IconButton,
  Link,
  SvgIcon,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import React from "react";
import { ArrowRight as ArrowRightIcon, Edit as EditIcon } from "react-feather";
import { Link as RouterLink } from "react-router-dom";

import makeStyles from "@mui/styles/makeStyles";
import { useImpersonate } from "src/hooks/useImpersonate";
import { USER_BADGES } from "../helpers/const";
import { staticFileUrl } from "../helpers/utils";
import { Spacing } from "../types/enum";
import { type UserModel } from "../types/graphql";
import Label from "./Label";

/**
 * Types
 */
interface Props {
  user: UserModel;
  selected?: string[];
  allSelected?: boolean;
  onSelectOne?: (id: string) => void;
}

/**
 * Styles
 */
const useStyles = makeStyles({
  title: {
    maxWidth: "240px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  purpleIcon: {
    color: "#8a85ff",
  },
  greenIcon: {
    color: "#4caf50",
  },
  redIcon: {
    color: "#f44336",
  },
});

const UsersPageUserDetails: React.FC<Props> = ({
  user,
  selected,
  onSelectOne,
  allSelected,
}: Props) => {
  const classes = useStyles();
  const impersonateMutation = useImpersonate(user.id);

  const isSelected = selected?.includes(user.id);

  const renderers = {
    location: () => {
      return (
        <>
          {user?.city}, {user?.country}
        </>
      );
    },
  };

  return (
    <TableRow key={user?.id} hover>
      <TableCell padding="checkbox">
        <Checkbox
          checked={isSelected || allSelected}
          onChange={() => {
            onSelectOne?.(user.id);
          }}
        />
      </TableCell>

      <TableCell>
        <Box display="flex" alignItems="center">
          <Avatar src={staticFileUrl(user?.avatarUrl)} />
          <Box ml={Spacing.sm} display="flex">
            <Link color="inherit" component={RouterLink} to={`/users/${user.id}`}>
              <Typography variant="body2" className={classes.title}>
                {user?.name}
              </Typography>

              {user?.isVerified ? (
                <Box ml={Spacing.sm}>
                  <CheckIcon className={classes.purpleIcon} />
                </Box>
              ) : null}
            </Link>
          </Box>
        </Box>
      </TableCell>

      <TableCell>
        <Box ml={Spacing.sm}>
          {user?.badge ? USER_BADGES.get(user?.badge) : format(user?.createdAt, "LLL yyyy")}
        </Box>
      </TableCell>

      <TableCell>
        <Label variant="info">{user?.email}</Label>
      </TableCell>

      <TableCell>
        {user?.city ? (
          <Label variant="success">{renderers.location()}</Label>
        ) : (
          <Label variant="error">N/A</Label>
        )}
      </TableCell>

      <TableCell>
        <Typography variant="body2">{format(new Date(user?.createdAt), "yyyy-MM-dd")}</Typography>
      </TableCell>

      <TableCell align="right">
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            impersonateMutation.mutate();
          }}
        >
          Impersonate
        </Button>
        <Link component={RouterLink} to={`/users/${user.id}/edit`}>
          <IconButton size="large">
            <SvgIcon fontSize="small">
              <EditIcon />
            </SvgIcon>
          </IconButton>
        </Link>
        <Link color="inherit" component={RouterLink} to={`/users/${user.id}`}>
          <IconButton size="large">
            <SvgIcon fontSize="small">
              <ArrowRightIcon />
            </SvgIcon>
          </IconButton>
        </Link>
      </TableCell>
    </TableRow>
  );
};

export default UsersPageUserDetails;
