import { Checkbox, TableCell, TableHead, TableRow } from "@mui/material";
import React from "react";
import { AdminUsersOrderBy, SortDirection } from "src/types/enum";
import SortableTableCell from "./SortableTableCell";

interface Props {
  onRequestSort: (event: React.MouseEvent<unknown>, property: AdminUsersOrderBy) => void;
  order: SortDirection;
  orderBy: AdminUsersOrderBy;
  onSelectCurrentPage: () => void;
  selectCurrentPageChecked: boolean;
  shouldClearSelection: boolean;
}

const UsersPageTableHead: React.FC<Props> = ({
  onRequestSort,
  order,
  orderBy,
  onSelectCurrentPage,
  selectCurrentPageChecked,
  shouldClearSelection,
}: Props) => {
  const createSortHandler = (property: AdminUsersOrderBy) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  const orderedByName = orderBy === AdminUsersOrderBy.Name;
  const orderedByEmail = orderBy === AdminUsersOrderBy.Email;
  const orderedByCountry = orderBy === AdminUsersOrderBy.Country;
  const orderedByDate = orderBy === AdminUsersOrderBy.Date;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            checked={selectCurrentPageChecked}
            indeterminate={shouldClearSelection}
            onChange={onSelectCurrentPage}
          />
        </TableCell>

        <SortableTableCell
          sort={order}
          orderedByProp={orderedByName}
          sortHandler={createSortHandler(AdminUsersOrderBy.Name)}
        >
          Name
        </SortableTableCell>

        <TableCell>Community role</TableCell>

        <SortableTableCell
          sort={order}
          orderedByProp={orderedByEmail}
          sortHandler={createSortHandler(AdminUsersOrderBy.Email)}
        >
          E-mail
        </SortableTableCell>

        <SortableTableCell
          sort={order}
          orderedByProp={orderedByCountry}
          sortHandler={createSortHandler(AdminUsersOrderBy.Country)}
        >
          Location
        </SortableTableCell>

        <SortableTableCell
          sort={order}
          orderedByProp={orderedByDate}
          sortHandler={createSortHandler(AdminUsersOrderBy.Date)}
        >
          Joined date
        </SortableTableCell>

        <TableCell align="right">Actions</TableCell>
      </TableRow>
    </TableHead>
  );
};

export default UsersPageTableHead;
