import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  type Theme,
  Typography,
} from "@mui/material";
import React from "react";

import makeStyles from "@mui/styles/makeStyles";

import AttachmentIcon from "@mui/icons-material/Attachment";
import CodeIcon from "@mui/icons-material/Code";
import BannerIcon from "@mui/icons-material/Crop169Outlined";
import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";
import FormatLineSpacingIcon from "@mui/icons-material/FormatLineSpacing";
import HelpIcon from "@mui/icons-material/Help";
import ImageIcon from "@mui/icons-material/Image";
import LinearScaleIcon from "@mui/icons-material/LinearScale";
import LinkIcon from "@mui/icons-material/Link";
import ImageAndButtonIcon from "@mui/icons-material/PhotoSizeSelectLarge";
import ButtonIcon from "@mui/icons-material/SmartButtonOutlined";
import TitleIcon from "@mui/icons-material/Title";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import ViewWeekIcon from "@mui/icons-material/ViewWeek";

import { Sizing, Spacing } from "../types/enum";
import { ElementKind } from "../types/graphql";

import LessonEditPageTemplatesTemplate from "./LessonEditPageTemplatesTemplate";

/**
 * Defaults
 */
const TEMPLATES = [
  { kind: ElementKind.Grid, title: "Grid" },
  { kind: ElementKind.Title, title: "Title" },
  { kind: ElementKind.Paragraph, title: "Text" },
  { kind: ElementKind.Image, title: "Image" },
  { kind: ElementKind.Video, title: "Video" },
  { kind: ElementKind.Links, title: "Links" },
  { kind: ElementKind.Questions, title: "Question" },
  { kind: ElementKind.Ideas, title: "Ideas" },
  { kind: ElementKind.Attachment, title: "Attach" },
  { kind: ElementKind.Spacing, title: "Spacing" },
  { kind: ElementKind.Divider, title: "Divider" },
  { kind: ElementKind.Button, title: "Button" },
  { kind: ElementKind.Banner, title: "Banner" },
  { kind: ElementKind.ImageAndButton, title: "Img&Button" },
  { kind: ElementKind.Embedded, title: "Embedded" },
];

/**
 * Constants
 */
const ICONS = new Map([
  // biome-ignore lint/correctness/useJsxKeyInIterable: <explanation>
  [ElementKind.Grid, <ViewWeekIcon />],
  // biome-ignore lint/correctness/useJsxKeyInIterable: <explanation>
  [ElementKind.Title, <TitleIcon />],
  // biome-ignore lint/correctness/useJsxKeyInIterable: <explanation>
  [ElementKind.Paragraph, <ViewHeadlineIcon />],
  // biome-ignore lint/correctness/useJsxKeyInIterable: <explanation>
  [ElementKind.Image, <ImageIcon />],
  // biome-ignore lint/correctness/useJsxKeyInIterable: <explanation>
  [ElementKind.Video, <VideoLibraryIcon />],
  // biome-ignore lint/correctness/useJsxKeyInIterable: <explanation>
  [ElementKind.Links, <LinkIcon />],
  // biome-ignore lint/correctness/useJsxKeyInIterable: <explanation>
  [ElementKind.Questions, <HelpIcon />],
  // biome-ignore lint/correctness/useJsxKeyInIterable: <explanation>
  [ElementKind.Ideas, <EmojiObjectsIcon />],
  // biome-ignore lint/correctness/useJsxKeyInIterable: <explanation>
  [ElementKind.Attachment, <AttachmentIcon />],
  // biome-ignore lint/correctness/useJsxKeyInIterable: <explanation>
  [ElementKind.Spacing, <FormatLineSpacingIcon />],
  // biome-ignore lint/correctness/useJsxKeyInIterable: <explanation>
  [ElementKind.Divider, <LinearScaleIcon />],
  // biome-ignore lint/correctness/useJsxKeyInIterable: <explanation>
  [ElementKind.Button, <ButtonIcon />],
  // biome-ignore lint/correctness/useJsxKeyInIterable: <explanation>
  [ElementKind.Banner, <BannerIcon />],
  // biome-ignore lint/correctness/useJsxKeyInIterable: <explanation>
  [ElementKind.ImageAndButton, <ImageAndButtonIcon />],
  // biome-ignore lint/correctness/useJsxKeyInIterable: <explanation>
  [ElementKind.Embedded, <CodeIcon />],
]);

/**
 * Styles
 */
const useStyles = makeStyles((_theme: Theme) => ({
  root: {
    overflow: "initial",
  },
}));

const LessonEditPageTemplates: React.FC = () => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardHeader
        title={
          <Box mb={Spacing.xs}>
            <Typography variant="body1">Elements</Typography>
          </Box>
        }
        subheader={
          <Typography variant="body2" color="textSecondary">
            Compose a new section by dragging and dropping elements from the list below
          </Typography>
        }
      />

      <Divider />

      <CardContent>
        <Grid container spacing={Spacing.s}>
          {TEMPLATES.map((template) => (
            <Grid item key={template.kind} xs={Sizing.Half}>
              <LessonEditPageTemplatesTemplate
                kind={template.kind}
                title={template.title}
                icon={ICONS.get(template.kind)}
              />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default LessonEditPageTemplates;
