import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  TextField,
  type Theme,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { not } from "ramda";
import React from "react";
import { type SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";

import { AdminGetVideoSetDocument, useAdminCreateVideoMutation } from "../types/graphql";

import makeStyles from "@mui/styles/makeStyles";
import { Spacing } from "../types/enum";

/**
 * Types
 */
interface Props {
  open: boolean;
  onClose: () => void;
  videoSetId: string;
}

interface FormValues {
  title: string;
}
/**
 * Schema
 */
const schema = yup.object().shape({
  title: yup.string().required(),
});

/**
 * Styles
 */
const useStyles = makeStyles((_theme: Theme) => ({
  dialog: {
    minWidth: "460px",
  },
}));

const VideosPageCreateVideoCont: React.FC<Props> = ({ open, onClose, videoSetId }: Props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { register, formState, handleSubmit } = useForm<FormValues>({
    mode: "all",
    defaultValues: {
      title: undefined,
    },
    resolver: yupResolver(schema),
  });

  const [createVideo, { loading }] = useAdminCreateVideoMutation({
    refetchQueries: [
      {
        query: AdminGetVideoSetDocument,
        variables: {
          id: videoSetId,
        },
      },
    ],
  });

  const onSubmit: SubmitHandler<FormValues> = async (form) => {
    const variables = {
      input: {
        title: form.title,
        videoSetId,
      },
    };

    try {
      await createVideo({ variables });
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
    onClose();
  };

  return (
    <Dialog maxWidth="lg" open={open} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent className={classes.dialog}>
          <Box mb={Spacing.sm}>
            <Typography variant="body1" color="textPrimary">
              Video Label
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Please enter a label for this video.
            </Typography>
          </Box>
          <FormControl fullWidth>
            <TextField label="Title" variant="outlined" {...register("title")} />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit" disabled={not(formState.isValid) || loading}>
            Create
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default VideosPageCreateVideoCont;
