import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  TextField,
  type Theme,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import { useSnackbar } from "notistack";
import { not } from "ramda";
import React from "react";
import { type SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";

import {
  AdminGetVideoSetDocument,
  AdminListPaginatedVideoSetsDocument,
  ColourCode,
  useAdminCreateVideoSetMutation,
  useAdminEditVideoSetMutation,
} from "../types/graphql";

import { type InferType } from "yup";
import { VIDEO_SET_COLOURS } from "../helpers/const";
import { Spacing } from "../types/enum";

/**
 * Types
 */
interface Props {
  open: boolean;
  onClose: () => void;
  limit?: number;
  offset?: number;
  videoSetId?: string;
  videoSetTitle?: string;
  videoSetColour?: ColourCode | null;
}

/**
 * Schema
 */
const schema = yup.object().shape({
  title: yup.string().required(),
  colour: yup.mixed<ColourCode>().oneOf<ColourCode>(Object.values(ColourCode)).required(),
});
type FormValues = InferType<typeof schema>;

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) => ({
  colour: {
    marginRight: theme.spacing(Spacing.s),
    width: "20px",
    height: "20px",
    borderRadius: theme.shape.borderRadius,
    cursor: "pointer",
  },
  selected: {
    border: `2px solid ${theme.palette.common.white}`,
  },
}));

const VideosPageCreateUpdateVideoSetCont: React.FC<Props> = ({
  open,
  onClose,
  limit,
  offset,
  videoSetId,
  videoSetTitle,
  videoSetColour,
}: Props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { register, formState, handleSubmit, setValue, watch } = useForm<FormValues>({
    mode: "all",
    defaultValues: {
      title: videoSetTitle,
      colour: videoSetColour ?? ColourCode.Pink,
    },
    resolver: yupResolver(schema),
  });
  const colour = watch("colour");

  const [createBanner, { loading }] = useAdminCreateVideoSetMutation({
    refetchQueries: [
      {
        query: AdminListPaginatedVideoSetsDocument,
        variables: {
          filters: {
            limit,
            offset,
          },
        },
      },
    ],
  });

  const [editVideoSet] = useAdminEditVideoSetMutation({
    refetchQueries: [
      {
        query: AdminGetVideoSetDocument,
        variables: {
          id: videoSetId,
        },
      },
    ],
  });

  const onSubmit: SubmitHandler<FormValues> = async (form) => {
    if (videoSetId) {
      const variables = {
        id: videoSetId,
        input: {
          title: form.title,
          colour: form.colour,
        },
      };

      try {
        await editVideoSet({ variables });
        enqueueSnackbar("Video set was updated successfully", { variant: "success" });
      } catch (_error) {
        enqueueSnackbar("There was a problem updating this video set", { variant: "error" });
      }
    } else {
      const variables = {
        input: {
          title: form.title,
          colour: form.colour,
        },
      };
      try {
        await createBanner({ variables });
        enqueueSnackbar("Video set was successfully added", { variant: "success" });
      } catch (error: any) {
        enqueueSnackbar(error.message, { variant: "error" });
      }
    }
    onClose();
  };

  return (
    <Dialog maxWidth="lg" open={open} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Box mb={Spacing.sm}>
            <Typography variant="body1" color="textPrimary">
              Video Set
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Please enter the title for this video set.
            </Typography>
          </Box>
          <FormControl fullWidth>
            <TextField label="Title" variant="outlined" {...register("title")} />
          </FormControl>
          <Box display="flex" mt={Spacing.sm}>
            {Array.from(VIDEO_SET_COLOURS.entries()).map(([key, value]) => (
              <Box
                className={classNames(classes.colour, { [classes.selected]: colour === key })}
                key={key}
                sx={{ backgroundColor: value }}
                onClick={() => {
                  setValue("colour", key);
                }}
              />
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit" disabled={not(formState.isValid) || loading}>
            {videoSetId ? "Update" : "Create"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default VideosPageCreateUpdateVideoSetCont;
