import { type Theme } from "@mui/material";
import classNames from "classnames";
import React from "react";

import makeStyles from "@mui/styles/makeStyles";

import { Spacing } from "../types/enum";

/**
 * Types
 */
interface Props {
  children?: string | JSX.Element | JSX.Element[];
  variant: "success" | "warning" | "error" | "info";
  onClick?: () => void;
  isCode?: boolean;
}

/**
 * Styles
 */
const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  root: {
    fontSize: "12px",
    lineHeight: "24px",
    cursor: "default",
    borderRadius: "2px",
    alignItems: "center",
    display: "inline-flex",
    whiteSpace: "nowrap",
    justifyContent: "center",
    paddingLeft: theme.spacing(Spacing.s),
    paddingRight: theme.spacing(Spacing.s),
    fontWeight: theme.typography.fontWeightMedium as "bold",
    textTransform: ({ isCode }) => (isCode ? undefined : "uppercase"),
  },
  success: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.success.main,
  },
  info: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.info.main,
  },
  warning: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.warning.light,
  },
  error: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
  },
}));

const Label: React.FC<Props> = ({
  variant = "info",
  children,
  onClick,
  isCode,
  ...props
}: Props) => {
  const classes = useStyles({ isCode, variant });

  return (
    <span
      {...props}
      className={classNames(classes.root, {
        [classes.info]: variant === "info",
        [classes.error]: variant === "error",
        [classes.warning]: variant === "warning",
        [classes.success]: variant === "success",
      })}
      onClick={onClick}
    >
      {children}
    </span>
  );
};

export default Label;
