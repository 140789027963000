import { Box, IconButton, Link, SvgIcon, TableCell, TableRow } from "@mui/material";
import React from "react";
import { Trash2 as DeleteIcon, Edit as EditIcon } from "react-feather";
import { Link as RouterLink } from "react-router-dom";

import { type FeatureFlagModel } from "../types/graphql";

import { useOnOffSwitch } from "../helpers/hooks";

import ConfirmationModal from "./ConfirmationModal";
import Label from "./Label";

/**
 * Types
 */
interface Props {
  flag: FeatureFlagModel;
  onDelete: (roleId: string) => void;
}

const FlagPageDetails: React.FC<Props> = ({ flag, onDelete }: Props) => {
  const [open, onOpen, onClose] = useOnOffSwitch();
  return (
    <TableRow>
      <TableCell>
        <Label variant="info">{flag?.label}</Label>
      </TableCell>

      <TableCell>
        <Box display="flex" alignItems="center">
          {flag?.active ? (
            <Label variant="success">Active</Label>
          ) : (
            <Label variant="error">Not Active</Label>
          )}
        </Box>
      </TableCell>

      <TableCell align="right">
        <Link component={RouterLink} to={`/flags/${flag.id}/edit`}>
          <IconButton size="large">
            <SvgIcon fontSize="small">
              <EditIcon />
            </SvgIcon>
          </IconButton>
        </Link>
        <IconButton onClick={onOpen} size="large">
          <SvgIcon fontSize="small">
            <DeleteIcon />
          </SvgIcon>
        </IconButton>
      </TableCell>

      <ConfirmationModal
        open={open}
        onSubmit={() => {
          onDelete(flag?.id);
        }}
        onClose={onClose}
      />
    </TableRow>
  );
};

export default FlagPageDetails;
