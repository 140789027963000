import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { TOPIC_CATEGORIES } from "src/helpers/const";
import { AdminTopicsCategories } from "src/types/graphql";
import { TopicsFilters } from "./types";

/**
 * Types
 */
interface Props {
  filters: TopicsFilters;
  updateFilters: React.Dispatch<React.SetStateAction<TopicsFilters>>;
}

const TopicsCategoryFilter: React.FC<Props> = ({ filters, updateFilters }) => {
  const handleChange = (event: any) => {
    updateFilters({
      ...filters,
      category: event.target.value === "" ? undefined : event.target.value,
    });
  };
  return (
    <FormControl fullWidth>
      <InputLabel id="topic-category-filter-label">Filter by Category</InputLabel>
      <Select
        label="Filter by Category"
        variant="outlined"
        value={filters.category || ""}
        labelId="topic-category-filter-label"
        onChange={handleChange}
        sx={{ width: "180px" }}
      >
        <MenuItem value="">—</MenuItem>
        {Object.values(AdminTopicsCategories).map((value) => (
          <MenuItem value={value} key={value}>
            {TOPIC_CATEGORIES.get(value)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default TopicsCategoryFilter;
