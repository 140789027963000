import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button, Grid, Link, type Theme, Typography } from "@mui/material";
import { isFuture } from "date-fns";
import { useSnackbar } from "notistack";
import React from "react";
import { Link as RouterLink, useMatch, useNavigate } from "react-router-dom";

import makeStyles from "@mui/styles/makeStyles";

import { Spacing } from "../types/enum";
import {
  EventKind,
  type EventModel,
  useAdminDeleteEventMutation,
  useAdminRepublishEventMutation,
} from "../types/graphql";

import { useOnOffSwitch } from "../helpers/hooks";
import { truncateWithEllipses } from "../helpers/utils";

import ConfirmationModal from "../components/ConfirmationModal";
import TableFigure from "../components/TableFigure";

import BulkInviteEmailsModal from "src/components/BulkInvite/BulkInviteEmailsModal";
import { GET_EVENT } from "src/gql/queries/event";

/**
 * Types
 */
interface Props {
  event: EventModel;
}

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) => ({
  delete: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));

const EventLayoutHeaderCont: React.FC<Props> = ({ event }: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();
  const navigate = useNavigate();

  const [openDeleteModal, onOpenDeleteModal, onCloseDeleteModal] = useOnOffSwitch();
  const [openInviteModal, onOpenInviteModal, onCloseInviteModal] = useOnOffSwitch();

  const [deleteEvent] = useAdminDeleteEventMutation();
  const [republishEvent] = useAdminRepublishEventMutation({
    refetchQueries: [{ query: GET_EVENT, variables: { id: event.id } }],
  });

  const onRepublish = async () => {
    const variables = {
      eventId: event.id,
    };
    try {
      await republishEvent({ variables });
      enqueueSnackbar("Event has been republished", { variant: "success" });
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const onDelete = async (): Promise<any> => {
    const variables = {
      id: event.id,
    };

    try {
      await deleteEvent({ variables });
      navigate("/events");
      enqueueSnackbar("Event deleted", { variant: "success" });
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const matchParticipantsTab = useMatch("event/:eventId/participants");
  const groupFutureEvent = event.kind !== EventKind.OneToOne && isFuture(event.startDate);

  return (
    <Grid container justifyContent="space-between">
      <Grid item>
        <Box display="flex" alignItems="center">
          <Link color="inherit" component={RouterLink} to="/events">
            <Box mr={Spacing.sm}>
              <ArrowBackIcon />
            </Box>
          </Link>

          <Box mr={Spacing.sm}>
            <TableFigure src={event.coverUrl || undefined} />
          </Box>

          {event?.title ? (
            <Typography variant="h3" color="textPrimary">
              {truncateWithEllipses(event.title)}
            </Typography>
          ) : null}
        </Box>
      </Grid>

      <Grid item>
        <Box display="flex" flexDirection="row">
          <Box mr={Spacing.sm}>
            <Link component={RouterLink} to={`/event/${event.id}/edit`} underline="none">
              <Button variant="contained" color="secondary" type="submit">
                Edit
              </Button>
            </Link>
          </Box>
          {matchParticipantsTab && groupFutureEvent && (
            <Box mr={Spacing.sm}>
              <Button onClick={onOpenInviteModal} variant="contained" color="secondary">
                Bulk Invite
              </Button>
              <BulkInviteEmailsModal open={openInviteModal} onClose={onCloseInviteModal} />
            </Box>
          )}
          <Box mr={Spacing.sm}>
            <Button onClick={onRepublish} variant="contained" color="secondary">
              Republish
            </Button>
          </Box>
          <Box>
            <Button
              className={classes.delete}
              onClick={onOpenDeleteModal}
              variant="contained"
              color="secondary"
            >
              Delete
            </Button>
            <ConfirmationModal
              open={openDeleteModal}
              onClose={onCloseDeleteModal}
              onSubmit={onDelete}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default EventLayoutHeaderCont;
