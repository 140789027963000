import { type MutationFunction } from "@apollo/client";
import { useSnackbar } from "notistack";
import { type GenericResponseType, MutationStatus } from "src/types/graphql";

export function useTriggerJob<TData, TVariables>(
  mutateFn: MutationFunction<TData, TVariables>,
  queryName: string,
  { successMsg, failMsg }: { successMsg: string; failMsg: string },
) {
  const { enqueueSnackbar } = useSnackbar();

  const onTrigger = async () => {
    const result = await mutateFn();

    if (
      ((result.data as any)?.[queryName] as GenericResponseType)?.status === MutationStatus.Success
    ) {
      enqueueSnackbar(successMsg, { variant: "info" });
    } else {
      enqueueSnackbar(`${failMsg} ${result.errors?.map((error) => error.message).join(", ")}`, {
        variant: "error",
      });
    }
  };

  return [onTrigger];
}
