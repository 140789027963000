import { gql } from "@apollo/client";

export const QUERY_FLAG = gql`
  query adminGetFeatureFlag($id: String!) {
    adminGetFeatureFlag(id: $id) {
      id
      label
      active
    }
  }
`;

export const QUERY_FLAGS = gql`
  query adminListPaginatedFeatureFlags($filters: AdminFilterFeatureFlagsInput!) {
    adminListPaginatedFeatureFlags(filters: $filters) {
      items {
        id
        label
        active
      }
      total
    }
  }
`;
