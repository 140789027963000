import { useMutation, useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Card,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";

import { DELETE_COVER } from "../gql/mutations/cover";
import { QUERY_COVERS } from "../gql/queries/cover";
import { DEFAULT_OFFSET, ROWS_PER_PAGE_DEFAULT, ROWS_PER_PAGE_OPTIONS } from "../helpers/const";

import { useOnOffSwitch, usePagination } from "../helpers/hooks";

import { Spacing } from "../types/enum";
import { type CoverModel } from "../types/graphql";

import CoversPageCoverDetails from "../components/CoversPageCoverDetails";
import CoversPageCreateCont from "../containers/CoversPageCreateCont";

const CoversPage: React.FC = () => {
  const [open, onOpen, onClose] = useOnOffSwitch();
  const [offset, setOffset] = useState<number>(DEFAULT_OFFSET);
  const [limit, setLimit] = useState<number>(ROWS_PER_PAGE_DEFAULT);

  const { loading, data } = useQuery(QUERY_COVERS, {
    fetchPolicy: "network-only",
    variables: { filters: { limit, offset } },
  });

  const { enqueueSnackbar } = useSnackbar();

  const { page, rowsPerPage, items, onChangePage, onChangeRowsPerPage } = usePagination<CoverModel>(
    data?.adminListPaginatedCovers?.items,
  );
  const [deleteCover] = useMutation(DELETE_COVER, {
    refetchQueries: [{ query: QUERY_COVERS, variables: { filters: { limit, offset } } }],
  });

  useEffect(() => {
    setOffset(page * rowsPerPage);
  }, [page, rowsPerPage]);

  useEffect(() => {
    setLimit(rowsPerPage);
  }, [rowsPerPage]);

  const onDelete = async (coverId: string) => {
    const variables = {
      id: coverId,
    };

    try {
      await deleteCover({ variables });
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Box mb={Spacing.m} display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h3" color="textPrimary">
          All Covers
        </Typography>

        <Button variant="contained" color="secondary" onClick={onOpen}>
          Add cover
        </Button>
      </Box>

      <Card>
        {loading ? (
          <Box display="flex" justifyContent="center" p={Spacing.xxl}>
            <CircularProgress />
          </Box>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Images</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items?.map((cover: CoverModel) => (
                <CoversPageCoverDetails key={cover?.id} cover={cover} onDelete={onDelete} />
              ))}
            </TableBody>

            <TableFooter>
              <TableRow>
                <TablePagination
                  page={page}
                  count={data?.adminListPaginatedCovers.total}
                  onPageChange={onChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={onChangeRowsPerPage}
                  rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                />
              </TableRow>
            </TableFooter>
          </Table>
        )}
      </Card>

      <CoversPageCreateCont open={open} onClose={onClose} limit={limit} offset={0} />
    </>
  );
};

export default CoversPage;
