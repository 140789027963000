import React from "react";
import { Trash2 as DeleteIcon, Edit as EditIcon } from "react-feather";
import { Link as RouterLink } from "react-router-dom";

import { Box, IconButton, Link, SvgIcon, TableCell, TableRow } from "@mui/material";

import { type BannerModel } from "../types/graphql";

import { useOnOffSwitch } from "../helpers/hooks";
import { truncateWithEllipses } from "../helpers/utils";

import ConfirmationModal from "./ConfirmationModal";
import Label from "./Label";

/**
 * Types
 */
interface Props {
  banner: BannerModel;
  onDelete: (bannerID: string) => void;
}

const SlidersPageSliderDetails: React.FC<Props> = ({ banner, onDelete }: Props) => {
  const [isOpen, open, close] = useOnOffSwitch();

  const renderers = {
    status: () => {
      if (banner.isActive) {
        return <Label variant="success">Active</Label>;
      }
      return <Label variant="error">Inactive</Label>;
    },
  };

  return (
    <TableRow>
      <TableCell>{truncateWithEllipses(banner.title)}</TableCell>

      <TableCell>
        <Box display="flex" alignItems="center">
          {renderers.status()}
        </Box>
      </TableCell>

      <TableCell align="right">
        <Link component={RouterLink} to={`/sliders/${banner.id}/edit`}>
          <IconButton size="large">
            <SvgIcon fontSize="small">
              <EditIcon />
            </SvgIcon>
          </IconButton>
        </Link>
        <IconButton onClick={open} size="large">
          <SvgIcon fontSize="small">
            <DeleteIcon />
          </SvgIcon>
        </IconButton>
      </TableCell>

      <ConfirmationModal
        open={isOpen}
        onSubmit={() => {
          onDelete(banner.id);
        }}
        onClose={close}
      />
    </TableRow>
  );
};

export default SlidersPageSliderDetails;
