import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { Spacing } from "../../types/enum";
import { useDuplicateTopicMutation } from "../../types/graphql";

/**
 * Types
 */
interface Props {
  topicId: string;
  isOpen: boolean;
  close: () => void;
  redirect?: () => void;
  refetch?: () => void;
}

const DuplicateTopic: React.FC<Props> = ({ topicId, isOpen, close, redirect, refetch }: Props) => {
  const [duplicateTopic] = useDuplicateTopicMutation();

  const { enqueueSnackbar } = useSnackbar();

  const onDuplicateTopic = async () => {
    const variables = {
      id: topicId,
    };

    try {
      await duplicateTopic({ variables });
      enqueueSnackbar("Topic successfully duplicated", { variant: "success" });
      redirect?.();
      refetch?.();
      close();
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return (
    <Dialog open={isOpen} onClose={close}>
      <DialogContent>
        <Box mb={Spacing.m}>
          <Typography variant="body1" color="textPrimary" align="center">
            You are about to duplicate this topic and all the lessons contained within it. You will
            be able to find the topic in the main topic list and it will be INACTIVE, ready for
            editing.
          </Typography>
        </Box>
        <Typography variant="body1" color="textPrimary" align="center">
          Do you want to continue?
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <Button onClick={onDuplicateTopic}>Yes</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DuplicateTopic;
