import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { LIST_TOTP } from "src/gql/queries/totp";
import { Spacing } from "src/types/enum";
import { useListTotpQuery, useRemoveTotpMutation } from "src/types/graphql";

export default function AccountSettingsPage() {
  const { data } = useListTotpQuery({ fetchPolicy: "cache-and-network" });
  const totps = data?.listTotp ?? [];
  const [removeTotpMutation] = useRemoveTotpMutation({ refetchQueries: [LIST_TOTP] });

  const onRemove = (totpId: string) => {
    removeTotpMutation({ variables: { totpId } });
  };

  return (
    <>
      <Box mb={Spacing.m} display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h3" color="textPrimary">
          Account settings
        </Typography>
      </Box>

      <Box mb={Spacing.m} display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h4" color="textPrimary">
          Two factor authentication devices
        </Typography>
      </Box>

      {totps.length > 0 ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Device name</TableCell>
              <TableCell>Added at</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {totps.map((totp) => (
              <TableRow key={totp.id}>
                <TableCell>{totp.deviceName}</TableCell>
                <TableCell>
                  <time dateTime={totp.createdAt}>{new Date(totp.createdAt).toLocaleString()}</time>
                </TableCell>
                <TableCell>
                  <Button onClick={() => onRemove(totp.id)}>Remove</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Box m={Spacing.m} display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="body1" color="textPrimary">
            No devices configured yet
          </Typography>
        </Box>
      )}

      <Box m={Spacing.m} display="flex" alignItems="center" justifyContent="space-between">
        <Link to="/account/new-device">
          <Button variant="contained">Configure new device</Button>
        </Link>
      </Box>
    </>
  );
}
