import React, { useEffect } from "react";
import { Trash2 as DeleteIcon } from "react-feather";
import { Link as RouterLink } from "react-router-dom";
import useClipboard from "react-use-clipboard";

import { useSnackbar } from "notistack";

import { useMutation } from "@apollo/client";
import {
  Checkbox,
  IconButton,
  Link,
  SvgIcon,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { DELETE_USER_REPORT } from "../gql/mutations/reports";
import { LIST_REPORTS } from "../gql/queries/reports";

import { type AbuseModel } from "../types/graphql";

import Label from "./Label";

/**
 * Types
 */
interface Props {
  report: AbuseModel;
}

/**
 * Styles
 */
const useStyles = makeStyles({
  title: {
    maxWidth: "240px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});

const ReportsPageUsersDetails: React.FC<Props> = ({ report }: Props) => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const [isCopied, setCopied] = useClipboard(report.id);
  const [deleteReport] = useMutation(DELETE_USER_REPORT, {
    refetchQueries: [{ query: LIST_REPORTS, variables: {} }],
  });

  const onClick = async (): Promise<any> => {
    const variables = {
      id: report.id,
    };

    try {
      await deleteReport({ variables });
      enqueueSnackbar("Report was deleted successfully", { variant: "success" });
    } catch (_error) {
      enqueueSnackbar("There was a problem deleting report", { variant: "error" });
    }
  };

  useEffect(() => {
    if (isCopied) {
      enqueueSnackbar("ID successfully copied", { variant: "success" });
    }
  });

  return (
    <TableRow key={report.id} hover>
      <TableCell padding="checkbox">
        <Checkbox />
      </TableCell>

      <TableCell>
        <Label variant="info" onClick={setCopied}>
          {report.id}
        </Label>
      </TableCell>

      <TableCell>
        <Link color="inherit" component={RouterLink} to={`/users/${report.victim!.id}`}>
          <Label variant="warning">{report.victim?.name}</Label>
        </Link>
      </TableCell>

      <TableCell>
        <Link color="inherit" component={RouterLink} to={`/users/${report.abuser!.id}`}>
          <Label variant="error">{report.abuser?.name}</Label>
        </Link>
      </TableCell>

      <TableCell>
        <Typography variant="body2" className={classes.title}>
          {report.reason}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant="body2" className={classes.title}>
          {report.description}
        </Typography>
      </TableCell>

      <TableCell align="right">
        <IconButton onClick={onClick} size="large">
          <SvgIcon fontSize="small">
            <DeleteIcon />
          </SvgIcon>
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default ReportsPageUsersDetails;
