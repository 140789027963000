import React from "react";
import { useMount } from "react-use";
import { useLogout } from "src/hooks/useLogout";

const LogoutPage: React.FC = () => {
  const logoutMutation = useLogout();

  useMount(() => {
    logoutMutation.mutate();
  });

  return <></>;
};

export default LogoutPage;
