import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import { not } from "ramda";
import React, { useEffect } from "react";
import { Controller, type ControllerRenderProps, useForm } from "react-hook-form";
import * as yup from "yup";

import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormLabel,
  Grid,
  LinearProgress,
  Switch,
  Typography,
} from "@mui/material";

import { Sizing, Spacing } from "../types/enum";
import { type AttachmentModel } from "../types/generic";
import { type EditZineInput, type ZineModel } from "../types/graphql";

import { EDIT_ZINE_COVER } from "../gql/mutations/zineCover";

import { type InferType } from "yup";
import FileDropzone from "../components/FileDropzone";

/**
 * Types
 */
interface Props {
  zineCover?: ZineModel;
}

/**
 * Schema
 */
const schema = yup.object().shape({
  isCurrent: yup.boolean().required(),
  coverUrl: yup.string().required(),
});

type FormValues = InferType<typeof schema>;

const ZineCoversEditFormCont: React.FC<Props> = ({ zineCover }: Props) => {
  const [editZineCover, { loading }] = useMutation(EDIT_ZINE_COVER);

  const { control, formState, handleSubmit, trigger } = useForm<FormValues>({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      coverUrl: zineCover?.coverUrl ?? "",
      isCurrent: zineCover?.isCurrent ?? false,
    },
  });

  useEffect(() => {
    void trigger();
  }, [trigger]);

  const { enqueueSnackbar } = useSnackbar();

  const renderers = {
    file: ({
      field: { value, onChange },
    }: { field: ControllerRenderProps<FormValues, "coverUrl"> }) => {
      return (
        <FileDropzone
          preview
          fileName={value}
          onDropFile={(attachment: AttachmentModel) => {
            onChange(attachment?.url);
          }}
          zineId={zineCover?.id}
          onDropZine
        />
      );
    },
    switch: ({
      field: { value, onChange },
    }: { field: ControllerRenderProps<FormValues, "isCurrent"> }) => {
      return (
        <Switch
          edge="start"
          checked={value}
          color="secondary"
          onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
            onChange(target.checked);
          }}
        />
      );
    },
  };

  const onSubmit = async (form: EditZineInput): Promise<void> => {
    const variables = {
      id: zineCover?.id,
      input: {
        isCurrent: form?.isCurrent,
      },
    };

    try {
      await editZineCover({ variables });
      enqueueSnackbar("Image successfully updated", { variant: "success" });
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return (
    <Card>
      {loading ? <LinearProgress /> : null}

      <CardContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid spacing={Spacing.m} container>
            <Grid item xs={Sizing.Full}>
              <FormControl fullWidth>
                <Box mb={Spacing.s}>
                  <FormLabel>Image</FormLabel>
                </Box>
                <Controller name="coverUrl" control={control} render={renderers.file} />
              </FormControl>
            </Grid>

            <Grid item xs={Sizing.OneFourth}>
              <Typography variant="h5" color="textPrimary">
                Current
              </Typography>
              <Typography variant="body2" color="textSecondary">
                This will mark the cover as current
              </Typography>
              <Controller name="isCurrent" control={control} render={renderers.switch} />
            </Grid>

            <Grid item xs={Sizing.Full}>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                disabled={not(formState.isValid)}
                fullWidth
              >
                Update image
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export default ZineCoversEditFormCont;
