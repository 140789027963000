import { useApolloClient } from "@apollo/client";
import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

export function useLogout() {
  const { resetStore } = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const logoutMutation = useMutation({
    mutationFn: () => {
      return fetch(`${import.meta.env.VITE_AUTH_URL}/logout`, {
        method: "POST",
        credentials: "include",
      });
    },
    onSuccess: async () => {
      navigate("/login");
      await resetStore();
    },
    onError: (error: Error) => {
      if (error.message) {
        enqueueSnackbar(error.message, { variant: "error" });
      }
    },
  });

  return logoutMutation;
}
