import { TableCell, TableRow, Typography } from "@mui/material";
import React, { type ReactNode } from "react";

/**
 * Types
 */
interface Props {
  title: string;
  content?: string | boolean | ReactNode | ReactNode[];
}

const EntityPageDetailsCardRow: React.FC<Props> = ({ title, content }: Props) => {
  if (typeof content === "boolean") {
    content = content.toString();
  }

  return (
    <TableRow>
      <TableCell>{title}</TableCell>
      <TableCell>
        {content ? (
          <Typography variant="body2" color="textSecondary" component="div">
            {content}
          </Typography>
        ) : (
          <Typography variant="body2" color="textSecondary">
            ―
          </Typography>
        )}
      </TableCell>
    </TableRow>
  );
};

export default EntityPageDetailsCardRow;
