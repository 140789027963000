import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { Dialog } from "@mui/material";

import BulkInviteEmailsInviteForm from "./BulkInviteEmailsInviteForm";
import BulkInviteEmailsValidateForm from "./BulkInviteEmailsValidateForm";
import { FormStates } from "./types";

/**
 * Types
 */
interface Props {
  open: boolean;
  onClose: () => void;
}

const BulkInviteEmailsModal: React.FC<Props> = ({ open, onClose }: Props) => {
  const [userIds, setUserIds] = useState<string[]>([]);
  const [emails, setEmails] = useState("");
  const [formState, setFormState] = useState<FormStates>(FormStates.Validate);
  const { eventId } = useParams<{ eventId: string }>();
  const [serverMessages, setServerMessages] = useState<string[]>([]);

  if (!eventId) {
    return null;
  }

  const onGoBack = () => {
    setFormState(FormStates.Validate);
    setUserIds([]);
    setServerMessages([]);
  };

  const onCancel = () => {
    setFormState(FormStates.Validate);
    setUserIds([]);
    setEmails("");
    setServerMessages([]);
    onClose();
  };

  const onValidated = ({ emails, userIds }: { emails: string; userIds: string[] }) => {
    setUserIds(userIds);
    setEmails(emails);
  };

  return (
    <Dialog maxWidth="lg" open={open} onClose={onCancel}>
      {formState === FormStates.Confirm ? (
        <BulkInviteEmailsInviteForm
          eventId={eventId}
          emailsStr={emails}
          userIds={userIds}
          onGoBack={onGoBack}
          onClose={onCancel}
          serverMessages={serverMessages}
          setServerMessages={setServerMessages}
        />
      ) : (
        <BulkInviteEmailsValidateForm
          eventId={eventId}
          emailsStr={emails}
          onClose={onCancel}
          onValidated={onValidated}
          userIds={userIds}
          formState={formState}
          setFormState={setFormState}
          serverMessages={serverMessages}
          setServerMessages={setServerMessages}
        />
      )}
    </Dialog>
  );
};

export default BulkInviteEmailsModal;
