export enum Spacing {
  xs = 0,
  s = 1,
  sm = 2,
  m = 3,
  ml = 4,
  l = 5,
  xl = 6,
  xxl = 7,
}

export enum Sizing {
  OneSixth = 2,
  OneFourth = 3,
  OneThird = 4,
  OneFifth = 5,
  Half = 6,
  OneSeventh = 7,
  TwoThirds = 8,
  ThreeFourths = 9,
  Full = 12,
}

export enum AdminUsersOrderBy {
  Name = "name",
  Email = "email",
  Country = "country",
  Date = "date",
}

export enum SortDirection {
  ASC = "asc",
  DESC = "desc",
}
