import { arrayMoveImmutable } from "array-move";
import { append, lensPath, map, set as ramdaSet, insert as ramndaInsert } from "ramda";

import { type Element, type SectionModel } from "../types/graphql";

export const without = <T>(elements: T[], element: number) => {
  return elements.filter((_: T, index: number) => index !== element);
};

export const insert = (item: any, elements: Element[]) => {
  return append({ ...item, meta: { ...item.meta, createdAt: Date.now() } }, elements);
};

export const set = (elements: Element[], index: number, value: unknown) => {
  return elements?.map((element, current) => {
    if (current === index) {
      return { ...element, value };
    }
    return element;
  });
};

export const enhanceElementMeta = (
  elements: Element[],
  index: number,
  key: string,
  value: unknown,
) => {
  return elements?.map((element, current) => {
    if (current === index) {
      return ramdaSet(lensPath(["meta", key]), value, element);
    }
    return element;
  });
};

export const remove = (sectionId: string, sections: SectionModel[], index: number) => {
  return map((section) => {
    if (section.id === sectionId) {
      return { ...section, elements: without(section.elements ?? [], index) };
    }
    return section;
  }, sections);
};

export const add = (sectionId: string, sections: SectionModel[], item: any) => {
  return map((section) => {
    if (section.id === sectionId) {
      return { ...section, elements: insert(item, section?.elements ?? []) };
    }
    return section;
  }, sections);
};

export const move = (
  sectionId: string,
  sections: SectionModel[],
  dragIndex: number,
  hoverIndex: number,
) => {
  return map((section) => {
    if (section.id === sectionId) {
      return {
        ...section,
        elements: arrayMoveImmutable(section?.elements ?? [], dragIndex, hoverIndex),
      };
    }
    return section;
  }, sections);
};

export const insertElementAt = (
  sectionId: string,
  sections: SectionModel[],
  index: number,
  item: any,
) => {
  return map((section) => {
    if (section.id === sectionId) {
      const newItem = { ...item, meta: { ...item.meta, createdAt: Date.now() } };
      return { ...section, elements: ramndaInsert(index, newItem, section?.elements ?? []) };
    }
    return section;
  }, sections);
};

export const update = (
  sectionId: string,
  sections: SectionModel[],
  index: number,
  value: unknown,
) => {
  return map((section) => {
    if (section.id === sectionId) {
      return { ...section, elements: set(section?.elements ?? [], index, value) };
    }
    return section;
  }, sections);
};

export const enhanceMeta = (
  sectionId: string,
  sections: SectionModel[],
  index: number,
  key: string,
  value: unknown,
) => {
  return map((section) => {
    if (section.id === sectionId) {
      return {
        ...section,
        elements: enhanceElementMeta(section?.elements ?? [], index, key, value),
      };
    }
    return section;
  }, sections);
};

export const enhanceMetaOnChild = (
  sectionId: string,
  sections: SectionModel[],
  parentIndex: number,
  childIndex: number,
  key: string,
  value: unknown,
) => {
  return map((section) => {
    if (section.id === sectionId) {
      return {
        ...section,
        elements: section.elements?.map((element, current) => {
          if (current === parentIndex) {
            return {
              ...element,
              value: enhanceElementMeta(element.value ?? [], childIndex, key, value),
            };
          }
          return element;
        }),
      };
    }
    return section;
  }, sections);
};
