import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import { not } from "ramda";
import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";

import { CREATE_NOTE } from "../gql/mutations/user";
import { GET_USER_ADMIN } from "../gql/queries/user";

import { Spacing } from "../types/enum";
import { type NoteInput, type NoteModel, type UserModel } from "../types/graphql";

/**
 * Types
 */
interface Props {
  user: UserModel;
}

/**
 * Schema
 */
const schema = yup.object().shape({
  description: yup.string().required(),
});

const UserPageDetailsNotesCardCont: React.FC<Props> = ({ user }: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const [createNote] = useMutation(CREATE_NOTE, {
    refetchQueries: [{ query: GET_USER_ADMIN, variables: { id: user.id } }],
  });

  const { register, formState, reset, handleSubmit } = useForm<NoteInput>({
    mode: "all",
    resolver: yupResolver(schema),
  });

  const onSubmit = async (form: NoteInput): Promise<void> => {
    if (formState.isValid) {
      const variables = {
        id: user.id,
        input: {
          description: form.description,
        },
      };

      try {
        await createNote({ variables });
        reset();
        enqueueSnackbar("Note was created successfully", { variant: "success" });
      } catch (_error) {
        enqueueSnackbar("There was a problem creating the note", { variant: "error" });
      }
    }
  };

  return (
    <Card>
      <CardHeader title="Admin Notes" />

      <Divider />

      <CardContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl fullWidth>
            <TextField
              color="secondary"
              multiline
              label="Description"
              variant="outlined"
              {...register("description")}
            />
          </FormControl>

          <Box mt={Spacing.m}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={not(formState.isValid)}
              fullWidth
            >
              Add note
            </Button>
          </Box>
        </form>
      </CardContent>

      <Divider />

      <Table>
        <TableBody>
          {user.notes?.map((note: NoteModel) => (
            <TableRow key={note.id}>
              <TableCell>{note.description}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  );
};

export default UserPageDetailsNotesCardCont;
