import { useQuery } from "@apollo/client";
import { Box, CircularProgress, Grid } from "@mui/material";
import { not } from "ramda";
import React from "react";

import { Sizing, Spacing } from "../types/enum";
import { type UserModel } from "../types/graphql";

import { GET_PLAN_DETAILS } from "../gql/queries/user";

import UserPageDetailsMetricsCard from "src/components/UserPageDetailsMetricsCard";
import UserPageDetailsReferralActivityCard from "src/components/UserPageDetailsReferralActivityCard";
import UserLanguageAssessmentCard from "../components/UserLanguageAssessmentCard";
import UserPageDetailsInfoCard from "../components/UserPageDetailsInfoCard";
import UserPageDetailsInfoOtherCard from "../components/UserPageDetailsInfoOtherCard";
import UserPageDetailsNotesCardCont from "../containers/UserPageDetailsNotesCardCont";

/**
 * Types
 */
interface Props {
  user: UserModel;
}

const UserPageDetailsCont: React.FC<Props> = ({ user }: Props) => {
  const planDetails = useQuery(GET_PLAN_DETAILS, {
    variables: {
      userId: user.id,
    },
  });

  const details = planDetails?.data?.adminGetPlanDetails;

  return (
    <Grid container spacing={Spacing.m}>
      <Grid item xs={Sizing.Half}>
        {not(planDetails.loading) ? (
          <UserPageDetailsInfoCard
            user={user}
            planDetails={details}
            loading={planDetails.loading}
          />
        ) : (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        )}
      </Grid>

      <Grid item xs={Sizing.Half}>
        <Box mb={Spacing.m}>
          <UserPageDetailsMetricsCard user={user} />
        </Box>
        <Box mb={Spacing.m}>
          <UserPageDetailsReferralActivityCard user={user} />
        </Box>
        <Box mb={Spacing.m}>
          <UserPageDetailsNotesCardCont user={user} />
        </Box>
        <Box mb={Spacing.m}>
          <UserPageDetailsInfoOtherCard user={user} />
        </Box>
        <Box>
          <UserLanguageAssessmentCard user={user} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default UserPageDetailsCont;
