export enum AttachmentKind {
  Wordlist = "WORD_LIST",
  Wordbook = "WORDBOOK",
}

export interface AttachmentModel {
  size: number;
  name: string;
  extension: string;
  url?: string;
}

export interface IdeaModel {
  idea: string;
}

export interface QuestionModel {
  question: string;
  answer: string;
}

export interface AutocompleteOptionModel {
  id: string;
  title?: string;
  name?: string;
  groupName?: string;
}
