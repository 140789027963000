import { gql } from "@apollo/client";

export const QUERY_LESSON_SECTIONS = gql`
  query adminGetLesson($id: String!) {
    adminGetLesson(id: $id) {
      id
      kind
      title
      sections {
        id
        order
        elements {
          kind
          value
          meta
        }
      }
    }
    adminGetTopicByLessonId(id: $id) {
      id
      title
    }
  }
`;
