import { Box, Button, Link, Typography } from "@mui/material";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

import { Spacing } from "../types/enum";

const HomePage: React.FC = () => {
  return (
    <Box>
      <Typography variant="h3" color="textPrimary">
        Home Page
      </Typography>
      <Box mt={Spacing.xxl} mb={Spacing.m}>
        <Typography variant="body1" color="textPrimary">
          Choose an option
        </Typography>
      </Box>

      <Box display="flex">
        <Box mr={Spacing.m}>
          <Link component={RouterLink} to={"/sliders"}>
            <Button variant="contained" color="secondary">
              Sliders
            </Button>
          </Link>
        </Box>
        <Box mr={Spacing.m}>
          <Link component={RouterLink} to={"/videos"}>
            <Button variant="contained" color="secondary">
              Videos
            </Button>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default HomePage;
