import { Box, Divider, Tab, Tabs, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";
import { Outlet, useMatch } from "react-router-dom";

import { Spacing } from "../types/enum";

const ThemesLayout: React.FC = () => {
  const navigate = useNavigate();
  const match = useMatch("/themes/:pageName");
  const pageName = match?.params.pageName ?? "standard";

  const onChange = (_: React.ChangeEvent<unknown>, nextPageName: string) => {
    navigate(`/themes/${nextPageName}`);
  };

  return (
    <>
      <Box mb={Spacing.m}>
        <Typography variant="h3" color="textPrimary">
          All Themes
        </Typography>
      </Box>

      <Box>
        <Tabs
          scrollButtons="auto"
          variant="scrollable"
          textColor="secondary"
          value={pageName}
          onChange={onChange}
        >
          <Tab label="Standard" value="standard" />
          <Tab label="Topic Library" value="topic-library" />
          <Tab label="Grammar" value="grammar-library" />
          <Tab label="Resource" value="resource" />
          <Tab label="Draft" value="draft" />
        </Tabs>
      </Box>

      <Divider />

      <Box mt={Spacing.m}>
        <Outlet />
      </Box>
    </>
  );
};

export default ThemesLayout;
