import { Box, FormControl, FormLabel } from "@mui/material";
import debounce from "lodash.debounce";
import React from "react";

import CopyLinkToElement from "src/components/Topics/CopyLinkToElement.tsx";
import { DEBOUNCE_TIME } from "../helpers/const";
import { Spacing } from "../types/enum";
import RichTextEditor from "./RichTextEditor";

/**
 * Types
 */
interface Props {
  value?: string;
  setValue: (text: string) => void;
  id: string;
  topicId: string;
  lessonPath: string;
  step?: number | null;
}

const LessonEditPageSectionParagraph: React.FC<Props> = ({
  value,
  setValue,
  id,
  topicId,
  lessonPath,
  step,
}: Props) => {
  const onChange = debounce(setValue, DEBOUNCE_TIME);

  return (
    <FormControl fullWidth>
      <Box mb={Spacing.s} display="flex" alignItems="center" justifyContent="space-between">
        <FormLabel>Paragraph</FormLabel>
        <CopyLinkToElement id={id} topicId={topicId} lessonPath={lessonPath} step={step} />
      </Box>
      <RichTextEditor value={value} onChange={onChange} />
    </FormControl>
  );
};

export default LessonEditPageSectionParagraph;
