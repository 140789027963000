import { Typography } from "@mui/material";
import { Spacing } from "../../types/enum";

/**
 * Types
 */
interface Props {
  number: number;
}

const BulkInviteParticipantsNumber: React.FC<Props> = ({ number }: Props) => {
  return (
    <>
      <Typography variant="body2" color="textPrimary" mb={Spacing.sm} textAlign="right">
        {number} Validated participants
      </Typography>
    </>
  );
};

export default BulkInviteParticipantsNumber;
