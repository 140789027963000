import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";

import {
  Box,
  Card,
  Checkbox,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";

import { LIST_REPORTS } from "../gql/queries/reports";
import { DEFAULT_OFFSET, ROWS_PER_PAGE_DEFAULT, ROWS_PER_PAGE_OPTIONS } from "../helpers/const";

import { usePagination } from "../helpers/hooks";
import { Spacing } from "../types/enum";
import { type AbuseModel } from "../types/graphql";

import ReportsPageUsersDetails from "../components/ReportsPageUsersDetails";

const ReportsUsersPage: React.FC = () => {
  const [offset, setOffset] = useState<number>(DEFAULT_OFFSET);
  const [limit, setLimit] = useState<number>(ROWS_PER_PAGE_DEFAULT);

  const { data, loading } = useQuery(LIST_REPORTS, {
    fetchPolicy: "network-only",
    variables: {
      filters: {
        limit,
        offset,
      },
    },
  });

  const { page, rowsPerPage, items, onChangePage, onChangeRowsPerPage } = usePagination<AbuseModel>(
    data?.adminListPaginatedReportsUsers?.items,
  );

  useEffect(() => {
    setOffset(page * rowsPerPage);
  }, [page, rowsPerPage]);

  useEffect(() => {
    setLimit(rowsPerPage);
  }, [rowsPerPage]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Card>
        {loading ? (
          <Box display="flex" justifyContent="center" p={Spacing.xxl}>
            <CircularProgress />
          </Box>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox />
                </TableCell>
                <TableCell>ID</TableCell>
                <TableCell>Victim</TableCell>
                <TableCell>Abuser</TableCell>
                <TableCell>Reason</TableCell>
                <TableCell>Description</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((report: AbuseModel) => (
                <ReportsPageUsersDetails key={report.id} report={report} />
              ))}
            </TableBody>

            <TableFooter>
              <TableRow>
                <TablePagination
                  page={page}
                  count={data?.adminListPaginatedReportsUsers.total}
                  onPageChange={onChangePage}
                  onRowsPerPageChange={onChangeRowsPerPage}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                />
              </TableRow>
            </TableFooter>
          </Table>
        )}
      </Card>
    </>
  );
};

export default ReportsUsersPage;
