import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, CircularProgress, Link, Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router";
import { Link as RouterLink } from "react-router-dom";

import { Spacing } from "../types/enum";

import { useAdminGetArticleQuery } from "src/types/graphql";
import ArticlesEditFormCont from "../containers/ArticlesEditFormCont";

const ArticlesEditPage: React.FC = () => {
  const params = useParams();

  const { data, loading } = useAdminGetArticleQuery({
    fetchPolicy: "network-only",
    variables: {
      filters: {},
      id: params?.articleId ?? "",
    },
  });

  const article = data?.adminGetArticle;
  const users = data?.adminListUsers.items;

  if (loading) {
    return <CircularProgress />;
  }

  if (!article) {
    return <div>Error happened</div>;
  }

  return (
    <>
      <Box display="flex" alignItems="center" mb={Spacing.m}>
        <Link color="inherit" component={RouterLink} to="/articles">
          <Box mr={Spacing.sm}>
            <ArrowBackIcon />
          </Box>
        </Link>
        <Typography variant="h3" color="textPrimary">
          Article
        </Typography>
      </Box>
      <ArticlesEditFormCont article={article} users={users} />
    </>
  );
};

export default ArticlesEditPage;
