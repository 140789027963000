import { gql } from "@apollo/client";

export const LIST_REPORTS = gql`
  query adminListPaginatedReportsUsers($filters: AdminFilterReportsUserFilterInput!) {
    adminListPaginatedReportsUsers(filters: $filters) {
      items {
        id
        description
        reason
        createdAt
        abuser {
          id
          name
        }
        victim {
          id
          name
        }
      }
      total
    }
  }
`;
