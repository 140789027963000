import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, FormControl, TextField, Typography } from "@mui/material";
import { isBefore, parse, startOfMonth } from "date-fns";
import { useSnackbar } from "notistack";
import { isNil, not } from "ramda";
import React, { type ReactNode } from "react";
import { type SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { USER_BADGE_TYPES } from "../helpers/const";
import { useOnOffSwitch } from "../helpers/hooks";
import { Spacing } from "../types/enum";
import { GetUserAdminDocument, UserBadgeType, useAssignUserBadgeMutation } from "../types/graphql";

/**
 * Types
 */
interface Props {
  onClose: () => void;
  badgeType: keyof typeof UserBadgeType;
  userId: string;
}

/**
 * Schema
 */
const schema = yup.object().shape({
  type: yup.string().required(),
  achievedDate: yup
    .string()
    .required("Achieved date is required")
    .matches(/^(0[1-9]|1[0-2])\/\d{4}$/, "Achieved date must be in the format MM/YYYY")
    .test("is-after-2018", "Achieved date must be after the year 2018", (value) => {
      const year = Number.parseInt(value.split("/")[1], 10);
      return year > 2018;
    })
    .test("is-not-in-future", "Achieved date must not be in the future", (value) => {
      const date = parse(value, "MM/yyyy", new Date());
      const dateEndOfMonth = startOfMonth(date);
      return isBefore(dateEndOfMonth, new Date());
    }),
});
type FormValues = yup.InferType<typeof schema>;

const AssignBadgeForm: React.FC<Props> = ({ onClose, badgeType, userId }: Props) => {
  const [confirm, show, hide] = useOnOffSwitch();
  const { enqueueSnackbar } = useSnackbar();

  const [assignBadge] = useAssignUserBadgeMutation({
    refetchQueries: [{ query: GetUserAdminDocument, variables: { id: userId } }],
  });

  const defaultValues = {
    type: badgeType,
    achievedDate: "",
  };

  const { register, formState, handleSubmit, reset } = useForm<FormValues>({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues,
  });

  const handleClose = () => {
    reset();
    hide();
    onClose();
  };

  const onSubmit: SubmitHandler<FormValues> = async (form) => {
    const date = parse(form?.achievedDate, "MM/yyyy", new Date());

    const variables = {
      input: {
        userId,
        type: form?.type,
        achievedDate: date,
      },
    };

    try {
      await assignBadge({ variables });
      enqueueSnackbar("Badge successfully assigned", { variant: "success" });
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: "error" });
    } finally {
      handleClose();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        display="flex"
        py={Spacing.ml}
        justifyContent="space-around"
        alignItems="start"
        width="600px"
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="start"
          alignItems="center"
          width="33%"
        >
          <Box mb={Spacing.m}>
            <Typography variant="h5" color="textPrimary">
              Badge
            </Typography>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            p={Spacing.s}
          >
            <Typography variant="h5" color="primary">
              {USER_BADGE_TYPES.get(UserBadgeType[badgeType])}
            </Typography>
          </Box>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="start"
          alignItems="center"
          width="33%"
        >
          <Box mb={Spacing.m}>
            <Typography variant="h5" color="textPrimary">
              Assigned Date
            </Typography>
          </Box>
          <Box>
            <FormControl fullWidth>
              <TextField
                fullWidth
                variant="outlined"
                placeholder={"MM/YYYY"}
                {...register("achievedDate")}
                error={not(isNil(formState?.errors?.achievedDate))}
                helperText={
                  not(isNil(formState?.errors?.achievedDate))
                    ? (formState?.errors?.achievedDate?.message as ReactNode)
                    : undefined
                }
              />
            </FormControl>
          </Box>
        </Box>

        {confirm ? (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="33%"
          >
            <Box mb={Spacing.m}>
              <Typography variant="h5" color="textPrimary">
                Are you sure?
              </Typography>
            </Box>
            <Box display="flex" justifyContent="start" alignItems="center">
              <Button variant="text" onClick={hide}>
                Cancel
              </Button>
              <Button variant="text" type="submit" disabled={not(formState.isValid)}>
                Yes
              </Button>
            </Box>
          </Box>
        ) : (
          <Box
            display="flex"
            alignSelf="normal"
            alignItems="center"
            justifyContent="center"
            width="33%"
            pt={Spacing.m}
          >
            <Box>
              <Button variant="text" onClick={show} disabled={not(formState.isValid)}>
                Assign
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </form>
  );
};

export default AssignBadgeForm;
