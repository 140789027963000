import { useMutation, useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Card,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";

import { DELETE_ARTICLE } from "../gql/mutations/article";
import { QUERY_ARTICLES } from "../gql/queries/article";

import { DEFAULT_OFFSET, ROWS_PER_PAGE_DEFAULT, ROWS_PER_PAGE_OPTIONS } from "../helpers/const";
import { useOnOffSwitch, usePagination } from "../helpers/hooks";
import { Spacing } from "../types/enum";
import { type ArticleModel, ArticleOrderBy } from "../types/graphql";

import ArticlesPageArticleDetails from "../components/ArticlesPageArticleDetails";

const ArticlesPage: React.FC = () => {
  const [orderBy, setOrderBy] = useState(ArticleOrderBy.Published);
  const [sort, onASC, onDESC] = useOnOffSwitch();
  const [offset, setOffset] = useState<number>(DEFAULT_OFFSET);
  const [limit, setLimit] = useState<number>(ROWS_PER_PAGE_DEFAULT);

  const [deleteArticle] = useMutation(DELETE_ARTICLE, {
    refetchQueries: [
      {
        query: QUERY_ARTICLES,
        variables: { filters: { isDraft: false }, order: { by: orderBy, sortAsc: sort } },
      },
    ],
  });

  const { enqueueSnackbar } = useSnackbar();

  const { data, loading } = useQuery(QUERY_ARTICLES, {
    variables: {
      filters: {
        isDraft: true,
        limit,
        offset,
      },
      order: {
        by: orderBy,
        sortAsc: sort,
      },
      fetchPolicy: "network-only",
    },
  });

  const { page, rowsPerPage, items, onChangePage, onChangeRowsPerPage } =
    usePagination<ArticleModel>(data?.adminListPaginatedArticles?.items);

  useEffect(() => {
    setOffset(page * rowsPerPage);
  }, [page, rowsPerPage]);

  useEffect(() => {
    setLimit(rowsPerPage);
  }, [rowsPerPage]);

  const orderAscHandler = () => {
    if (sort) {
      onDESC();
    } else {
      onASC();
    }
  };

  const onClick = (value: ArticleOrderBy) => {
    setOrderBy(value);
    orderAscHandler();
  };

  const onDelete = async (articleID: string) => {
    const variables = {
      id: articleID,
    };

    try {
      await deleteArticle({ variables });
      enqueueSnackbar("Article successfully deleted", { variant: "success" });
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return (
    <>
      <Box mb={Spacing.m} display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h3" color="textPrimary">
          Draft Articles
        </Typography>
      </Box>

      <Card>
        {loading ? (
          <Box display="flex" justifyContent="center" p={Spacing.xxl}>
            <CircularProgress />
          </Box>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Button
                    onClick={() => {
                      onClick(ArticleOrderBy.Title);
                    }}
                  >
                    Title
                  </Button>
                </TableCell>
                <TableCell>Author</TableCell>
                <TableCell>
                  <Button
                    onClick={() => {
                      onClick(ArticleOrderBy.Published);
                    }}
                  >
                    Published At
                  </Button>
                </TableCell>
                <TableCell>Is featured</TableCell>
                <TableCell>Is Visible</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items?.map((article: ArticleModel) =>
                article?.isDraft ? (
                  <ArticlesPageArticleDetails
                    key={article?.id}
                    article={article}
                    onDelete={onDelete}
                  />
                ) : null,
              )}
            </TableBody>

            <TableFooter>
              <TableRow>
                <TablePagination
                  page={page}
                  count={data?.adminListPaginatedArticles.total}
                  onPageChange={onChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={onChangeRowsPerPage}
                  rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                />
              </TableRow>
            </TableFooter>
          </Table>
        )}
      </Card>
    </>
  );
};

export default ArticlesPage;
