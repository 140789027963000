import { gql } from "@apollo/client";

export const EDIT_TOPIC = gql`
  mutation adminEditTopic($id: String!, $input: EditTopicInput!) {
    adminEditTopic(id: $id, input: $input) {
      id
      title
      stars
      theme {
        id
        title
      }
      illustration {
        id
        title
        url
      }
      lessons {
        id
        kind
      }
    }
  }
`;

export const DELETE_TOPIC = gql`
  mutation adminDeleteTopic($id: String!) {
    adminDeleteTopic(id: $id) {
      status
      reason
    }
  }
`;

export const DELETE_TOPICS = gql`
  mutation adminDeleteTopics($input: DeleteTopicsInput!) {
    adminDeleteTopics(input: $input) {
      status
    }
  }
`;

export const CREATE_TOPIC = gql`
  mutation adminCreateTopic($input: CreateTopicInput!) {
    adminCreateTopic(input: $input) {
      id
      title
      stars
      illustration {
        id
        title
        url
      }
      theme {
        id
        title
      }
      lessons {
        id
        kind
      }
    }
  }
`;

export const DUPLICATE_TOPIC = gql`
    mutation duplicateTopic($id: String!) {
        duplicateTopic(id: $id) {
            status
        }
    }
`;

export const DUPLICATE_LESSON = gql`
    mutation duplicateLesson($input: DuplicateLessonInput!) {
        duplicateLesson(input: $input) {
            status
        }
    }
`;

export const CREATE_TOPIC_FOR_PREVIEW_MODE = gql`
    mutation createTopicForPreviewMode($input: TopicInPreviewInput!) {
        createTopicForPreviewMode(input: $input) {
            id
        }
    }
`;
