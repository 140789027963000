import ClearIcon from "@mui/icons-material/Clear";
import { Box, Button, Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import { Spacing } from "src/types/enum";
import TopicThemeFilter from "./TopicThemeFilter";
import TopicsCategoryFilter from "./TopicsCategoryFilter";
import TopicsStatusFilter from "./TopicsStatusFilter";
import { TopicsFilters } from "./types";

/**
 * Types
 */
interface Props {
  searchQuery: string;
  onSearch: (event: any) => void;
  onClearSearch: () => void;
  onCreateTopic: () => void;
  onDeleteTopic: () => void;
  deleteEnabled: boolean;
  filters: TopicsFilters;
  updateFilters: React.Dispatch<React.SetStateAction<TopicsFilters>>;
}

const TopicsActions: React.FC<Props> = ({
  searchQuery,
  onSearch,
  onClearSearch,
  onCreateTopic,
  onDeleteTopic,
  deleteEnabled,
  filters,
  updateFilters,
}) => {
  const shouldShowClearSearchButton = searchQuery.length > 0;
  return (
    <Box sx={{ flex: 1, marginRight: Spacing.s }}>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs>
          <TextField
            label="Search topic by title or by ID"
            variant="outlined"
            value={searchQuery}
            onChange={onSearch}
            fullWidth
            InputProps={{
              endAdornment: shouldShowClearSearchButton ? (
                <InputAdornment position="end">
                  <IconButton aria-label="clear" onClick={onClearSearch}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ) : null,
            }}
          />
        </Grid>
        <Grid item>
          <TopicsCategoryFilter filters={filters} updateFilters={updateFilters} />
        </Grid>
        <Grid item>
          <TopicThemeFilter filters={filters} updateFilters={updateFilters} />
        </Grid>
        <Grid item>
          <TopicsStatusFilter filters={filters} updateFilters={updateFilters} />
        </Grid>

        <Grid item>
          <Button
            variant="contained"
            color="error"
            onClick={onDeleteTopic}
            disabled={!deleteEnabled}
          >
            Delete
          </Button>
        </Grid>

        <Grid item>
          <Button variant="contained" color="secondary" onClick={onCreateTopic}>
            Create topic
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TopicsActions;
