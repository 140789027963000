import { Typography } from "@mui/material";
import { Spacing } from "src/types/enum";

const BulkInviteSelectionFormHeader: React.FC = () => {
  return (
    <>
      <Typography variant="body1" color="textPrimary" fontWeight="bold" mb={Spacing.s}>
        Bulk Invite
      </Typography>
      <Typography variant="body2" color="textPrimary" mb={Spacing.sm}>
        {
          "To invite these members to an event you will need the ID, available from the Events page (open the Events page in a new tab)."
        }
      </Typography>
    </>
  );
};

export default BulkInviteSelectionFormHeader;
