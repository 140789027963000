import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import { not } from "ramda";
import React from "react";
import { Controller, type ControllerRenderProps, useForm } from "react-hook-form";
import * as yup from "yup";

import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  LinearProgress,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import { EDIT_FLAG } from "../gql/mutations/flag";

import { Sizing, Spacing } from "../types/enum";
import { type EditFeatureFlagInput, type FeatureFlagModel } from "../types/graphql";

/**
 * Types
 */
interface Props {
  flag?: FeatureFlagModel;
}

interface FormValues {
  label: string;
  active: boolean;
}

/**
 * Schema
 */
const schema = yup.object().shape({
  label: yup.string().required(),
  active: yup.boolean().required(),
});

const FlagEditFormCont: React.FC<Props> = ({ flag }: Props) => {
  const [editFlag, { loading }] = useMutation(EDIT_FLAG);

  const { register, control, formState, handleSubmit } = useForm<FormValues>({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      label: flag?.label,
      active: flag?.active ?? false,
    },
  });

  const { enqueueSnackbar } = useSnackbar();

  const renderers = {
    switch: ({
      field: { value, onChange },
    }: { field: ControllerRenderProps<FormValues, "active"> }) => {
      return (
        <Switch
          edge="start"
          checked={value}
          color="secondary"
          onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
            onChange(target.checked);
          }}
        />
      );
    },
  };

  const onSubmit = async (form: EditFeatureFlagInput): Promise<void> => {
    const variables = {
      id: flag?.id,
      input: {
        label: form?.label,
        active: form?.active,
      },
    };

    try {
      await editFlag({ variables });
      enqueueSnackbar("Flag successfully updated", { variant: "success" });
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return (
    <Card>
      {loading ? <LinearProgress /> : null}

      <CardContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid spacing={Spacing.m} container>
            <Grid item xs={Sizing.Full}>
              <FormControl fullWidth>
                <TextField label="Label" variant="outlined" {...register("label")} />
              </FormControl>
            </Grid>

            <Grid item xs={Sizing.Full}>
              <Typography variant="h5" color="textPrimary">
                Active
              </Typography>
              <Typography variant="body2" color="textSecondary">
                This will mark the feature flag as active
              </Typography>
              <Controller name="active" control={control} render={renderers.switch} />
            </Grid>

            <Grid item xs={Sizing.Full}>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                disabled={not(formState.isValid)}
                fullWidth
              >
                Update flag
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export default FlagEditFormCont;
