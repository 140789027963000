import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import { isNil, not } from "ramda";
import React from "react";
import {
  Controller,
  type ControllerRenderProps,
  type UseFormStateReturn,
  useForm,
} from "react-hook-form";
import * as yup from "yup";

import {
  Box,
  Button,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  LinearProgress,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import { EDIT_TOPIC } from "../gql/mutations/topic";
import { TOPIC_STATUS } from "../helpers/const";

import { Sizing, Spacing } from "../types/enum";
import {
  type IllustrationModel,
  type ThemeModel,
  type TopicModel,
  TopicStatus,
} from "../types/graphql";

import { type InferType } from "yup";
import Autocomplete from "../components/Autocomplete";
import Card from "../components/Card";
import RichTextEditor from "../components/RichTextEditor";

/**
 * Types
 */
interface Props {
  topic?: TopicModel;
  themes: ThemeModel[];
  illustrations: IllustrationModel[];
}

/**
 * Schema
 */
const schema = yup.object().shape({
  title: yup.string().required(),
  theme: yup
    .object()
    .shape({
      id: yup.string().required(),
      title: yup.string().required(),
    })
    .required(),
  illustration: yup
    .object()
    .shape({
      id: yup.string().required(),
      title: yup.string().required(),
    })
    .required(),
  status: yup.mixed<TopicStatus>().oneOf(Object.values(TopicStatus)).required(),
});
type FormValues = InferType<typeof schema>;

const TopicEditPageFormCont: React.FC<Props> = ({ topic, themes, illustrations }: Props) => {
  const [topicEdit, { loading }] = useMutation(EDIT_TOPIC);

  const { enqueueSnackbar } = useSnackbar();
  const defaultValues = {
    title: topic?.title ?? "",
    theme: { id: topic?.theme?.id ?? "", title: topic?.theme?.title },
    illustration: { id: topic?.illustration?.id ?? "", title: topic?.illustration?.title },
    status: topic?.status ?? TopicStatus.ComingSoon,
  };

  const { control, formState, register, handleSubmit, watch } = useForm<FormValues>({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues,
  });

  const renderers = {
    editor: ({ field: { value, onChange } }: { field: ControllerRenderProps }) => {
      return (
        <RichTextEditor
          value={value}
          onChange={(content: string) => {
            onChange(content);
          }}
        />
      );
    },
    theme: ({
      field: { value, onChange },
    }: { field: ControllerRenderProps<FormValues, "theme"> }) => {
      const error = not(isNil(formState?.errors?.theme));

      return (
        <Autocomplete
          label="Theme"
          options={themes}
          value={value}
          onChange={onChange}
          error={error}
          helperText={error ? "This field is required" : undefined}
        />
      );
    },
    illustration: ({
      field: { value, onChange },
      formState,
    }: {
      field: ControllerRenderProps<FormValues, "illustration">;
      formState: UseFormStateReturn<FormValues>;
    }) => {
      const error = not(isNil(formState?.errors?.illustration));

      return (
        <Autocomplete
          label="Illustration"
          options={illustrations}
          value={value}
          onChange={onChange}
          error={error}
          helperText={error ? "This field is required" : undefined}
        />
      );
    },
    switch: ({ field: { value, onChange } }: { field: ControllerRenderProps }) => {
      return (
        <Switch
          checked={value}
          edge="start"
          color="secondary"
          onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
            onChange(target.checked);
          }}
        />
      );
    },
    status: ({
      field: { value, onChange },
    }: { field: ControllerRenderProps<FormValues, "status"> }) => {
      return (
        <Select
          fullWidth
          value={value}
          variant="outlined"
          onChange={({ target }) => {
            onChange(target.value);
          }}
        >
          {Object.values(TopicStatus).map((value) => (
            <MenuItem value={value} key={value}>
              {TOPIC_STATUS.get(value)}
            </MenuItem>
          ))}
        </Select>
      );
    },
  };

  const onSubmit = async (form: any) => {
    const variables = {
      id: topic?.id,
      input: {
        title: form?.title,
        description: form?.description,
        themeId: form?.theme.id,
        status: form?.status,
        illustrationId: form?.illustration.id,
      },
    };

    try {
      await topicEdit({ variables });
      enqueueSnackbar("Topic successfully updated", { variant: "success" });
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const watchAllFields = watch();

  const isAnySelectChanged = Object.keys(watchAllFields).some(
    (key) => watchAllFields[key as keyof FormValues] !== "",
  );

  return (
    <Card overflow>
      <CardHeader title="Details" />

      <Divider />

      <CardContent>
        {loading ? <LinearProgress /> : null}

        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid spacing={Spacing.m} container>
            <Grid item xs={Sizing.Full}>
              <FormControl fullWidth>
                <TextField
                  label="Title"
                  variant="outlined"
                  {...register("title")}
                  error={not(isNil(formState?.errors?.title))}
                  helperText={
                    not(isNil(formState?.errors?.title)) ? "This field is required" : undefined
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={Sizing.Half}>
              <FormControl fullWidth>
                <Controller name="theme" control={control} render={renderers.theme} />
              </FormControl>
            </Grid>

            <Grid item xs={Sizing.Half}>
              <FormControl fullWidth>
                <Controller name="illustration" control={control} render={renderers.illustration} />
              </FormControl>
            </Grid>

            <Grid item xs={Sizing.Full}>
              <Box mb={Spacing.s}>
                <Typography variant="h5" color="textPrimary">
                  Status
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Set topic status
                </Typography>
              </Box>

              <Controller name="status" control={control} render={renderers.status} />
            </Grid>

            <Grid item xs={Sizing.Full}>
              <Button
                fullWidth
                type="submit"
                color="primary"
                variant="contained"
                disabled={
                  not(formState.isDirty) || not(formState.isValid) || not(isAnySelectChanged)
                }
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export default TopicEditPageFormCont;
