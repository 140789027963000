import { gql } from "@apollo/client";

export const LIST_TOTP = gql`
  query listTotp {
    listTotp {
      id
      deviceName
      createdAt
    }
  }
`;
