import { useQuery } from "@apollo/client";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, CircularProgress, Typography } from "@mui/material";
import type { FC } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";

import { GET_USER_ADMIN } from "../gql/queries/user";

import { Spacing } from "../types/enum";

import UserEditPageFormCont from "../containers/UserEditPageFormCont";

const UserEditPage: FC = () => {
  const params = useParams<Record<string, string>>();
  const navigate = useNavigate();

  const { data, loading } = useQuery(GET_USER_ADMIN, {
    fetchPolicy: "network-only",
    variables: {
      id: params?.userId,
    },
  });

  if (loading) {
    return <CircularProgress />;
  }

  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Box mb={Spacing.m} ml={Spacing.xs} display="flex">
        <Box mr={Spacing.sm} onClick={goBack}>
          <ArrowBackIcon />
        </Box>
        <Typography variant="h3" color="textPrimary">
          Edit user
        </Typography>
      </Box>

      <UserEditPageFormCont user={data?.getUserAdmin} />
    </>
  );
};

export default UserEditPage;
