import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Box, IconButton, Link, SvgIcon, TableCell, TableRow, Typography } from "@mui/material";
import { format } from "date-fns";
import { inc } from "ramda";
import React from "react";
import { Trash2 as DeleteIcon, Edit as EditIcon } from "react-feather";
import { Link as RouterLink } from "react-router-dom";

import { Spacing } from "../types/enum";
import {
  AdminListPaginatedVideoSetsDocument,
  type VideoSetModel,
  useAdminOrderVideoSetMutation,
} from "../types/graphql";

import { MoveElement, VIDEO_SET_COLOURS } from "../helpers/const";

import { useOnOffSwitch } from "../helpers/hooks";
import { truncateWithEllipses } from "../helpers/utils";

import { useSnackbar } from "notistack";
import ConfirmationModal from "./ConfirmationModal";
import Label from "./Label";

/**
 * Types
 */
interface Props {
  index: number;
  total: number;
  videoSet: VideoSetModel;
  onDelete: (bannerID: string) => void;
  limit: number;
  offset: number;
}

const VideosPageVideoSetDetails: React.FC<Props> = ({
  index,
  total,
  videoSet,
  onDelete,
  limit,
  offset,
}: Props) => {
  const [isOpen, open, close] = useOnOffSwitch();
  const { enqueueSnackbar } = useSnackbar();

  const [editVideoSetOrder, { loading }] = useAdminOrderVideoSetMutation({
    refetchQueries: [
      {
        query: AdminListPaginatedVideoSetsDocument,
        variables: {
          filters: {
            limit,
            offset,
          },
        },
      },
    ],
  });

  const renderers = {
    status: () => {
      if (videoSet.isActive) {
        return <Label variant="success">Active</Label>;
      }
      return <Label variant="error">Inactive</Label>;
    },
  };

  const onSort = async (position: MoveElement) => {
    const variables = {
      id: videoSet.id,
      input: position,
    };

    try {
      await editVideoSetOrder({ variables });
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return (
    <TableRow>
      <TableCell>
        <Box
          p={Spacing.s}
          sx={{
            backgroundColor: videoSet.colour ? VIDEO_SET_COLOURS.get(videoSet.colour) : undefined,
          }}
        >
          <Typography variant="body2">{truncateWithEllipses(videoSet.title)}</Typography>
        </Box>
      </TableCell>

      <TableCell align="right">
        <Box>{renderers.status()}</Box>
      </TableCell>

      <TableCell align="right">
        <Box>
          <Typography variant="body2">
            {format(new Date(videoSet?.updatedAt), "yyyy-MM-dd")}
          </Typography>
        </Box>
      </TableCell>

      <TableCell align="right">
        <Box display="flex" justifyContent="flex-end">
          {index ? (
            <IconButton size="small" onClick={() => onSort(MoveElement.UP)} disabled={loading}>
              <ArrowUpwardIcon fontSize="small" />
            </IconButton>
          ) : null}
          {inc(index) < total ? (
            <IconButton size="small" onClick={() => onSort(MoveElement.DOWN)} disabled={loading}>
              <ArrowDownwardIcon fontSize="small" />
            </IconButton>
          ) : null}
        </Box>
      </TableCell>

      <TableCell align="right">
        <Link component={RouterLink} to={`/videos/${videoSet.id}/edit`}>
          <IconButton size="large">
            <SvgIcon fontSize="small">
              <EditIcon />
            </SvgIcon>
          </IconButton>
        </Link>
        <IconButton onClick={open} size="large">
          <SvgIcon fontSize="small">
            <DeleteIcon />
          </SvgIcon>
        </IconButton>
      </TableCell>

      <ConfirmationModal
        open={isOpen}
        onSubmit={() => {
          onDelete(videoSet.id);
        }}
        onClose={close}
      />
    </TableRow>
  );
};

export default VideosPageVideoSetDetails;
