import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  type Theme,
} from "@mui/material";
import { inc } from "ramda";
import React, { useContext } from "react";
import { useDrop } from "react-dnd";

import makeStyles from "@mui/styles/makeStyles";

import { ELEMENT_TEMPLATES_TYPE } from "../helpers/const";

import { Spacing } from "../types/enum";
import { type Element, type Maybe } from "../types/graphql";

import Label from "./Label";

import LessonContext from "../context/LessonContext";
import LessonEditPageSectionInstructions from "./LessonEditPageSectionInstructions";
import LessonEditPageSectionSandbox from "./LessonEditPageSectionSandbox";

/**
 * Types
 */
interface Props {
  topicTitle?: string;
  index: number;
  sectionId: string;
  elements?: Maybe<Element[]>;
  topicId: string;
  lessonPath: string;
  step?: number | null;
}

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(Spacing.sm),
  },
  content: {
    padding: "unset",
    flexDirection: "column",
  },
}));

const LessonEditPageSection: React.FC<Props> = ({
  index,
  elements,
  sectionId,
  topicTitle,
  topicId,
  lessonPath,
  step,
}: Props) => {
  const classes = useStyles();
  const { onDeleteSection, onSaveSection, onAddElement } = useContext(LessonContext);

  const [, ref] = useDrop({
    accept: ELEMENT_TEMPLATES_TYPE,
    drop: (item, monitor) => {
      if (monitor.didDrop()) {
        return null;
      }
      onAddElement(sectionId, item);
    },
  });

  return (
    <Card className={classes.root} ref={ref}>
      <CardHeader
        title={`Section #${inc(index)}`}
        subheader={
          <Box mt={Spacing.s}>
            <Label variant="success">{topicTitle}</Label>
          </Box>
        }
      />

      <Divider />

      <CardContent className={classes.content}>
        {elements?.length ? (
          <LessonEditPageSectionSandbox
            elements={elements}
            sectionId={sectionId}
            topicId={topicId}
            lessonPath={lessonPath}
            step={step}
          />
        ) : (
          <LessonEditPageSectionInstructions />
        )}
      </CardContent>

      <Divider />

      <CardActions>
        <Button onClick={() => onDeleteSection(sectionId)}>Remove</Button>
        <Button onClick={() => onSaveSection(sectionId)}>Save</Button>
      </CardActions>
    </Card>
  );
};

export default LessonEditPageSection;
