import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import { useParams } from "react-router";

import {
  Box,
  Card,
  Checkbox,
  CircularProgress,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
} from "@mui/material";

import { GET_EVENT_PARTICIPANTS } from "../gql/queries/event";

import { Spacing } from "../types/enum";
import { type UserModel } from "../types/graphql";

import UsersPageUserDetails from "../components/UsersPageUserDetails";

/**
 * Types
 */
enum ParticipantsStatus {
  Going = "Going",
  Invited = "Invited",
  Declined = "Declined",
}

/**
 * Helpers
 */
const showParticipants = (tabName: ParticipantsStatus, data: any) => {
  let participantsData = data?.participants;

  if (tabName === ParticipantsStatus.Invited) {
    participantsData = data?.invited;
  }

  if (tabName === ParticipantsStatus.Declined) {
    participantsData = data?.declined;
  }

  return participantsData.map((user: UserModel) => (
    <UsersPageUserDetails key={user.id} user={user} />
  ));
};

const EventParticipantsPage: React.FC = () => {
  const { eventId } = useParams<Record<string, string>>();
  const [tabName, setTabName] = useState<ParticipantsStatus>(ParticipantsStatus.Going);

  const onChange = (_: React.ChangeEvent<unknown>, nextTabName: ParticipantsStatus) => {
    setTabName(nextTabName);
  };

  const { data, loading } = useQuery(GET_EVENT_PARTICIPANTS, {
    fetchPolicy: "network-only",
    variables: {
      id: eventId,
    },
  });

  return (
    <>
      <Box mt={Spacing.m} mb={Spacing.m}>
        <Tabs
          scrollButtons="auto"
          variant="scrollable"
          textColor="secondary"
          value={tabName}
          onChange={onChange}
        >
          <Tab label="Going" value={ParticipantsStatus.Going} />
          <Tab label="Invited" value={ParticipantsStatus.Invited} />
          <Tab label="Declined" value={ParticipantsStatus.Declined} />
        </Tabs>
      </Box>
      <Card>
        {loading ? (
          <Box display="flex" justifyContent="center" p={Spacing.xxl}>
            <CircularProgress />
          </Box>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox />
                </TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Community role</TableCell>
                <TableCell>E-mail</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Joined date</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{showParticipants(tabName, data.getEventAdmin)}</TableBody>
          </Table>
        )}
      </Card>
    </>
  );
};

export default EventParticipantsPage;
