import { useMutation } from "@tanstack/react-query";
import { enqueueSnackbar } from "notistack";

export function useImpersonate(userId: string) {
  return useMutation({
    mutationFn: async () => {
      const response = await fetch(
        `${import.meta.env.VITE_AUTH_URL}/impersonate?${new URLSearchParams({
          userId,
        }).toString()}`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message);
      }
      return response;
    },
    onSuccess: () => {
      window.location.assign(`${import.meta.env.VITE_APP_URL}/home`);
    },
    onError: (error) => {
      enqueueSnackbar((error as any)?.message, { variant: "error" });
    },
  });
}
