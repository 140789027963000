import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  type Theme,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import debounce from "lodash.debounce";
import React from "react";
import CopyLinkToElement from "src/components/Topics/CopyLinkToElement.tsx";
import { DEBOUNCE_TIME, TopicButtonAlignment, TopicButtonType } from "../helpers/const";
import { Sizing, Spacing } from "../types/enum";

/**
 * Types
 */
interface ButtonDetails {
  buttonType: string;
  buttonAlignment: string;
  buttonText: string;
  buttonURL: string;
}

interface Props {
  value: ButtonDetails;
  setValue: (value: ButtonDetails) => void;
  id?: string;
  topicId?: string;
  lessonPath?: string;
  step?: number | null;
}

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderWidth: "1px",
    borderStyle: "dashed",
    borderRadius: theme.shape.borderRadius,
    borderColor: theme.palette.action.disabled,
    paddingRight: Spacing.xxl,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
    marginBottom: Spacing.xxl,
  },
}));

const LessonEditPageSectionButton: React.FC<Props> = ({
  value,
  setValue,
  id,
  topicId,
  lessonPath,
  step,
}: Props) => {
  const classes = useStyles();

  const onChange = debounce(setValue, DEBOUNCE_TIME);

  const typeOptions = Object.values(TopicButtonType);
  const alignmentOptions = Object.values(TopicButtonAlignment);

  return (
    <Grid container spacing={Spacing.s} className={classes.root}>
      <Grid item xs={Sizing.Full} lg={Sizing.Half} className={classes.container}>
        <Box mb={Spacing.s} display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h5" color="textPrimary">
            Button
          </Typography>
          {id ? (
            <CopyLinkToElement
              id={id}
              topicId={topicId ?? ""}
              lessonPath={lessonPath ?? ""}
              step={step}
            />
          ) : null}
        </Box>
        <Box mb={Spacing.s}>
          <FormLabel>Button Type</FormLabel>
        </Box>
        <FormControl fullWidth>
          <Select
            fullWidth
            value={value.buttonType}
            variant="outlined"
            onChange={({ target }) => {
              setValue({ ...value, buttonType: target.value });
            }}
          >
            {typeOptions.map((option: string, _index: number) => (
              <MenuItem value={option} key={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={Sizing.Full} lg={Sizing.Half} className={classes.container}>
        <Box mb={Spacing.s}>
          <FormLabel>Button Alignment</FormLabel>
        </Box>
        <FormControl fullWidth>
          <Select
            fullWidth
            value={value.buttonAlignment}
            variant="outlined"
            onChange={({ target }) => {
              setValue({ ...value, buttonAlignment: target.value });
            }}
          >
            {alignmentOptions.map((option: string, _index: number) => (
              <MenuItem value={option} key={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={Sizing.Full} lg={Sizing.Half} className={classes.container}>
        <Box mb={Spacing.s}>
          <FormLabel>Button Text</FormLabel>
        </Box>
        <FormControl fullWidth>
          <TextField
            variant="outlined"
            placeholder="Button Text"
            defaultValue={value.buttonText}
            name="buttonText"
            onChange={({ target }: React.ChangeEvent<HTMLInputElement>) =>
              onChange({ ...value, buttonText: target.value })
            }
          />
        </FormControl>
      </Grid>

      <Grid item xs={Sizing.Full} lg={Sizing.Half} className={classes.container}>
        <Box mb={Spacing.s}>
          <FormLabel>Button URL</FormLabel>
        </Box>
        <FormControl fullWidth>
          <TextField
            variant="outlined"
            placeholder="Button URL"
            defaultValue={value.buttonURL}
            name="buttonURL"
            onChange={({ target }: React.ChangeEvent<HTMLInputElement>) =>
              onChange({ ...value, buttonURL: target.value })
            }
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default LessonEditPageSectionButton;
