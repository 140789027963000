import { gql } from "@apollo/client";

export const QUERY_ZINE_COVER = gql`
  query adminGetZineCover($id: String!) {
    adminGetZineCover(id: $id) {
      id
      coverUrl
      isCurrent
    }
  }
`;

export const QUERY_ZINE_COVERS = gql`
  query adminListPaginatedZineCovers($filters: AdminFilterZineCoversInput!) {
    adminListPaginatedZineCovers(filters: $filters) {
      items {
        id
        coverUrl
        isCurrent
      }
      total
    }
  }
`;
