import { Dialog } from "@mui/material";
import React from "react";

import AssignBadgeForm from "src/components/AssignBadgeForm";
import { type UserBadgeType } from "../types/graphql";

/**
 * Types
 */
interface Props {
  open: boolean;
  onClose: () => void;
  badgeType: keyof typeof UserBadgeType;
  userId: string;
}

const AssignBadgeCont: React.FC<Props> = ({ open, onClose, badgeType, userId }: Props) => {
  return (
    <Dialog maxWidth="lg" open={open} onClose={onClose}>
      <AssignBadgeForm onClose={onClose} badgeType={badgeType} userId={userId} />
    </Dialog>
  );
};

export default AssignBadgeCont;
