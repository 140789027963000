import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useAdminListThemesQuery } from "src/types/graphql";
import { TopicsFilters } from "./types";

/**
 * Types
 */
interface Props {
  filters: TopicsFilters;
  updateFilters: React.Dispatch<React.SetStateAction<TopicsFilters>>;
}

const TopicThemeFilter: React.FC<Props> = ({ filters, updateFilters }) => {
  const { data } = useAdminListThemesQuery();
  const themes =
    data?.adminListThemes?.map((theme) => ({ id: theme.id, title: theme.title })) || [];

  const handleChange = (event: any) => {
    updateFilters({
      ...filters,
      theme: event.target.value === "" ? undefined : event.target.value,
    });
  };
  return (
    <FormControl fullWidth>
      <InputLabel id="topic-theme-filter-label">Filter by Theme</InputLabel>
      <Select
        label="Filter by Theme"
        variant="outlined"
        value={filters.theme || ""}
        labelId="topic-theme-filter-label"
        onChange={handleChange}
        sx={{ width: "180px" }}
      >
        <MenuItem value="">—</MenuItem>
        {themes.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default TopicThemeFilter;
