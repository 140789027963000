import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import SelectTopic from "src/components/SelectTopic.tsx";
import { Spacing } from "../types/enum";
import { LessonKind, useDuplicateLessonMutation } from "../types/graphql";

/**
 * Types
 */
interface Props {
  isOpen: boolean;
  lessonId: string;
  kind: LessonKind;
  currentTopicId: string;
  close: () => void;
}

/**
 * Constants
 */
const CUSTOM_LESSON_TEXT =
  "You are about to duplicate this lesson. If you select a different topic make sure that the topic is INACTIVE" +
  " before saving. Where would you like to send the copy?";
const OTHER_LESSON_TEXT =
  "To duplicate this lesson you need to select a topic to add to. Please be aware that the" +
  " Topic must be INACTIVE before you can send a copy of this lesson there.";

const DuplicateLesson: React.FC<Props> = ({
  isOpen,
  lessonId,
  kind,
  currentTopicId,
  close,
}: Props) => {
  const defaultTopicId = kind === LessonKind.Custom ? currentTopicId : undefined;
  const [topicId, setTopicId] = useState<string | undefined>(defaultTopicId);
  const [saveToCurrent, setSaveToCurrent] = useState<boolean>(kind === LessonKind.Custom);

  const { enqueueSnackbar } = useSnackbar();

  const [duplicateLesson] = useDuplicateLessonMutation();

  const onDuplicate = async () => {
    const variables = {
      input: { lessonId: lessonId, topicId: topicId ?? "" },
    };

    try {
      await duplicateLesson({ variables });
      enqueueSnackbar("Lesson successfully duplicated", { variant: "success" });
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: "error" });
    } finally {
      close();
    }
  };

  return (
    <Dialog open={isOpen} onClose={close}>
      <DialogContent>
        <Box mb={Spacing.m}>
          <Typography variant="body1" color="textPrimary" align="center">
            {kind === LessonKind.Custom ? CUSTOM_LESSON_TEXT : OTHER_LESSON_TEXT}
          </Typography>
        </Box>
        {kind === LessonKind.Custom ? (
          <Box mt={Spacing.s}>
            <RadioGroup
              value={saveToCurrent}
              onChange={(e) => {
                setSaveToCurrent(e.target.value === "true");
                e.target.value !== "true" ? setTopicId(undefined) : setTopicId(currentTopicId);
              }}
            >
              <FormControlLabel value={true} control={<Radio />} label="This topic" />
              <FormControlLabel value={false} control={<Radio />} label="A different topic" />
            </RadioGroup>
          </Box>
        ) : null}
        {!saveToCurrent ? (
          <SelectTopic topicId={topicId ?? currentTopicId} setTopicId={setTopicId} />
        ) : null}
      </DialogContent>

      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <Button disabled={!topicId} onClick={onDuplicate}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DuplicateLesson;
