import { useLazyQuery, useQuery } from "@apollo/client";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableFooter,
  TablePagination,
  TableRow,
  TextField,
  type Theme,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { format } from "date-fns";
import debounce from "lodash.debounce";
import { useSnackbar } from "notistack";
import { isEmpty, not } from "ramda";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { GET_SELECTED_USERS, SEARCH_USERS } from "../gql/queries/user";
import {
  BooleanOptions,
  PAGE_DEFAULT,
  ROWS_PER_PAGE_OPTIONS,
  UrlFilterParams,
} from "../helpers/const";
import { useOnOffSwitch, usePagination, useSwitch } from "../helpers/hooks";
import { transformUsersDataToCSV } from "../helpers/utils";

import { AdminUsersOrderBy, SortDirection, Spacing } from "../types/enum";
import { type UserModel } from "../types/graphql";

import BulkInviteSelectionModal from "src/components/BulkInvite/BulkInviteWithSelection/BulkInviteSelectionModal";
import UsersPageTableHead from "src/components/UsersPageTableHead";
import { useTableSelect } from "src/hooks/useTableSelect";
import AdvancedSearch from "../components/AdvancedSearch";
import UsersPageUserDetails from "../components/UsersPageUserDetails";

/**
 * Styles
 */
const useStyles = makeStyles((_theme: Theme) => ({
  search: {
    width: "50%",
  },
}));

const UsersPage: React.FC = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const filtersStr = useLocation().search;
  const queryParams = new URLSearchParams(filtersStr);

  const readParam = (key: string) => {
    const values = queryParams.get(key);
    if (!values) {
      return [];
    }
    return values.split(",");
  };

  const filters = {
    subscriptionStatus: readParam(UrlFilterParams.Subscription),
    plan: readParam(UrlFilterParams.Plan),
    kind: readParam(UrlFilterParams.Kind),
    location: JSON.parse(queryParams.get(UrlFilterParams.Location) ?? "[]"),
    interests: readParam(UrlFilterParams.Interests),
    englishSkills: readParam(UrlFilterParams.Skills),
    englishLevels: readParam(UrlFilterParams.Levels),
    openToSpeakingPartners: readParam(UrlFilterParams.Open),
    occupations: readParam(UrlFilterParams.Occupations),
    badges: readParam(UrlFilterParams.Badges),
    verificationStatus: readParam(UrlFilterParams.VerificationStatus),
  };

  const [query, setQuery] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [expanded, expand] = useSwitch();

  const { page, rowsPerPage, onChangePage, onChangeRowsPerPage } = usePagination<UserModel>();
  const [order, setOrder] = useState<SortDirection>(SortDirection.DESC);
  const [orderBy, setOrderBy] = useState<AdminUsersOrderBy>(AdminUsersOrderBy.Date);

  const handleRequestSort = (_event: React.MouseEvent<unknown>, property: AdminUsersOrderBy) => {
    const isAsc = orderBy === property && order === SortDirection.ASC;
    setOrder(isAsc ? SortDirection.DESC : SortDirection.ASC);
    setOrderBy(property);
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: we wannt to change page on filtersStr change
  useEffect(() => {
    onChangePage(null, PAGE_DEFAULT);
  }, [filtersStr, onChangePage]);

  const { loading, data } = useQuery(SEARCH_USERS, {
    fetchPolicy: "network-only",
    variables: {
      filters: {
        limit: rowsPerPage,
        offset: page * rowsPerPage,
        query: searchValue,
        ...filters,
        verificationStatus: filters.verificationStatus.map((o) => o === BooleanOptions.True),
        openToSpeakingPartners: filters.openToSpeakingPartners.map(
          (o) => o === BooleanOptions.True,
        ),
        order: order.toUpperCase(),
        orderBy,
      },
    },
  });

  const [runQueryToExport, { loading: exportLoading, error: exportError }] = useLazyQuery(
    GET_SELECTED_USERS,
    {
      onCompleted: (data) => {
        const csvData = transformUsersDataToCSV(data?.adminGetSelectedUsers);
        const csvBlob = new Blob([csvData], { type: "text/csv" });
        const downloadUrl = window.URL.createObjectURL(csvBlob);

        const link = document.createElement("a");
        link.href = downloadUrl;

        const formattedDate = format(new Date(), "yyyy-MM-dd");
        const filename = `HL-Export_${formattedDate}_(${data?.adminGetSelectedUsers.length}members).csv`;

        link.download = filename;
        link.click();
      },
    },
  );

  const exportUsers = () => {
    if (isEmpty(selected) && not(allSelected)) {
      enqueueSnackbar("Select users first.", { variant: "info" });
      return;
    }
    void runQueryToExport({
      variables: {
        filters: {
          ...filters,
          ids: selected,
          all: allSelected,
          verificationStatus: filters.verificationStatus.map((o) => o === BooleanOptions.True),
          openToSpeakingPartners: filters.openToSpeakingPartners.map(
            (o) => o === BooleanOptions.True,
          ),
        },
      },
    });
  };

  const debounceCallback = useCallback(
    debounce((searchVal: string) => {
      setSearchValue(searchVal);
    }, 1000),
    [],
  );

  const handleChange = (event: any) => {
    setQuery(event.target.value);

    debounceCallback(event.target.value);
  };

  if (exportError) {
    enqueueSnackbar(exportError.message, { variant: "error" });
  }

  const items = data?.adminListUsers?.items || [];
  const pageCount = Math.ceil(data?.adminListUsers?.total / rowsPerPage);
  const validPage = page < pageCount ? page : PAGE_DEFAULT;

  const { selected, setSelected, allSelected, onSelectOne, onSelectedAll, clearSelection } =
    useTableSelect();

  const idsOnCurrentPage = items.map((item: UserModel) => item.id);
  const allRowsChecked =
    selected.length > 0 && idsOnCurrentPage.every((id: string) => selected.includes(id));

  const selectedOnCurrentPage = selected.filter((id: string) =>
    idsOnCurrentPage.includes(id),
  ).length;
  const [selectCurrentPageChecked, setSelectCurrentPageChecked] = useState(
    allRowsChecked || allSelected,
  );

  const shouldClearSelection =
    selectedOnCurrentPage > 1 && !allSelected && !selectCurrentPageChecked;

  const onSelectCurrentPage = () => {
    if (selectedOnCurrentPage > 1 && !allSelected && !selectCurrentPageChecked) {
      const newSelected = selected.filter((id) => !idsOnCurrentPage.includes(id));
      setSelected(newSelected);
      return;
    }

    if (!selectCurrentPageChecked) {
      const idsOnCurrentPage = items.map((item: UserModel) => item.id);
      const newSelected = Array.from(new Set([...selected, ...idsOnCurrentPage]));
      setSelected(newSelected);
    } else {
      const idsToRemove = new Set(items.map((item: UserModel) => item.id));
      const newSelected = selected.filter((id) => !idsToRemove.has(id));
      setSelected(newSelected);
    }
    setSelectCurrentPageChecked((prev: boolean) => !prev);
  };

  useEffect(() => {
    setSelectCurrentPageChecked(allRowsChecked || allSelected);
  }, [allRowsChecked, allSelected]);

  const [openInviteModal, onOpenInviteModal, onCloseInviteModal] = useOnOffSwitch();

  const handleInviteClick = () => {
    if (isEmpty(selected) && not(allSelected)) {
      enqueueSnackbar("Copy Event Id and Select participants.", { variant: "info" });
      return;
    }
    onOpenInviteModal();
  };

  return (
    <>
      <Box mb={Spacing.m}>
        <Typography variant="h3" color="textPrimary">
          All Users
        </Typography>
      </Box>

      <Box mb={Spacing.m} display="flex" alignItems="center">
        <Box className={classes.search} width="50%" alignItems="center">
          <TextField
            fullWidth
            label="Search user"
            variant="outlined"
            value={query}
            onChange={handleChange}
          />
        </Box>
        <Box display="flex" alignItems="center" width="50%" justifyContent="space-between">
          <Box ml={Spacing.l} display="flex" alignItems="center">
            <Typography variant="h4" color="textPrimary">
              Advanced Search
            </Typography>
            <IconButton onClick={expand}>
              {expanded ? <ExpandLessIcon fontSize="large" /> : <ExpandMoreIcon fontSize="large" />}
            </IconButton>
          </Box>
          <Box mx={Spacing.sm}>
            <Button variant="outlined" onClick={handleInviteClick}>
              Invite to Event
            </Button>
          </Box>

          <Button variant="outlined" onClick={exportUsers} disabled={exportLoading}>
            Export Selection
          </Button>
        </Box>
      </Box>

      {expanded ? (
        <Box mb={Spacing.l}>
          <AdvancedSearch
            filters={filters}
            loading={loading}
            expanded={expanded}
            clearSelection={clearSelection}
          />
        </Box>
      ) : null}

      <Box display="flex" justifyContent="space-between" mb={Spacing.s}>
        <Box display="flex" alignItems="center">
          {allRowsChecked && !allSelected ? (
            <Box mr={Spacing.m}>
              <Typography variant="h5" color="textPrimary">
                {`All ${data?.adminListUsers.items.length} users on this page are selected`}
              </Typography>
            </Box>
          ) : null}
          {allSelected ? (
            <Button style={{ textTransform: "none" }} onClick={clearSelection}>
              <Typography variant="h5" color="secondary">
                Clear selection of all users
              </Typography>
            </Button>
          ) : (
            <Button style={{ textTransform: "none" }} onClick={onSelectedAll}>
              <Typography variant="h5" color="secondary">
                Select all {data?.adminListUsers.total || 0} in this list
              </Typography>
            </Button>
          )}
        </Box>
        <Typography variant="h5" color="textPrimary">
          {`${allSelected ? data?.adminListUsers.total : selected.length} users selected`}
        </Typography>
      </Box>

      <Card>
        {loading ? (
          <Box display="flex" justifyContent="center" p={Spacing.xxl}>
            <CircularProgress />
          </Box>
        ) : (
          <Table>
            <UsersPageTableHead
              order={order}
              orderBy={orderBy}
              selectCurrentPageChecked={selectCurrentPageChecked}
              shouldClearSelection={shouldClearSelection}
              onRequestSort={handleRequestSort}
              onSelectCurrentPage={onSelectCurrentPage}
            />
            <TableBody>
              {items.map((user: UserModel) => (
                <UsersPageUserDetails
                  key={user.id}
                  user={user}
                  selected={selected}
                  onSelectOne={onSelectOne}
                  allSelected={allSelected}
                />
              ))}
            </TableBody>

            <TableFooter>
              <TableRow>
                <TablePagination
                  page={validPage}
                  count={data?.adminListUsers.total || 0}
                  onPageChange={onChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={onChangeRowsPerPage}
                  rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                />
              </TableRow>
            </TableFooter>
          </Table>
        )}
      </Card>
      {
        <BulkInviteSelectionModal
          open={openInviteModal}
          onClose={onCloseInviteModal}
          filters={filters}
          selected={selected}
          allSelected={allSelected}
        />
      }
    </>
  );
};

export default UsersPage;
