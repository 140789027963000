import { useQuery } from "@apollo/client";
import { Box, CircularProgress, Divider, Tab, Tabs } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router";
import { Outlet, useMatch } from "react-router-dom";

import { Spacing } from "../types/enum";

import { GET_EVENT } from "../gql/queries/event";

import EventLayoutHeaderCont from "../containers/EventLayoutHeaderCont";

// import EventEditPage from "../pages/EventEditPage";
// import EventDetailsPage from "../pages/EventDetailsPage";
// import EventParticipantsPage from "../pages/EventParticipantsPage";
// import EventCommentsPage from "../pages/EventCommentsPage";

const EventLayout: React.FC = () => {
  const navigate = useNavigate();
  const { eventId } = useParams<Record<string, string>>();
  const match = useMatch("/event/:eventId/:pageName");

  const { data, loading, error } = useQuery(GET_EVENT, {
    fetchPolicy: "network-only",
    variables: {
      id: eventId,
    },
  });

  const onChange = (_: React.ChangeEvent<unknown>, nextPageName: string) => {
    navigate(`/event/${data?.getEventAdmin.id}/${nextPageName}`);
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <>Event not found</>;
  }

  return (
    <>
      <EventLayoutHeaderCont event={data?.getEventAdmin} />

      <Box mt={Spacing.m}>
        <Tabs
          scrollButtons="auto"
          variant="scrollable"
          textColor="secondary"
          value={match?.params.pageName ?? "details"}
          onChange={onChange}
        >
          <Tab label="Details" value="details" />
          <Tab label="Participants" value="participants" />
          <Tab label="Comments" value="comments" />
        </Tabs>
      </Box>

      <Divider />

      <Box mt={Spacing.m}>
        <Outlet />
      </Box>
    </>
  );
};

export default EventLayout;
