import { Box, TableCell, TableSortLabel } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { PropsWithChildren } from "react";
import { SortDirection } from "src/types/enum";

interface Props extends PropsWithChildren {
  sort: SortDirection;
  orderedByProp: boolean;
  sortHandler: React.MouseEventHandler<HTMLSpanElement> | undefined;
}

const SortableTableCell: React.FC<Props> = ({ children, sort, orderedByProp, sortHandler }) => {
  return (
    <TableCell sortDirection={orderedByProp ? sort : false}>
      <TableSortLabel
        active={orderedByProp}
        direction={orderedByProp ? sort : SortDirection.ASC}
        onClick={sortHandler}
      >
        {children}
        {orderedByProp ? (
          <Box component="span" sx={visuallyHidden}>
            {sort === SortDirection.DESC ? "sorted descending" : "sorted ascending"}
          </Box>
        ) : null}
      </TableSortLabel>
    </TableCell>
  );
};

export default SortableTableCell;
