import {
  Box,
  Card,
  Checkbox,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  type Theme,
  Typography,
} from "@mui/material";
import debounce from "lodash.debounce";
import React, { useCallback, useEffect, useState } from "react";

import makeStyles from "@mui/styles/makeStyles";

import { DEFAULT_OFFSET, ROWS_PER_PAGE_DEFAULT, ROWS_PER_PAGE_OPTIONS } from "../helpers/const";

import { Spacing } from "../types/enum";
import { useAdminSearchEventsQuery } from "../types/graphql";

import { usePagination } from "../helpers/hooks";

import EventsPageEventDetails from "../components/EventsPageEventDetails";

/**
 * Styles
 */
const useStyles = makeStyles((_theme: Theme) => ({
  search: {
    width: "50%",
  },
}));

const EventsPage: React.FC = () => {
  const classes = useStyles();

  const [query, setQuery] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [offset, setOffset] = useState<number>(DEFAULT_OFFSET);
  const [limit, setLimit] = useState<number>(ROWS_PER_PAGE_DEFAULT);

  const { data, loading, error } = useAdminSearchEventsQuery({
    fetchPolicy: "network-only",
    variables: {
      filters: {
        title: searchValue,
        limit,
        offset,
      },
    },
  });

  const { page, rowsPerPage, items, onChangePage, onChangeRowsPerPage } = usePagination(
    data?.adminSearchEvents?.items ?? [],
  );

  useEffect(() => {
    setOffset(page * rowsPerPage);
  }, [page, rowsPerPage]);

  useEffect(() => {
    setLimit(rowsPerPage);
  }, [rowsPerPage]);

  const debounceCallback = useCallback(
    debounce((searchVal: string) => {
      setSearchValue(searchVal);
    }, 1000),
    [],
  );

  const handleChange = (event: any) => {
    setQuery(event.target.value);

    debounceCallback(event.target.value);
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error || !data) {
    return <p>An error happened while loading events</p>;
  }

  return (
    <>
      <Box mb={Spacing.m}>
        <Typography variant="h3" color="textPrimary">
          All Events
        </Typography>
      </Box>
      <Box mb={Spacing.m} className={classes.search}>
        <TextField
          fullWidth
          label="Search event"
          variant="outlined"
          value={query}
          onChange={handleChange}
        />
      </Box>
      <Card>
        {loading ? (
          <Box display="flex" justifyContent="center" p={Spacing.xxl}>
            <CircularProgress />
          </Box>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox />
                </TableCell>
                <TableCell>Title</TableCell>
                <TableCell>ID</TableCell>
                <TableCell>Organizer</TableCell>
                <TableCell>Start Date</TableCell>
                <TableCell>Start time</TableCell>
                <TableCell>Duration</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(items ?? []).map((event) => (
                <EventsPageEventDetails key={event.id} event={event} />
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  page={page}
                  count={data?.adminSearchEvents?.total}
                  onPageChange={onChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={onChangeRowsPerPage}
                  rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                />
              </TableRow>
            </TableFooter>
          </Table>
        )}
      </Card>
    </>
  );
};

export default EventsPage;
