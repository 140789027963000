import { Box, Button, Typography } from "@mui/material";
import React from "react";

/**
 * Types
 */
interface Props {
  id: string;
  topicId: string;
  lessonPath: string;
  step?: number | null;
}

const CopyLinkToElement: React.FC<Props> = ({ id, topicId, lessonPath, step }: Props) => {
  const lessonPathWithoutStep = `${import.meta.env.VITE_APP_URL}/topic/${topicId}/${lessonPath}`;
  const url = step ? `${lessonPathWithoutStep}/${step}#${id}` : `${lessonPathWithoutStep}#${id}`;

  return (
    <Box display="flex" alignItems="center">
      <Button variant="text" color="primary" onClick={() => navigator.clipboard.writeText(url)}>
        <Typography variant="caption">Copy Link</Typography>
      </Button>
    </Box>
  );
};

export default CopyLinkToElement;
