import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import { not, values } from "ramda";
import React from "react";
import {
  Controller,
  type ControllerRenderProps,
  type SubmitHandler,
  useForm,
} from "react-hook-form";
import * as yup from "yup";

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";

import { CREATE_LESSON } from "../gql/mutations/lesson";
import { QUERY_TOPIC_THEMES_AND_ILLUSTRATIONS } from "../gql/queries/topic";

import { LESSON_NAMES } from "../helpers/const";

import { Spacing } from "../types/enum";
import { LessonKind, type LessonModel } from "../types/graphql";

import { useOnOffSwitch } from "../helpers/hooks";

import { type InferType } from "yup";
import TopicEditPageLesson from "../components/TopicEditPageLesson";

/**
 * Types
 */
interface Props {
  topicId: string;
  lessons: LessonModel[];
}

/**
 * Schema
 */
const schema = yup.object().shape({
  kind: yup.string().required(),
  title: yup.string().when("kind", {
    is: LessonKind.Custom,
    then: (schema) => schema.required(),
  }),
});
type FormValues = InferType<typeof schema>;

const TopicEditPageLessonsCont: React.FC<Props> = ({ topicId, lessons }: Props) => {
  const [open, onOpen, onClose] = useOnOffSwitch();

  const [createLesson] = useMutation(CREATE_LESSON, {
    refetchQueries: [{ query: QUERY_TOPIC_THEMES_AND_ILLUSTRATIONS, variables: { id: topicId } }],
  });

  const { enqueueSnackbar } = useSnackbar();

  const { control, formState, handleSubmit, register, watch, reset } = useForm<FormValues>({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      kind: undefined,
      title: undefined,
    },
  });

  const selectedKind = watch("kind");

  const renderers = {
    kind: ({
      field: { value, onChange },
    }: { field: ControllerRenderProps<FormValues, "kind"> }) => {
      return (
        <RadioGroup
          defaultChecked={!!value}
          onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
            onChange(target.value);
          }}
        >
          {values(LessonKind).map((kind) => (
            <FormControlLabel
              key={kind}
              value={kind}
              control={<Radio />}
              label={LESSON_NAMES.get(kind)}
              disabled={
                lessons.some((lesson) => lesson.kind === kind) && kind !== LessonKind.Custom
              }
            />
          ))}
        </RadioGroup>
      );
    },
  };

  const onSubmit: SubmitHandler<FormValues> = async (form) => {
    const variables = {
      input: {
        kind: form.kind,
        title: form.kind === LessonKind.Custom ? form.title : undefined,
        topicID: topicId,
      },
    };

    try {
      await createLesson({ variables });
      enqueueSnackbar("Lesson successfully created", { variant: "success" });
      reset();
      onClose();
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const onCancel = () => {
    reset();
    onClose();
  };

  return (
    <Card>
      <CardHeader title="Lessons" />

      <Divider />

      <CardContent>
        {lessons?.length ? (
          <Box mb={Spacing.sm}>
            {lessons.map((lesson: LessonModel) => (
              <TopicEditPageLesson
                key={lesson.id}
                topicId={topicId}
                title={lesson.title ? lesson.title : LESSON_NAMES.get(lesson.kind)}
                lessonId={lesson.id}
              />
            ))}
          </Box>
        ) : null}

        <Button variant="contained" color="primary" onClick={onOpen} fullWidth>
          Create lesson
        </Button>

        <Dialog open={open} onClose={onClose}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
              <Box mb={Spacing.s}>
                <Typography variant="body1" color="textPrimary">
                  Lesson type
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Please choose the type of lesson you would like to create.
                </Typography>
              </Box>

              <FormControl>
                <Controller name="kind" control={control} render={renderers.kind} />
              </FormControl>

              {selectedKind === LessonKind.Custom ? (
                <>
                  <Box my={Spacing.s}>
                    <Typography variant="body1" color="textPrimary">
                      Lesson title
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Enter the name of the lesson
                    </Typography>
                  </Box>
                  <FormControl fullWidth>
                    <TextField label="Title" variant="outlined" {...register("title")} />
                  </FormControl>
                </>
              ) : null}
            </DialogContent>

            <DialogActions>
              <Button onClick={onCancel}>Cancel</Button>
              <Button type="submit" disabled={not(formState.isValid)}>
                Create
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </CardContent>
    </Card>
  );
};

export default TopicEditPageLessonsCont;
