import {
  CssBaseline,
  type Theme as MuiTheme,
  StyledEngineProvider,
  ThemeProvider,
  adaptV4Theme,
  createTheme,
} from "@mui/material";
import { type Shadows } from "@mui/material/styles";
import React from "react";

declare module "@mui/styles/defaultTheme" {
  interface DefaultTheme extends MuiTheme {}
}

/**
 * Types
 */
interface Props {
  children: JSX.Element[] | JSX.Element;
}

/**
 * Typography
 */
const palette = {
  type: "dark" as const,
  action: {
    focus: "rgba(255, 255, 255, 0.12)",
    hover: "rgba(255, 255, 255, 0.04)",
    active: "rgba(255, 255, 255, 0.54)",
    selected: "rgba(255, 255, 255, 0.08)",
    disabled: "rgba(255, 255, 255, 0.26)",
    disabledBackground: "rgba(255, 255, 255, 0.12)",
  },
  background: {
    default: "#282C34",
    dark: "#1c2025",
    paper: "#282C34",
  },
  primary: {
    main: "#8a85ff",
  },
  secondary: {
    main: "#8a85ff",
  },
  text: {
    primary: "#e6e5e8",
    secondary: "#adb0bb",
  },
};

/**
 * Shadows
 */
const shadows: Shadows = [
  "none",
  "0 0 1px 0 rgba(0,0,0,0.70), 0 3px 4px -2px rgba(0,0,0,0.50)",
  "0 0 1px 0 rgba(0,0,0,0.70), 0 2px 2px -2px rgba(0,0,0,0.50)",
  "0 0 1px 0 rgba(0,0,0,0.70), 0 3px 4px -2px rgba(0,0,0,0.50)",
  "0 0 1px 0 rgba(0,0,0,0.70), 0 3px 4px -2px rgba(0,0,0,0.50)",
  "0 0 1px 0 rgba(0,0,0,0.70), 0 4px 6px -2px rgba(0,0,0,0.50)",
  "0 0 1px 0 rgba(0,0,0,0.70), 0 4px 6px -2px rgba(0,0,0,0.50)",
  "0 0 1px 0 rgba(0,0,0,0.70), 0 4px 8px -2px rgba(0,0,0,0.50)",
  "0 0 1px 0 rgba(0,0,0,0.70), 0 5px 8px -2px rgba(0,0,0,0.50)",
  "0 0 1px 0 rgba(0,0,0,0.70), 0 6px 12px -4px rgba(0,0,0,0.50)",
  "0 0 1px 0 rgba(0,0,0,0.70), 0 7px 12px -4px rgba(0,0,0,0.50)",
  "0 0 1px 0 rgba(0,0,0,0.70), 0 6px 16px -4px rgba(0,0,0,0.50)",
  "0 0 1px 0 rgba(0,0,0,0.70), 0 7px 16px -4px rgba(0,0,0,0.50)",
  "0 0 1px 0 rgba(0,0,0,0.70), 0 8px 18px -8px rgba(0,0,0,0.50)",
  "0 0 1px 0 rgba(0,0,0,0.70), 0 9px 18px -8px rgba(0,0,0,0.50)",
  "0 0 1px 0 rgba(0,0,0,0.70), 0 10px 20px -8px rgba(0,0,0,0.50)",
  "0 0 1px 0 rgba(0,0,0,0.70), 0 11px 20px -8px rgba(0,0,0,0.50)",
  "0 0 1px 0 rgba(0,0,0,0.70), 0 12px 22px -8px rgba(0,0,0,0.50)",
  "0 0 1px 0 rgba(0,0,0,0.70), 0 13px 22px -8px rgba(0,0,0,0.50)",
  "0 0 1px 0 rgba(0,0,0,0.70), 0 14px 24px -8px rgba(0,0,0,0.50)",
  "0 0 1px 0 rgba(0,0,0,0.70), 0 16px 28px -8px rgba(0,0,0,0.50)",
  "0 0 1px 0 rgba(0,0,0,0.70), 0 18px 30px -8px rgba(0,0,0,0.50)",
  "0 0 1px 0 rgba(0,0,0,0.70), 0 20px 32px -8px rgba(0,0,0,0.50)",
  "0 0 1px 0 rgba(0,0,0,0.70), 0 22px 34px -8px rgba(0,0,0,0.50)",
  "0 0 1px 0 rgba(0,0,0,0.70), 0 24px 36px -8px rgba(0,0,0,0.50)",
];

/**
 * Typography
 */
const typography = {
  h1: {
    fontSize: 35,
    fontWeight: 500,
    letterSpacing: "-0.24px",
  },
  h2: {
    fontSize: 29,
    fontWeight: 500,
    letterSpacing: "-0.24px",
  },
  h3: {
    fontSize: 24,
    fontWeight: 500,
    letterSpacing: "-0.06px",
  },
  h4: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: "-0.06px",
  },
  h5: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: "-0.05px",
  },
  h6: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: "-0.05px",
  },
  overline: {
    fontWeight: 500,
  },
};

/**
 * Theme
 */
const theme = createTheme(
  adaptV4Theme({
    palette,
    shadows,
    typography,
  }),
);

const Theme: React.FC<Props> = ({ children }: Props) => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default Theme;
