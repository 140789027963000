import { type AvatarProps, Avatar as MuiAvatar } from "@mui/material";
import classNames from "classnames";
import React from "react";

import makeStyles from "@mui/styles/makeStyles";

/**
 * Types
 */
interface Props extends AvatarProps {
  size?: "xs" | "sm" | "md" | "lg" | "xl";
}

/**
 * Styles
 */
const useStyles = makeStyles({
  sizeXs: {
    width: "32px",
    height: "32px",
  },
  sizeSm: {
    width: "44px",
    height: "44px",
  },
  sizeMd: {
    width: "48px",
    height: "48px",
  },
  sizeLg: {
    width: "104px",
    height: "104px",
  },
  sizeXl: {
    width: "112px",
    height: "112px",
  },
});

const TableFigure: React.FC<Props> = ({ size = "sm", ...props }: Props) => {
  const classes = useStyles();

  return (
    <MuiAvatar
      {...props}
      variant="square"
      className={classNames({
        [classes.sizeXs]: size === "xs",
        [classes.sizeSm]: size === "sm",
        [classes.sizeMd]: size === "md",
        [classes.sizeLg]: size === "lg",
        [classes.sizeXl]: size === "xl",
      })}
    />
  );
};

export default TableFigure;
