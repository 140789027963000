import { Box, FormControl, FormLabel, MenuItem, Select } from "@mui/material";
import React from "react";

import { Spacing } from "../types/enum";

/**
 * Types
 */
interface Props {
  value?: string;
  setValue: (text: string) => void;
}

/**
 * Constants
 */
const spacings = [
  [0, "xs"],
  [1, "s"],
  [2, "sm"],
  [3, "m"],
  [4, "ml"],
  [5, "l"],
  [6, "xl"],
  [7, "xxl"],
];

const LessonEditPageSectionSpacing: React.FC<Props> = ({ value, setValue }: Props) => {
  return (
    <FormControl fullWidth>
      <Box mb={Spacing.s}>
        <FormLabel>Spacing</FormLabel>
      </Box>
      <Select
        variant="outlined"
        value={String(value)}
        onChange={({ target }) => {
          setValue(target.value);
        }}
      >
        {spacings.map(([value, label]) => (
          <MenuItem value={value} key={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default LessonEditPageSectionSpacing;
