import { Checkbox, TableCell, TableHead, TableRow } from "@mui/material";
import React from "react";
import { SortDirection } from "src/types/enum";
import { AdminTopicsOrderBy } from "src/types/graphql";
import SortableTableCell from "../SortableTableCell";

interface Props {
  onRequestSort: (event: React.MouseEvent<unknown>, property: AdminTopicsOrderBy) => void;
  sort: SortDirection;
  orderBy: AdminTopicsOrderBy;
}

const TopicsPageTableHead: React.FC<Props> = ({ onRequestSort, sort, orderBy }: Props) => {
  const orderedByTitle = orderBy === AdminTopicsOrderBy.Title;
  const orderedByTheme = orderBy === AdminTopicsOrderBy.Theme;
  const orderedByLastUpdated = orderBy === AdminTopicsOrderBy.LastUpdated;

  const createSortHandler =
    (property: AdminTopicsOrderBy) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox />
        </TableCell>

        <SortableTableCell
          sort={sort}
          orderedByProp={orderedByTitle}
          sortHandler={createSortHandler(AdminTopicsOrderBy.Title)}
        >
          Title
        </SortableTableCell>

        <TableCell>Category</TableCell>

        <SortableTableCell
          sort={sort}
          orderedByProp={orderedByTheme}
          sortHandler={createSortHandler(AdminTopicsOrderBy.Theme)}
        >
          Theme
        </SortableTableCell>

        <SortableTableCell
          sort={sort}
          orderedByProp={orderedByLastUpdated}
          sortHandler={createSortHandler(AdminTopicsOrderBy.LastUpdated)}
        >
          Last Updated
        </SortableTableCell>

        <TableCell>Status</TableCell>

        <TableCell align="right">Actions</TableCell>
      </TableRow>
    </TableHead>
  );
};

export default TopicsPageTableHead;
