import { Box, IconButton, type Theme } from "@mui/material";
import { dec, inc } from "ramda";
import React, { ReactNode } from "react";

import makeStyles from "@mui/styles/makeStyles";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import CloseIcon from "@mui/icons-material/Close";

import { Spacing } from "../types/enum";

/**
 * Types
 */
interface Props {
  index: number;
  total: number;
  children: ReactNode;
  onSort: (index: number, destination: number) => void;
  onDelete: (index: number) => void;
}

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(Spacing.sm),
    marginBottom: theme.spacing(Spacing.s),
    border: `1px solid ${theme.palette.action.disabled}`,
    borderRadius: theme.shape.borderRadius,
    "&:last-of-type": {
      marginBottom: "unset",
    },
  },
}));

const SortableListItem: React.FC<Props> = ({ children, index, total, onSort, onDelete }: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.root} display="flex" alignItems="center">
      {children}
      <Box display="flex">
        {index ? (
          <IconButton
            size="small"
            onClick={() => {
              onSort(index, dec(index));
            }}
          >
            <ArrowUpwardIcon fontSize="small" />
          </IconButton>
        ) : null}
        {inc(index) < total ? (
          <IconButton
            size="small"
            onClick={() => {
              onSort(index, inc(index));
            }}
          >
            <ArrowDownwardIcon fontSize="small" />
          </IconButton>
        ) : null}
        <IconButton
          size="small"
          onClick={() => {
            onDelete(index);
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  );
};

export default SortableListItem;
