import { gql } from "@apollo/client";

export const DELETE_ILLUSTRATION = gql`
  mutation adminDeleteIllustration($id: String!) {
    adminDeleteIllustration(id: $id) {
      status
    }
  }
`;

export const CREATE_ILLUSTRATION = gql`
  mutation adminCreateIllustration($input: CreateIllustrationInput!) {
    adminCreateIllustration(input: $input) {
      id
      title
      url
    }
  }
`;

export const EDIT_ILLUSTRATION = gql`
  mutation adminEditIllustration($id: String!, $input: EditIllustrationInput!) {
    adminEditIllustration(id: $id, input: $input) {
      id
      title
      url
    }
  }
`;
