import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  TextField,
  type Theme,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import debounce from "lodash.debounce";
import React from "react";

import CopyLinkToElement from "src/components/Topics/CopyLinkToElement.tsx";
import { DEBOUNCE_TIME } from "../helpers/const";
import { Sizing, Spacing } from "../types/enum";
import { type AttachmentModel } from "../types/generic";
import FileDropzone from "./FileDropzone";

/**
 * Types
 */
interface BannerDetails {
  bannerText: string;
  buttonText?: string;
  buttonURL: string;
  bannerImageURL?: string;
}

interface Props {
  value: BannerDetails;
  setValue: (value: BannerDetails) => void;
  id: string;
  topicId: string;
  lessonPath: string;
  step?: number | null;
}

/**
 * Constants
 */
const IMAGE_INPUT_HEIGHT = 152;

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderWidth: "1px",
    borderStyle: "dashed",
    borderRadius: theme.shape.borderRadius,
    borderColor: theme.palette.action.disabled,
    paddingRight: Spacing.xxl,
    paddingBottom: Spacing.xxl,
  },
}));

const LessonEditPageSectionBanner: React.FC<Props> = ({
  value,
  setValue,
  id,
  topicId,
  lessonPath,
  step,
}: Props) => {
  const classes = useStyles();

  const onChange = debounce(setValue, DEBOUNCE_TIME);

  return (
    <Grid container spacing={Spacing.s} className={classes.root}>
      <Grid item xs={Sizing.Full}>
        <Box mb={Spacing.m} display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h5" color="textprimary">
            Banner
          </Typography>
          <CopyLinkToElement id={id} topicId={topicId} lessonPath={lessonPath} step={step} />
        </Box>
      </Grid>
      <Grid item container spacing={Spacing.s} xs={Sizing.Full} lg={Sizing.TwoThirds}>
        <Grid item xs={Sizing.Full} lg={Sizing.Full}>
          <Box mb={Spacing.s}>
            <FormLabel>Banner Text</FormLabel>
          </Box>
          <FormControl fullWidth>
            <TextField
              variant="outlined"
              placeholder="Banner Text"
              defaultValue={value.bannerText}
              name="bannerText"
              onChange={({ target }: React.ChangeEvent<HTMLInputElement>) =>
                onChange({ ...value, bannerText: target.value })
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={Sizing.Full} lg={Sizing.Half}>
          <Box mb={Spacing.s}>
            <FormLabel>Button Text</FormLabel>
          </Box>
          <FormControl fullWidth>
            <TextField
              variant="outlined"
              placeholder="Button Text"
              defaultValue={value.buttonText}
              name="buttonText"
              onChange={({ target }: React.ChangeEvent<HTMLInputElement>) =>
                onChange({ ...value, buttonText: target.value })
              }
            />
          </FormControl>
        </Grid>

        <Grid item xs={Sizing.Full} lg={Sizing.Half}>
          <Box mb={Spacing.s}>
            <FormLabel>Button URL</FormLabel>
          </Box>
          <FormControl fullWidth>
            <TextField
              variant="outlined"
              placeholder="Button URL"
              defaultValue={value.buttonURL}
              name="buttonURL"
              onChange={({ target }: React.ChangeEvent<HTMLInputElement>) =>
                onChange({ ...value, buttonURL: target.value })
              }
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={Sizing.Full} lg={Sizing.OneThird}>
        <Box mb={Spacing.s}>
          <FormLabel>Banner Image</FormLabel>
        </Box>
        <FormControl fullWidth>
          <FileDropzone
            preview
            showFileContained
            fileName={value.bannerImageURL}
            onDropFile={(attachment: AttachmentModel) => {
              setValue({ ...value, bannerImageURL: attachment.url });
            }}
            height={IMAGE_INPUT_HEIGHT}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default LessonEditPageSectionBanner;
