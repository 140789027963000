import { useQuery } from "@apollo/client";
import React from "react";

import { Card, CircularProgress, LinearProgress } from "@mui/material";

import { GET_EVENT_AND_TOPICS } from "../gql/queries/event";

import { useParams } from "react-router-dom";
import EventEditPageFormCont from "../containers/EventEditPageFormCont";

// interface Props {
//   eventId: string;
// }

const EventEditPage: React.FC = () => {
  const { eventId } = useParams();
  const { data, loading } = useQuery(GET_EVENT_AND_TOPICS, {
    fetchPolicy: "cache-and-network",
    variables: {
      id: eventId,
    },
  });

  if (loading) {
    return <CircularProgress />;
  }

  const event = data?.getEventAdmin;
  const topics = data?.adminListTopics;

  return (
    <Card>
      {loading ? <LinearProgress /> : null}
      <EventEditPageFormCont event={event} topics={topics} />
    </Card>
  );
};

export default EventEditPage;
