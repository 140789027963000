import { useQuery } from "@apollo/client";
import React from "react";
import { Link as RouterLink, useParams } from "react-router-dom";

import {
  Avatar,
  Box,
  Card,
  Checkbox,
  CircularProgress,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { GET_EVENT_COMMENTS } from "../gql/queries/event";

import { Spacing } from "../types/enum";
import { type CommentModel } from "../types/graphql";

const EventCommentsPage: React.FC = () => {
  const { eventId } = useParams<Record<string, string>>();

  const { data, loading } = useQuery(GET_EVENT_COMMENTS, {
    fetchPolicy: "network-only",
    variables: {
      id: eventId,
    },
  });

  if (loading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Card>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox />
            </TableCell>
            <TableCell>Author</TableCell>
            <TableCell>Message</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {data?.getEventAdmin?.comments.map((comment: CommentModel) => (
            <TableRow key={comment?.id} hover>
              <TableCell padding="checkbox">
                <Checkbox />
              </TableCell>

              <TableCell>
                {comment.author ? (
                  <Box display="flex" alignItems="center">
                    <Avatar src={comment.author.avatarUrl!} />
                    <Box ml={Spacing.sm}>
                      <Link
                        color="inherit"
                        component={RouterLink}
                        to={`/users/${comment?.author.id}`}
                      >
                        <Typography variant="body1">{comment?.author.name}</Typography>
                      </Link>
                    </Box>
                  </Box>
                ) : (
                  <Box display="flex" alignItems="center">
                    <Typography variant="body1" component="span">
                      Inactive Member
                    </Typography>
                  </Box>
                )}
              </TableCell>

              <TableCell>{comment?.message}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  );
};

export default EventCommentsPage;
