import { format, formatDistance } from "date-fns";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { ArrowRight as ArrowRightIcon, Edit as EditIcon } from "react-feather";
import { Link as RouterLink } from "react-router-dom";
import useClipboard from "react-use-clipboard";

import {
  Box,
  Checkbox,
  IconButton,
  Link,
  SvgIcon,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { Spacing } from "../types/enum";
import { type AdminSearchEventsQuery } from "../types/graphql";

import { EVENT_PAGE_DATE_FORMAT, EVENT_PAGE_TIME_FORMAT } from "../helpers/const";

import Label from "./Label";

/**
 * Types
 */
interface Props {
  event: NonNullable<AdminSearchEventsQuery["adminSearchEvents"]["items"]>[0];
}

/**
 * Styles
 */
const useStyles = makeStyles({
  title: {
    maxWidth: "240px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});

const EventsPageEventDetails: React.FC<Props> = ({ event }: Props) => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const [isCopied, setCopied] = useClipboard(event?.id);

  useEffect(() => {
    if (isCopied) {
      enqueueSnackbar("ID successfully copied", { variant: "success" });
    }
  });

  const renderers = {
    name: () => {
      return (
        <>
          {event?.organizer?.firstName} {event?.organizer?.lastName}
        </>
      );
    },
  };

  return (
    <TableRow key={event.id} hover>
      <TableCell padding="checkbox">
        <Checkbox />
      </TableCell>

      <TableCell>
        <Box display="flex" alignItems="center">
          <Box ml={Spacing.sm}>
            <Link color="inherit" component={RouterLink} to={`/event/${event?.id}/details`}>
              <Typography variant="body2" className={classes.title}>
                {event?.title}
              </Typography>
            </Link>
          </Box>
        </Box>
      </TableCell>

      <TableCell>
        <Label variant="info" onClick={setCopied}>
          {event?.id}
        </Label>
      </TableCell>

      <TableCell>
        {event.organizer && (
          <Link color="inherit" component={RouterLink} to={`/users/${event.organizer.id}`}>
            <Label variant="success">{renderers.name()}</Label>
          </Link>
        )}
      </TableCell>

      <TableCell>
        <Typography variant="body2" className={classes.title}>
          {event?.startDate ? format(event?.startDate, EVENT_PAGE_DATE_FORMAT) : "-"}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant="body2" className={classes.title}>
          {event?.startDate ? format(event?.startDate, EVENT_PAGE_TIME_FORMAT) : "-"}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant="body2" className={classes.title}>
          {event?.endDate ? formatDistance(event?.endDate, event?.startDate) : "?"}
        </Typography>
      </TableCell>

      <TableCell align="right">
        <Link component={RouterLink} to={`/event/${event?.id}/edit`}>
          <IconButton size="large">
            <SvgIcon fontSize="small">
              <EditIcon />
            </SvgIcon>
          </IconButton>
        </Link>
        <Link color="inherit" component={RouterLink} to={`/event/${event?.id}/details`}>
          <IconButton size="large">
            <SvgIcon fontSize="small">
              <ArrowRightIcon />
            </SvgIcon>
          </IconButton>
        </Link>
      </TableCell>
    </TableRow>
  );
};

export default EventsPageEventDetails;
