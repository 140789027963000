import { gql } from "@apollo/client";

export const DELETE_LESSON = gql`
  mutation adminDeleteLesson($id: String!) {
    adminDeleteLesson(id: $id) {
      reason
      status
    }
  }
`;

export const CREATE_LESSON = gql`
  mutation adminCreateLesson($input: CreateLessonInput!) {
    adminCreateLesson(input: $input) {
      id
      sections {
        id
        order
        elements {
          kind
          meta
        }
      }
    }
  }
`;

export const EDIT_LESSON = gql`
  mutation adminEditLesson($id: String!, $input: EditLessonInput!) {
    adminEditLesson(id: $id, input: $input) {
      id
      deepDiveKind
      kind
    }
  }
`;
