import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Link,
  type Theme,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { not } from "ramda";
import React from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import makeStyles from "@mui/styles/makeStyles";

import { GET_USER_ADMIN } from "../gql/queries/user";

import { Spacing } from "../types/enum";
import {
  type UserModel,
  useAdminBanUserMutation,
  useAdminUnbanUserMutation,
  useDeleteAccountMutation,
} from "../types/graphql";

import { useOnOffSwitch } from "../helpers/hooks";

import { useImpersonate } from "src/hooks/useImpersonate";
import ConfirmationModal from "./ConfirmationModal";

/**
 * Types
 */
interface Props {
  user: UserModel;
}

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) => ({
  error: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
  warning: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.warning.main,
    "&:hover": {
      backgroundColor: theme.palette.warning.dark,
    },
  },
}));

const UserPageDetailsInfoOthersCard: React.FC<Props> = ({ user }: Props) => {
  const classes = useStyles();
  const history = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const [openDelete, onOpenDelete, onCloseDelete] = useOnOffSwitch();
  const [openBan, onOpenBan, onCloseBan] = useOnOffSwitch();
  const [openUnBan, onOpenUnBan, onCloseUnBan] = useOnOffSwitch();

  const [deleteAccount] = useDeleteAccountMutation();
  const impersonateMutation = useImpersonate(user.id);

  const [banUser] = useAdminBanUserMutation({
    refetchQueries: [{ query: GET_USER_ADMIN, variables: { id: user.id } }],
  });

  const [unbanUser] = useAdminUnbanUserMutation({
    refetchQueries: [{ query: GET_USER_ADMIN, variables: { id: user.id } }],
  });

  const onBan = async (): Promise<any> => {
    const variables = {
      id: user.id,
    };

    try {
      await banUser({ variables });
      enqueueSnackbar("User was banned successfully", { variant: "success" });
      onCloseBan();
    } catch (_error) {
      enqueueSnackbar("There was a problem banning user", { variant: "error" });
    }
  };

  const onUnban = async (): Promise<any> => {
    const variables = {
      id: user.id,
    };

    try {
      await unbanUser({ variables });
      enqueueSnackbar("User was unbanned successfully", { variant: "success" });
      onCloseUnBan();
    } catch (_error) {
      enqueueSnackbar("There was a problem banning user", { variant: "error" });
    }
  };

  const onSubmit = async (): Promise<any> => {
    const variables = {
      id: user.id,
    };

    try {
      await deleteAccount({ variables });
      history("/users");
      enqueueSnackbar("User was deleted successfully", { variant: "success" });
    } catch (_error) {
      enqueueSnackbar("There was a problem deleting user", { variant: "error" });
    }
  };

  return (
    <Card>
      <CardHeader title="Other actions" />
      <Divider />
      <CardContent>
        <Box display="flex">
          <Link component={RouterLink} to={`/users/${user.id}/edit`} underline="none">
            <Button variant="contained" color="secondary">
              Edit
            </Button>
          </Link>

          <Box ml={Spacing.sm}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                impersonateMutation.mutate();
              }}
            >
              Impersonate
            </Button>
          </Box>

          {not(user.isBanned) ? (
            <Box ml={Spacing.sm}>
              <Button
                variant="contained"
                color="secondary"
                className={classes.error}
                onClick={onOpenBan}
              >
                Ban
              </Button>
              <ConfirmationModal
                open={openBan}
                onClose={onCloseBan}
                onSubmit={onBan}
                description="This action will make the user unable to attend events where it was invited."
                label="Ban"
              />
            </Box>
          ) : (
            <Box ml={Spacing.sm}>
              <Button variant="contained" className={classes.warning} onClick={onOpenUnBan}>
                Unban
              </Button>
              <ConfirmationModal
                open={openUnBan}
                onClose={onCloseUnBan}
                onSubmit={onUnban}
                description="This action will make the user able to attend events where it was invited."
                label="Unban"
              />
            </Box>
          )}

          <Box ml={Spacing.sm}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.error}
              onClick={onOpenDelete}
            >
              Delete
            </Button>
          </Box>
          <ConfirmationModal open={openDelete} onSubmit={onSubmit} onClose={onCloseDelete} />
        </Box>
      </CardContent>
    </Card>
  );
};

export default UserPageDetailsInfoOthersCard;
