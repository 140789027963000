import { useQuery } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { useDebounce } from "react-use";
import { type AutocompleteOptionModel } from "src/types/generic";
import Autocomplete from "./Autocomplete";

/**
 * Types
 */
interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}

interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}

interface PlaceType {
  place_id: string;
  description: string;
  structured_formatting: StructuredFormatting;
}

interface LocationFilterProps {
  label: string;
  value?: AutocompleteOptionModel | AutocompleteOptionModel[] | null;
  onChange: (option: AutocompleteOptionModel[] | AutocompleteOptionModel | null) => void;
}

/**
 * Helpers
 */
async function fetchPlaces(request: { input: string }) {
  const autocompleteService = new google.maps.places.AutocompleteService();
  return await autocompleteService.getPlacePredictions({
    ...request,
    types: ["(regions)"],
    language: "en",
  });
}

/**
 * Constants
 */
const BLUR = "blur";
const RESET = "reset";
const EMPTY_INPUT = "";

const UsersLocationFilter: React.FC<LocationFilterProps> = ({
  value,
  onChange,
  label,
}: LocationFilterProps) => {
  const [inputValue, setInputValue] = React.useState(EMPTY_INPUT);
  const [debouncedInputValue, setDebouncedInputValue] = React.useState(EMPTY_INPUT);
  useDebounce(
    () => {
      setDebouncedInputValue(inputValue);
    },
    500,
    [inputValue],
  );

  const [locationOptions, setLocationOptions] = React.useState<AutocompleteOptionModel[]>([]);

  const queryCities = useQuery<any>({
    queryKey: ["queryCities", debouncedInputValue],
    queryFn: async () => {
      const places = await fetchPlaces({ input: debouncedInputValue });
      return places.predictions;
    },
    enabled: debouncedInputValue !== EMPTY_INPUT,
  });

  useEffect(() => {
    if (!queryCities.data) {
      return;
    }
    setLocationOptions(
      queryCities.data.map((result: PlaceType) => ({
        value: result.place_id,
        name: result.description,
        id: result.place_id,
      })) as AutocompleteOptionModel[],
    );
  }, [queryCities.data]);

  return (
    <Autocomplete
      multiple
      options={locationOptions}
      value={value}
      label={label}
      onChange={onChange}
      onInputChange={(event: React.ChangeEvent<any>, newInputValue: string, reason: string) => {
        if (event && event.type === BLUR) {
          setInputValue(EMPTY_INPUT);
        } else if (reason !== RESET) {
          setInputValue(newInputValue);
        }
      }}
      noOptionsText="No Results"
    />
  );
};

export default UsersLocationFilter;
