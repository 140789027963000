import { gql } from "@apollo/client";

export const DELETE_ARTICLE = gql`
  mutation adminDeleteArticle($id: String!) {
    adminDeleteArticle(id: $id) {
      status
    }
  }
`;

export const EDIT_ARTICLE = gql`
  mutation adminEditArticle($id: String!, $input: EditArticleInput!) {
    adminEditArticle(id: $id, input: $input) {
      id
      title
      isVisible
      isFeatured
      isDraft
    }
  }
`;
