import { gql } from "@apollo/client";

export const GENERATE_ATTACHMENT_UPLOAD_URL = gql`
  mutation adminGenerateAttachmentUploadUrl($filename: String!) {
    adminGenerateAttachmentUploadUrl(filename: $filename) {
      contentURL
      uploadURL
    }
  }
`;
