import { Box, IconButton, Link, SvgIcon, TableCell, TableRow, type Theme } from "@mui/material";
import { isNil } from "ramda";
import React from "react";
import { Trash2 as DeleteIcon, Edit as EditIcon } from "react-feather";
import { Link as RouterLink } from "react-router-dom";

import makeStyles from "@mui/styles/makeStyles";

import { staticFileUrl } from "../helpers/utils";

import { Spacing } from "../types/enum";
import { type ZineModel } from "../types/graphql";

import { useOnOffSwitch } from "../helpers/hooks";

import ConfirmationModal from "./ConfirmationModal";
import Label from "./Label";

/**
 * Types
 */
interface Props {
  zineCover: ZineModel;
  onDelete: (illustrationId: string) => void;
}

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) => ({
  image: {
    width: "auto",
    maxHeight: "150px",
    height: "auto",
    display: "block",
    borderRadius: theme.shape.borderRadius,
  },
  placeholder: {
    height: "100%",
    padding: theme.spacing(Spacing.ml),
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.action.disabled}`,
  },
  item: {
    maxWidth: "250px",
  },
}));

const ZineCoversPageCoverDetails: React.FC<Props> = ({ zineCover, onDelete }: Props) => {
  const classes = useStyles();

  const [open, onOpen, onClose] = useOnOffSwitch();

  const renderers = {
    image: (variant: string | undefined | null) => {
      if (isNil(variant)) {
        return (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            className={classes.placeholder}
          >
            <Label variant="error">Missing image</Label>
          </Box>
        );
      }
      return <img src={variant} alt={variant} className={classes.image} />;
    },
  };

  return (
    <TableRow>
      <TableCell>{renderers.image(staticFileUrl(zineCover?.coverUrl))}</TableCell>

      <TableCell>
        {zineCover?.isCurrent ? (
          <Label variant="success">True</Label>
        ) : (
          <Label variant="error">False</Label>
        )}
      </TableCell>

      <TableCell align="right">
        <Link component={RouterLink} to={`/zine-covers/${zineCover.id}/edit`}>
          <IconButton size="large">
            <SvgIcon fontSize="small">
              <EditIcon />
            </SvgIcon>
          </IconButton>
        </Link>
        <IconButton onClick={onOpen} size="large">
          <SvgIcon fontSize="small">
            <DeleteIcon />
          </SvgIcon>
        </IconButton>
      </TableCell>

      <ConfirmationModal
        open={open}
        onSubmit={() => {
          onDelete(zineCover.id);
        }}
        onClose={onClose}
      />
    </TableRow>
  );
};

export default ZineCoversPageCoverDetails;
