import { useMutation, useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  Card,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";

import { CREATE_ZINE_COVER, DELETE_ZINE_COVER } from "../gql/mutations/zineCover";
import { QUERY_ZINE_COVERS } from "../gql/queries/zineCover";
import { DEFAULT_OFFSET, ROWS_PER_PAGE_DEFAULT, ROWS_PER_PAGE_OPTIONS } from "../helpers/const";
import { usePagination } from "../helpers/hooks";

import { Spacing } from "../types/enum";
import { type ZineModel } from "../types/graphql";

import ZineCoversPageCoverDetails from "../components/ZineCoversPageCoverDetails";

const ZineCoversPage: React.FC = () => {
  const [offset, setOffset] = useState<number>(DEFAULT_OFFSET);
  const [limit, setLimit] = useState<number>(ROWS_PER_PAGE_DEFAULT);

  const [deleteZineCover] = useMutation(DELETE_ZINE_COVER, {
    refetchQueries: [
      {
        query: QUERY_ZINE_COVERS,
        variables: {
          filters: {
            limit,
            offset,
          },
        },
      },
    ],
  });

  const { enqueueSnackbar } = useSnackbar();

  const { data, loading } = useQuery(QUERY_ZINE_COVERS, {
    fetchPolicy: "network-only",
    variables: {
      filters: {
        limit,
        offset,
      },
    },
  });

  const { page, rowsPerPage, items, onChangePage, onChangeRowsPerPage } = usePagination<ZineModel>(
    data?.adminListPaginatedZineCovers?.items,
  );

  useEffect(() => {
    setOffset(page * rowsPerPage);
  }, [page, rowsPerPage]);

  useEffect(() => {
    setLimit(rowsPerPage);
  }, [rowsPerPage]);

  const history = useNavigate();

  const onDelete = async (zineCoverID: string) => {
    const variables = {
      id: zineCoverID,
    };

    try {
      await deleteZineCover({ variables });
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const [createZineCover] = useMutation(CREATE_ZINE_COVER, {
    refetchQueries: [
      {
        query: QUERY_ZINE_COVERS,
        variables: {
          filters: {
            limit,
            offset,
          },
        },
      },
    ],
  });

  const onSubmit = async () => {
    const variables = {
      input: {
        coverUrl: "",
      },
    };
    try {
      const response = await createZineCover({ variables });
      const coverId = response.data?.adminCreateZineCover?.id;
      history(`/zine-covers/${coverId}/edit`);
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return (
    <>
      <Box mb={Spacing.m} display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h3" color="textPrimary">
          All Zine Covers
        </Typography>

        <Button variant="contained" color="secondary" onClick={onSubmit}>
          Add Zine Cover
        </Button>
      </Box>

      <Card>
        {loading ? (
          <Box display="flex" justifyContent="center" p={Spacing.xxl}>
            <CircularProgress />
          </Box>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Image</TableCell>
                <TableCell>Is current</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items?.map((zineCover: ZineModel) => (
                <ZineCoversPageCoverDetails
                  key={zineCover?.id}
                  zineCover={zineCover}
                  onDelete={onDelete}
                />
              ))}
            </TableBody>

            <TableFooter>
              <TableRow>
                <TablePagination
                  page={page}
                  count={data?.adminListPaginatedZineCovers.total}
                  onPageChange={onChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={onChangeRowsPerPage}
                  rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                />
              </TableRow>
            </TableFooter>
          </Table>
        )}
      </Card>
    </>
  );
};

export default ZineCoversPage;
