import { useQuery } from "@apollo/client";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, CircularProgress, Link, Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router";
import { Link as RouterLink } from "react-router-dom";

import { Spacing } from "../types/enum";

import ZineCoversEditFormCont from "../containers/ZineCoversEditFormCont";
import { QUERY_ZINE_COVER } from "../gql/queries/zineCover";

const ZineCoversEditPage: React.FC = () => {
  const params = useParams<Record<string, string>>();

  const { data, loading } = useQuery(QUERY_ZINE_COVER, {
    fetchPolicy: "network-only",
    variables: {
      id: params?.zineCoverId,
    },
  });

  if (loading) {
    return <CircularProgress />;
  }

  const zineCover = data?.adminGetZineCover;

  return (
    <>
      <Box display="flex" alignItems="center" mb={Spacing.m}>
        <Link color="inherit" component={RouterLink} to="/zine-covers">
          <Box mr={Spacing.sm}>
            <ArrowBackIcon />
          </Box>
        </Link>

        <Typography variant="h3" color="textPrimary">
          Cover
        </Typography>
      </Box>

      <ZineCoversEditFormCont zineCover={zineCover} />
    </>
  );
};

export default ZineCoversEditPage;
