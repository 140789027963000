import { gql } from "@apollo/client";

export const DELETE_ACCOUNT = gql`
  mutation deleteAccount($id: String!) {
    deleteAccount(id: $id) {
      status
    }
  }
`;

export const EDIT_USER = gql`
  mutation adminEditUser($id: String!, $input: EditUserInput!) {
    adminEditUser(id: $id, input: $input) {
      status
    }
  }
`;

export const CREATE_NOTE = gql`
  mutation adminCreateNote($id: String!, $input: NoteInput!) {
    adminCreateNote(userID: $id, input: $input) {
      id
      description
    }
  }
`;

export const BAN_USER = gql`
  mutation adminBanUser($id: String!) {
    adminBanUser(id: $id) {
      status
    }
  }
`;

export const UNBAN_USER = gql`
  mutation adminUnbanUser($id: String!) {
    adminUnbanUser(id: $id) {
      status
    }
  }
`;

export const ASSIGN_BADGE = gql`
  mutation assignUserBadge($input: AssignOrDeleteBadgeInput!) {
    assignUserBadge(input: $input) {
      status
    }
  }
`;

export const DELETE_BADGE = gql`
  mutation deleteUserBadge($input: AssignOrDeleteBadgeInput!) {
    deleteUserBadge(input: $input) {
      status
    }
  }
`;
