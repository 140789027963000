import { Dialog } from "@mui/material";
import React, { useState } from "react";
import { FormStates } from "../types";
import BulkInviteSelectionInviteForm from "./BulkInviteSelectionInviteForm";
import BulkInviteSelectionValidateForm from "./BulkInviteSelectionValidateForm";

/**
 * Types
 */
interface Props {
  open: boolean;
  onClose: () => void;
  filters: any;
  selected: string[];
  allSelected: boolean;
}

const BulkInviteSelectionModal: React.FC<Props> = ({
  open,
  onClose,
  filters,
  selected,
  allSelected,
}: Props) => {
  const [formState, setFormState] = useState<FormStates>(FormStates.Validate);
  const [eventId, setEventId] = useState<string | null>(null);
  const [eventTitle, setEventTitle] = useState<string | null>(null);
  const [validatedUserIds, setValidatedUserIds] = useState<string[]>([]);
  const [serverMessages, setServerMessages] = useState<string[]>([]);

  const onCancel = () => {
    setFormState(FormStates.Validate);
    setEventId(null);
    setEventTitle(null);
    setValidatedUserIds([]);
    setServerMessages([]);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onCancel}>
      {formState === FormStates.Confirm && eventId ? (
        <BulkInviteSelectionInviteForm
          onClose={onCancel}
          eventId={eventId}
          eventTitle={eventTitle}
          validatedUserIds={validatedUserIds}
          serverMessages={serverMessages}
          setServerMessages={setServerMessages}
        />
      ) : (
        <BulkInviteSelectionValidateForm
          formState={formState}
          setFormState={setFormState}
          onClose={onCancel}
          filters={filters}
          selected={selected}
          allSelected={allSelected}
          setEventId={setEventId}
          eventTitle={eventTitle}
          setEventTitle={setEventTitle}
          validatedUserIds={validatedUserIds}
          onValidated={setValidatedUserIds}
          serverMessages={serverMessages}
          setServerMessages={setServerMessages}
        />
      )}
    </Dialog>
  );
};

export default BulkInviteSelectionModal;
