import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Avatar, Box, CircularProgress, Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";

import { type UserModel, useGetUserAdminQuery } from "src/types/graphql";
import UserPageDetailsCont from "../containers/UserPageDetailsCont";
import { Spacing } from "../types/enum";

const UserPage: React.FC = () => {
  const params = useParams<Record<string, string>>();
  const navigate = useNavigate();

  const { data, loading } = useGetUserAdminQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      id: params?.userId ?? "",
    },
  });

  const goBack = () => {
    navigate(-1);
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Box mb={Spacing.m}>
        <Box display="flex" alignItems="center">
          <Box mr={Spacing.sm} onClick={goBack}>
            <ArrowBackIcon />
          </Box>
          <Avatar src={data?.getUserAdmin?.avatarUrl ?? ""} />
          <Box ml={Spacing.sm}>
            <Typography variant="h3" color="textPrimary">
              {data?.getUserAdmin?.firstName} {data?.getUserAdmin?.lastName}
            </Typography>
          </Box>
        </Box>
      </Box>
      {data?.getUserAdmin && <UserPageDetailsCont user={data?.getUserAdmin as UserModel} />}
    </>
  );
};

export default UserPage;
