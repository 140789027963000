import { type CardProps, Card as MuiCard } from "@mui/material";
import React from "react";

import makeStyles from "@mui/styles/makeStyles";

/**
 * Types
 */
interface Props extends CardProps {
  overflow?: boolean;
}

/**
 * Styles
 */
const useStyles = makeStyles({
  overflow: {
    overflow: "initial",
  },
});

const Card: React.FC<Props> = ({ overflow, ...props }: Props) => {
  const classes = useStyles();

  return <MuiCard {...props} className={overflow ? classes.overflow : undefined} />;
};

export default Card;
