import { useEffect, useState } from "react";
import { useDebounce } from "react-use";

import {
  Box,
  Card,
  CircularProgress,
  Table,
  TableBody,
  TableFooter,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";

import { useOnOffSwitch, usePagination } from "../helpers/hooks";

import {
  DEBOUNCE_TIME,
  DEFAULT_OFFSET,
  ROWS_PER_PAGE_DEFAULT,
  ROWS_PER_PAGE_OPTIONS,
} from "../helpers/const";

import { Spacing, SortDirection as TableSortDirection } from "../types/enum";
import {
  AdminTopicsOrderBy,
  SortDirection,
  type TopicModel,
  useAdminDeleteTopicsMutation,
  useAdminListPaginatedTopicsQuery,
} from "../types/graphql";

import { enqueueSnackbar } from "notistack";
import { isNotEmpty } from "ramda";
import ConfirmationModal from "src/components/ConfirmationModal";
import DuplicateTopic from "src/components/Topics/DuplicateTopic.tsx";
import TopicsActions from "src/components/Topics/TopicsActions";
import TopicsPageTableHead from "src/components/Topics/TopicsPageTableHead";
import { TopicsFilters } from "src/components/Topics/types";
import { useTableSelect } from "src/hooks/useTableSelect";
import TopicsPageTopicDetails from "../components/TopicsPageTopicDetails";
import TopicsPageCreateTopicCont from "../containers/TopicsPageCreateTopicCont";

const TopicsPage: React.FC = () => {
  const [query, setQuery] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const [filters, setFilters] = useState<TopicsFilters>({
    category: undefined,
    theme: undefined,
    status: undefined,
  });
  const handleUpdateFilters = (newFilters: TopicsFilters) => {
    setFilters(newFilters);
    onResetPage();
  };

  const [open, onOpen, onClose] = useOnOffSwitch();
  const [isOpen, openDuplicateTopic, closeDuplicateTopic] = useOnOffSwitch();
  const [topicId, setTopicId] = useState<string>("");

  const [offset, setOffset] = useState<number>(DEFAULT_OFFSET);
  const [limit, setLimit] = useState<number>(ROWS_PER_PAGE_DEFAULT);

  const [sort, setSort] = useState<SortDirection>(SortDirection.Desc);
  const [orderBy, setOrderBy] = useState<AdminTopicsOrderBy>(AdminTopicsOrderBy.LastUpdated);

  const { selected, onSelectOne } = useTableSelect();
  const [isDeleteTopicModalOpen, openDeleteTopicModal, closeDeleteTopicModal] = useOnOffSwitch();

  const handleRequestSort = (_event: React.MouseEvent<unknown>, property: AdminTopicsOrderBy) => {
    const isAsc = orderBy === property && sort === SortDirection.Asc;
    setSort(isAsc ? SortDirection.Desc : SortDirection.Asc);
    setOrderBy(property);
  };

  useDebounce(
    () => {
      setSearchValue(query);
    },
    DEBOUNCE_TIME,
    [query],
  );

  const handleChange = (event: any) => {
    setQuery(event.target.value);
    onResetPage();
  };

  const clearSearch = () => {
    setQuery("");
    setSearchValue("");
    onResetPage();
  };

  const { loading, data, error, refetch } = useAdminListPaginatedTopicsQuery({
    fetchPolicy: "network-only",
    variables: {
      filters: {
        text: searchValue,
        category: filters.category,
        theme: filters.theme,
        status: filters.status,
        limit,
        offset,
        orderBy,
        sort,
      },
    },
  });

  const [adminDeleteTopicsMutation, { loading: deletingTopics }] = useAdminDeleteTopicsMutation();

  const { page, rowsPerPage, items, onChangePage, onChangeRowsPerPage, onResetPage } =
    usePagination<TopicModel>(data?.adminListPaginatedTopics?.items as TopicModel[]);

  useEffect(() => {
    setOffset(page * rowsPerPage);
  }, [page, rowsPerPage]);

  useEffect(() => {
    setLimit(rowsPerPage);
  }, [rowsPerPage]);

  if (loading) {
    return <CircularProgress />;
  }

  if (error || !data) {
    return <p>An error happened while loading topics</p>;
  }

  const onDuplicateTopic = (topicId: string) => {
    setTopicId(topicId);
    openDuplicateTopic();
  };

  const onDeleteTopics = async () => {
    try {
      await adminDeleteTopicsMutation({
        variables: {
          input: {
            topicIds: selected,
          },
        },
      });
      enqueueSnackbar("Topics successfully deleted", { variant: "success" });
      closeDeleteTopicModal();
      refetch();
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const uiSortDirection =
    sort === SortDirection.Asc ? TableSortDirection.ASC : TableSortDirection.DESC;

  return (
    <>
      <Box mb={Spacing.m}>
        <Typography variant="h3" color="textPrimary">
          All Topics
        </Typography>
      </Box>
      <Box mb={Spacing.l} display="flex" alignItems="center" justifyContent="space-between">
        <TopicsActions
          searchQuery={query}
          onSearch={handleChange}
          onClearSearch={clearSearch}
          onCreateTopic={onOpen}
          onDeleteTopic={openDeleteTopicModal}
          deleteEnabled={isNotEmpty(selected) && !deletingTopics}
          filters={filters}
          updateFilters={handleUpdateFilters}
        />
      </Box>
      <Card>
        {loading ? (
          <Box display="flex" justifyContent="center" p={Spacing.xxl}>
            <CircularProgress />
          </Box>
        ) : (
          <Table>
            <TopicsPageTableHead
              sort={uiSortDirection}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />

            <TableBody>
              {items.map((topic: TopicModel) => (
                <TopicsPageTopicDetails
                  key={topic.id}
                  topic={topic}
                  onDuplicateTopic={onDuplicateTopic}
                  selected={selected}
                  onSelectOne={onSelectOne}
                />
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  page={page}
                  count={data?.adminListPaginatedTopics?.total}
                  onPageChange={onChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={onChangeRowsPerPage}
                  rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                />
              </TableRow>
            </TableFooter>
          </Table>
        )}
      </Card>

      <TopicsPageCreateTopicCont open={open} onClose={onClose} limit={limit} offset={0} />

      <DuplicateTopic
        topicId={topicId}
        isOpen={isOpen}
        close={closeDuplicateTopic}
        refetch={refetch}
      />

      <ConfirmationModal
        open={isDeleteTopicModalOpen}
        onClose={closeDeleteTopicModal}
        onSubmit={onDeleteTopics}
      />
    </>
  );
};

export default TopicsPage;
