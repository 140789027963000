import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, Grid, IconButton, type Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import React, { Fragment, memo, useContext } from "react";
import { useDrag, useDrop } from "react-dnd";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { inc } from "ramda";
import LessonContext from "../context/LessonContext";
import { ELEMENT_TEMPLATES, ELEMENT_TEMPLATES_TYPE } from "../helpers/const";
import { Sizing, Spacing } from "../types/enum";
import { type Element, ElementKind } from "../types/graphql";
import LessonEditPageSectionAttachment from "./LessonEditPageSectionAttachment";
import LessonEditPageSectionBanner from "./LessonEditPageSectionBanner";
import LessonEditPageSectionButton from "./LessonEditPageSectionButton";
import LessonEditPageSectionChooseLayout from "./LessonEditPageSectionChooseLayout";
import LessonEditPageSectionDivider from "./LessonEditPageSectionDivider";
import LessonEditPageSectionEmbedded from "./LessonEditPageSectionEmbedded";
import LessonEditPageSectionIdeas from "./LessonEditPageSectionIdeas";
import LessonEditPageSectionImage from "./LessonEditPageSectionImage";
import LessonEditPageSectionImageAndButton from "./LessonEditPageSectionImageAndButton";
import LessonEditPageSectionLinks from "./LessonEditPageSectionLinks";
import LessonEditPageSectionParagraph from "./LessonEditPageSectionParagraph";
import LessonEditPageSectionQuestions from "./LessonEditPageSectionQuestions";
import LessonEditPageSectionSpacing from "./LessonEditPageSectionSpacing";
import LessonEditPageSectionTitle from "./LessonEditPageSectionTitle";
import LessonEditPageSectionVideo from "./LessonEditPageSectionVideo";

/**
 * Types
 */
export interface LessonEditPageSectionElementProps {
  sectionId?: string;
  index: number;
  element: Element;
  onDeleteElement: () => void;
  onEditElement: (value: string) => void;
  setElementMeta?: (key: string, value: string) => void;
  showGridDescription?: boolean;
  showAddRowButton?: boolean;
  total: number;
  canMove?: boolean;
  topicId: string;
  lessonPath: string;
  step?: number | null;
}

/**
 * Constants
 */
const ELEMENTS = new Map<ElementKind, any>([
  [ElementKind.Title, LessonEditPageSectionTitle],
  [ElementKind.Paragraph, LessonEditPageSectionParagraph],
  [ElementKind.Video, LessonEditPageSectionVideo],
  [ElementKind.Questions, LessonEditPageSectionQuestions],
  [ElementKind.Image, LessonEditPageSectionImage],
  [ElementKind.Spacing, LessonEditPageSectionSpacing],
  [ElementKind.Divider, LessonEditPageSectionDivider],
  [ElementKind.Links, LessonEditPageSectionLinks],
  [ElementKind.Ideas, LessonEditPageSectionIdeas],
  [ElementKind.Attachment, LessonEditPageSectionAttachment],
  [ElementKind.Grid, LessonEditPageSectionChooseLayout],
  [ElementKind.Button, LessonEditPageSectionButton],
  [ElementKind.Banner, LessonEditPageSectionBanner],
  [ElementKind.ImageAndButton, LessonEditPageSectionImageAndButton],
  [ElementKind.Embedded, LessonEditPageSectionEmbedded],
]);

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) => ({
  drag: {
    cursor: "-webkit-grab",
    "&:active": {
      cursor: "-webkit-grabbing",
    },
  },
  card: {
    width: "100%",
    backgroundColor: theme.palette.background.default,
    paddingTop: theme.spacing(Spacing.sm),
    paddingLeft: theme.spacing(Spacing.sm),
    paddingBottom: theme.spacing(Spacing.sm),
  },
  dragging: {
    opacity: 0,
  },
  dropArea: {
    paddingBottom: theme.spacing(Spacing.ml),
    backgroundImage: "inherit",
  },
  dragActive: {
    backgroundColor: theme.palette.success.main,
    opacity: 0.2,
  },
}));

const LessonEditPageSectionElement: React.FC<LessonEditPageSectionElementProps> = ({
  sectionId,
  element,
  onDeleteElement,
  onEditElement,
  setElementMeta,
  index,
  showGridDescription,
  showAddRowButton,
  total,
  canMove,
  topicId,
  lessonPath,
  step,
}: LessonEditPageSectionElementProps) => {
  const classes = useStyles();
  const { onInsertElement, onMoveElement } = useContext(LessonContext);

  // useDrag - the list item is draggable
  const [{ isDragging }] = useDrag({
    type: "ElementKind",
    item: index,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ isOverBefore }, dropBeforeRef] = useDrop({
    accept: ELEMENT_TEMPLATES_TYPE,
    drop: (item, monitor) => {
      if (monitor.didDrop()) {
        return null;
      }
      if (sectionId) {
        onInsertElement(sectionId, index, item);
      }
    },
    collect: (monitor) => ({
      isOverBefore: monitor.isOver(),
    }),
  });

  const [{ isOverAfter }, dropAfterRef] = useDrop({
    accept: ELEMENT_TEMPLATES_TYPE,
    drop: (item, monitor) => {
      const nextElementIndex = index + 1;
      if (monitor.didDrop()) {
        return null;
      }
      if (sectionId) {
        onInsertElement(sectionId, nextElementIndex, item);
      }
    },
    collect: (monitor) => ({
      isOverAfter: monitor.isOver(),
    }),
  });

  const onMoveUp = () => {
    onMoveElement(index, index - 1, sectionId);
  };

  const onMoveDown = () => {
    onMoveElement(index, index + 1, sectionId);
  };

  return (
    <Grid item xs={Sizing.Full}>
      {/* area used to drop before current element */}
      {index === 0 ? (
        <div
          ref={dropBeforeRef}
          className={classNames(classes.dropArea, {
            [classes.dragActive]: isDragging ? null : !!isOverBefore,
          })}
        />
      ) : null}
      <Box className={classNames(classes.card, { [classes.dragging]: isDragging })}>
        <Box display="flex" alignItems="center">
          {React.createElement(ELEMENTS.get(element?.kind) ?? <Fragment />, {
            value: element?.value,
            setValue: (value: string) => {
              onEditElement(value);
            },
            id: element?.meta?.createdAt,
            setElementMeta,
            parent: element,
            index,
            sectionId,
            showGridDescription,
            topicId,
            lessonPath,
            step,
          })}

          <Box display="flex" flexDirection="column" ml={Spacing.s}>
            <IconButton onClick={onDeleteElement} size="large">
              <DeleteIcon />
            </IconButton>
            {index && canMove ? (
              <IconButton size="small" onClick={onMoveUp}>
                <ArrowUpwardIcon fontSize="small" />
              </IconButton>
            ) : null}
            {inc(index) < total && canMove ? (
              <IconButton size="small" onClick={onMoveDown}>
                <ArrowDownwardIcon fontSize="small" />
              </IconButton>
            ) : null}
          </Box>
        </Box>
      </Box>
      {showAddRowButton ? (
        <Grid item xs={Sizing.Full}>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={() => {
              const newRow = ELEMENT_TEMPLATES.get(ElementKind.Grid);
              const newElementIndex = index + 1;
              onInsertElement(sectionId as string, newElementIndex, {
                ...newRow,
                meta: { ...newRow.meta, columnsLayout: element.meta.columnsLayout },
              });
            }}
          >
            Add Row
          </Button>
        </Grid>
      ) : null}
      {/* area used to drop after current element */}
      {!element.meta?.columnsLayout || (element.kind === ElementKind.Grid && showAddRowButton) ? (
        <div
          ref={dropAfterRef}
          className={classNames(classes.dropArea, {
            [classes.dragActive]: isOverAfter || isDragging,
          })}
        />
      ) : null}
    </Grid>
  );
};

export default memo(LessonEditPageSectionElement);
