import { useState } from "react";
import { useOnOffSwitch } from "src/helpers/hooks";

export const useTableSelect = () => {
  const [selected, setSelected] = useState<string[]>([]);
  const [allSelected, setAllSelectedOn, setAllSelectedOff] = useOnOffSwitch();

  const onSelectOne = (id: string) => {
    const isSelected = selected.includes(id);

    if (isSelected) {
      setSelected((prevSelected) => prevSelected.filter((item) => item !== id));
    } else {
      setSelected((prevSelected) => [...prevSelected, id]);
    }
  };

  const onSelectedAll = () => {
    setAllSelectedOn();
  };

  const clearSelection = () => {
    setAllSelectedOff();
    setSelected([]);
  };

  return {
    selected,
    setSelected,
    allSelected,
    setAllSelectedOn,
    setAllSelectedOff,
    onSelectOne,
    onSelectedAll,
    clearSelection,
  };
};
