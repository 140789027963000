import { Box, Typography } from "@mui/material";
import React from "react";

import { Spacing } from "../types/enum";

import Label from "./Label";

/**
 * Types
 */
interface Props {
  slideNumber: number;
  title?: string | undefined | null;
}

/**
 * Constants
 */
enum VariantType {
  Success = "success",
  Warning = "warning",
  Info = "info",
}

const SliderPageSlideTag: React.FC<Props> = ({ slideNumber, title }: Props) => {
  const renderers = {
    tag: (variant: VariantType) => {
      return (
        <Label variant={variant}>
          <Box p={Spacing.s}>
            <Typography> {title ?? `SLIDE #${slideNumber}`} </Typography>
          </Box>
        </Label>
      );
    },
  };

  const variant = () => {
    if (slideNumber % 3 === 0) {
      return VariantType.Info;
    }
    if (slideNumber % 3 === 1) {
      return VariantType.Warning;
    }
    return VariantType.Success;
  };

  return renderers.tag(variant());
};

export default SliderPageSlideTag;
