import { gql } from "@apollo/client";

export const DELETE_ZINE_COVER = gql`
  mutation adminDeleteZineCover($id: String!) {
    adminDeleteZineCover(id: $id) {
      status
    }
  }
`;

export const CREATE_ZINE_COVER = gql`
  mutation adminCreateZineCover($input: CreateZineInput!) {
    adminCreateZineCover(input: $input) {
      id
      coverUrl
      isCurrent
    }
  }
`;

export const EDIT_ZINE_COVER = gql`
  mutation adminEditZineCover($id: String!, $input: EditZineInput!) {
    adminEditZineCover(id: $id, input: $input) {
      id
      coverUrl
      isCurrent
    }
  }
`;

export const UPLOAD_ZINE_COVER = gql`
  mutation uploadZineCover($input: UploadCoverZineInput!) {
    uploadZineCover(input: $input) {
      id
      coverUrl
    }
  }
`;
