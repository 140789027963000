import React from "react";
import { Outlet } from "react-router-dom";

import { LessonProvider } from "../context/LessonContext";

const LessonLayout: React.FC = () => {
  return (
    <LessonProvider>
      <Outlet />
    </LessonProvider>
  );
};

export default LessonLayout;
