import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import React from "react";
import { type UserModel } from "src/types/graphql";

/**
 * Types
 */
interface Props {
  user: UserModel;
}

const UserPageDetailsMetricsCard: React.FC<Props> = ({ user }: Props) => {
  const duration = user.engagementMetrics?.attendanceMetrics?.totalParticipationDuration ?? 0;
  const minutes = duration ? Math.floor(duration / 60) : 0;

  return (
    <Card>
      <CardHeader title="User Engagement" />

      <Divider />

      <CardContent>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell variant="head">Scheduled events RSVPs</TableCell>
              <TableCell>{user.engagementMetrics?.totalRsvps}</TableCell>
              <TableCell variant="head">Events hosted</TableCell>
              <TableCell>{user.engagementMetrics?.attendanceMetrics?.totalHostedEvents}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Scheduled events attended</TableCell>
              <TableCell>
                {user.engagementMetrics?.attendanceMetrics?.totalParticipatedEvents}
              </TableCell>
              <TableCell variant="head">Participation minutes</TableCell>
              <TableCell>{minutes}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Attendance ratio</TableCell>
              <TableCell>
                {Math.round((user.engagementMetrics?.attendanceRatio ?? 0) * 100)}%
              </TableCell>
              <TableCell variant="head">Articles published</TableCell>
              <TableCell>{user.engagementMetrics?.publishedArticles}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Non-scheduled conversations</TableCell>
              <TableCell>{user.engagementMetrics?.attendanceMetrics?.totalVideoCalls}</TableCell>
              <TableCell variant="head">Hosted participants</TableCell>
              <TableCell>{user.engagementMetrics?.totalHostedParticipants}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default UserPageDetailsMetricsCard;
