import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  type Theme,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useSnackbar } from "notistack";
import React from "react";
import {
  Controller,
  type ControllerRenderProps,
  type SubmitHandler,
  useForm,
} from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useMutation } from "@apollo/client";

import { EDIT_LESSON } from "../gql/mutations/lesson";

import { Spacing } from "../types/enum";
import { DeepDiveKind, type LessonModel } from "../types/graphql";

interface Props {
  lesson: LessonModel;
}

/**
 * Schema
 */
const schema = yup.object().shape({
  deepDiveKind: yup.string().required(),
});
type FormValues = yup.InferType<typeof schema>;

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    overflow: "initial",
  },
  item: {
    marginBottom: theme.spacing(Spacing.sm),
    "&:last-of-type": {
      marginBottom: "unset",
    },
  },
}));

const LessonEditPageDeepDiveKind: React.FC<Props> = ({ lesson }: Props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [editLesson] = useMutation(EDIT_LESSON);

  const defaultValues = {
    deepDiveKind: DeepDiveKind.RolePlay,
  };
  const { control, handleSubmit } = useForm<FormValues>({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues,
  });

  const renderers = {
    radio: ({
      field: { value, onChange },
    }: { field: ControllerRenderProps<FormValues, "deepDiveKind"> }) => {
      return (
        <RadioGroup value={value} onChange={onChange}>
          <Box mb={Spacing.s}>
            <FormControlLabel control={<Radio />} label="Role Play" value={DeepDiveKind.RolePlay} />
          </Box>
          <Box mb={Spacing.s}>
            <FormControlLabel
              control={<Radio />}
              label="Share & Compare"
              value={DeepDiveKind.ShareAndCompare}
            />
          </Box>
          <Box mb={Spacing.s}>
            <FormControlLabel
              control={<Radio />}
              label="Solve a Problem"
              value={DeepDiveKind.SolveAProblem}
            />
          </Box>
          <Box mb={Spacing.s}>
            <FormControlLabel
              control={<Radio />}
              label="Roundtable"
              value={DeepDiveKind.Roundtable}
            />
          </Box>
        </RadioGroup>
      );
    },
  };

  const onSubmit: SubmitHandler<FormValues> = async (input): Promise<void> => {
    const variables = {
      id: lesson.id,
      input: {
        deepDiveKind: input.deepDiveKind,
      },
    };

    try {
      await editLesson({ variables });
      enqueueSnackbar("Deep dive kind successfully saved", { variant: "success" });
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return (
    <Card className={classes.root}>
      <CardHeader
        title={
          <Box mb={Spacing.xs}>
            <Typography variant="body1">What type of Deep Dive is this?</Typography>
          </Box>
        }
      />

      <Divider />

      <CardContent>
        <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
          <Box>
            <Controller name="deepDiveKind" control={control} render={renderers.radio} />
          </Box>

          <Box display="flex" alignItems="center">
            <Button fullWidth type="submit" color="primary" variant="contained" size="small">
              Save
            </Button>
          </Box>
        </form>
      </CardContent>
    </Card>
  );
};

export default LessonEditPageDeepDiveKind;
