import { Box, FormControl, FormLabel, TextField } from "@mui/material";
import debounce from "lodash.debounce";
import React from "react";
import CopyLinkToElement from "src/components/Topics/CopyLinkToElement.tsx";
import { DEBOUNCE_TIME } from "../helpers/const";
import { Spacing } from "../types/enum";

/**
 * Types
 */
interface Props {
  value?: string;
  setValue: (text: string) => void;
  id: string;
  topicId: string;
  lessonPath: string;
  step?: number | null;
}

const LessonEditPageSectionTitle: React.FC<Props> = ({
  value,
  setValue,
  id,
  topicId,
  lessonPath,
  step,
}: Props) => {
  const onChange = debounce(setValue, DEBOUNCE_TIME);

  return (
    <FormControl fullWidth>
      <Box mb={Spacing.s} display="flex" alignItems="center" justifyContent="space-between">
        <FormLabel>Title</FormLabel>
        <CopyLinkToElement id={id} topicId={topicId} lessonPath={lessonPath} step={step} />
      </Box>
      <TextField
        fullWidth
        defaultValue={value}
        name="title"
        placeholder="Title"
        variant="outlined"
        onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => onChange(target.value)}
      />
    </FormControl>
  );
};

export default LessonEditPageSectionTitle;
