import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import get from "get-value";
import { useSnackbar } from "notistack";
import { isNil, not } from "ramda";
import React, { useEffect } from "react";
import {
  Controller,
  type ControllerRenderProps,
  type UseFormStateReturn,
  useForm,
} from "react-hook-form";
import * as yup from "yup";

import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormLabel,
  Grid,
  LinearProgress,
  TextField,
} from "@mui/material";

import { EDIT_COVER } from "../gql/mutations/cover";

import { Sizing, Spacing } from "../types/enum";
import { type AttachmentModel } from "../types/generic";
import { type CoverModel, type EditCoverInput } from "../types/graphql";

import FileDropzone from "../components/FileDropzone";

/**
 * Types
 */
interface Props {
  cover: CoverModel;
}

/**
 * Schema
 */
const schema = yup.object().shape({
  title: yup.string().required(),
  primary: yup
    .object({
      mobile: yup.string().required(),
      desktop: yup.string().required(),
    })
    .required(),
  secondary: yup
    .object({
      mobile: yup.string().required(),
      desktop: yup.string().required(),
    })
    .required(),
  tertiary: yup
    .object({
      mobile: yup.string().required(),
      desktop: yup.string().required(),
    })
    .required(),
});
type FormValues = yup.InferType<typeof schema>;

const CoverEditFormCont: React.FC<Props> = ({ cover }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [editCover, { loading }] = useMutation(EDIT_COVER);

  const defaultValues = {
    title: cover.title,
    primary: cover.primary,
    secondary: cover.secondary,
    tertiary: cover.tertiary,
  };
  const { register, control, formState, handleSubmit, trigger } = useForm<FormValues>({
    mode: "all",
    resolver: yupResolver(schema),
    // @ts-expect-error graphql types have to be required
    defaultValues,
  });

  useEffect(() => {
    void trigger();
  }, [trigger]);

  const renderers = {
    file: <
      TName extends
        | "primary.mobile"
        | "primary.desktop"
        | "secondary.mobile"
        | "secondary.desktop"
        | "tertiary.mobile"
        | "tertiary.desktop",
    >({
      field: { value, onChange, name },
    }: {
      field: ControllerRenderProps<FormValues, TName>;
      formState: UseFormStateReturn<FormValues>;
    }) => {
      const isMissing = not(isNil(get(formState?.errors, name)));

      return (
        <FileDropzone
          preview
          fileName={value ?? undefined}
          error={isMissing ? "This field is required" : undefined}
          onDropFile={(attachment: AttachmentModel) => {
            onChange(attachment?.url);
          }}
        />
      );
    },
  };

  const onSubmit = async (form: EditCoverInput): Promise<void> => {
    const variables = {
      id: cover?.id,
      input: {
        title: form?.title,
        primary: form?.primary,
        secondary: form?.secondary,
        tertiary: form?.tertiary,
      },
    };

    try {
      await editCover({ variables });
      enqueueSnackbar("Cover successfully updated", { variant: "success" });
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return (
    <Card>
      {loading ? <LinearProgress /> : null}

      <CardContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid spacing={Spacing.m} container>
            <Grid item xs={Sizing.Full}>
              <FormControl fullWidth>
                <TextField
                  label="Title"
                  variant="outlined"
                  {...register("title")}
                  error={not(isNil(formState?.errors?.title))}
                  helperText={
                    not(isNil(formState?.errors?.title)) ? "This field is required" : undefined
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={Sizing.Full}>
              <FormControl fullWidth>
                <Box mb={Spacing.s}>
                  <FormLabel>Primary / Desktop - Mobile</FormLabel>
                </Box>

                <Grid spacing={Spacing.m} container>
                  <Grid item xs={Sizing.Half}>
                    <Controller name="primary.desktop" control={control} render={renderers.file} />
                  </Grid>
                  <Grid item xs={Sizing.Half}>
                    <Controller name="primary.mobile" control={control} render={renderers.file} />
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>

            <Grid item xs={Sizing.Full}>
              <FormControl fullWidth>
                <Box mb={Spacing.s}>
                  <FormLabel>Secondary / Desktop - Mobile</FormLabel>
                </Box>

                <Grid spacing={Spacing.m} container>
                  <Grid item xs={Sizing.Half}>
                    <Controller
                      name="secondary.desktop"
                      control={control}
                      render={renderers.file}
                    />
                  </Grid>
                  <Grid item xs={Sizing.Half}>
                    <Controller name="secondary.mobile" control={control} render={renderers.file} />
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>

            <Grid item xs={Sizing.Full}>
              <FormControl fullWidth>
                <Box mb={Spacing.s}>
                  <FormLabel>Tertiary / Desktop - Mobile</FormLabel>
                </Box>

                <Grid spacing={Spacing.m} container>
                  <Grid item xs={Sizing.Half}>
                    <Controller name="tertiary.desktop" control={control} render={renderers.file} />
                  </Grid>
                  <Grid item xs={Sizing.Half}>
                    <Controller name="tertiary.mobile" control={control} render={renderers.file} />
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>

            <Grid item xs={Sizing.Full}>
              <Button
                fullWidth
                type="submit"
                color="secondary"
                variant="contained"
                disabled={loading || not(formState.isDirty) || not(formState.isValid)}
              >
                Update cover
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export default CoverEditFormCont;
