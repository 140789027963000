import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import React from "react";

import { Spacing } from "../types/enum";

/**
 * Types
 */
interface Props {
  open: boolean;
  title?: string;
  description?: string;
  label?: string;
  onClose?: () => void;
  onSubmit?: () => void;
}

const ConfirmationModal: React.FC<Props> = ({
  open,
  title = "Are you sure?",
  description = "This action is irreversible and all data will be lost!",
  label = "Delete",
  onClose,
  onSubmit,
}: Props) => {
  return (
    <Dialog maxWidth="lg" open={open} onClose={onClose}>
      <DialogContent>
        <Box mb={Spacing.sm} display="flex" flexDirection="column" alignItems="center">
          <Typography variant="body1" color="textPrimary">
            {title}
          </Typography>
          <Box mt={Spacing.sm} />
          <Typography variant="body2" color="textSecondary">
            {description}
          </Typography>
        </Box>
      </DialogContent>

      <DialogActions>
        <Box width="100%" display="flex" justifyContent="space-around" alignItems="center">
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={onSubmit}>{label}</Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
