import { not } from "ramda";
import React, { useCallback, useState } from "react";

import { useMutation } from "@apollo/client";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import classNames from "classnames";
import { useSnackbar } from "notistack";
import { useDropzone } from "react-dropzone";

import {
  Box,
  CircularProgress,
  FormHelperText,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIcon,
  type Theme,
  Typography,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { type AttachmentModel } from "../types/generic";

import { GENERATE_ATTACHMENT_UPLOAD_URL } from "../gql/mutations/attachment";

import { Trash2 as DeleteIcon } from "react-feather";
import { UPLOAD_ZINE_COVER } from "../gql/mutations/zineCover";
import { QUERY_ZINE_COVER, QUERY_ZINE_COVERS } from "../gql/queries/zineCover";
import { DEFAULT_OFFSET, ROWS_PER_PAGE_DEFAULT } from "../helpers/const";
import { useOnOffSwitch } from "../helpers/hooks";
import { staticFileUrl, truncateWithEllipses, upload } from "../helpers/utils";
import { Spacing } from "../types/enum";

/**
 * Types
 */

interface Props {
  preview?: boolean;
  showFileContained?: boolean;
  fileName?: string;
  loading?: boolean;
  onDropFile: (attachment: AttachmentModel) => void;
  error?: string;
  onDropZine?: boolean;
  zineId?: string;
  placeholder?: JSX.Element;
  height?: number;
}

/**
 * Styles
 */
const useStyles = makeStyles<Theme, Partial<Props>>((theme: Theme) => ({
  root: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    height: ({ height }) => (height ? `${height}px` : "96px"),
    minWidth: "128px",
    flexDirection: "column",
    justifyContent: "center",
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: theme.shape.borderRadius,
    borderColor: theme.palette.action.disabled,
    "&:hover": {
      borderWidth: "2px",
      borderColor: theme.palette.action.active,
    },
  },
  fileName: {
    wordBreak: "break-word",
  },
  disabled: {
    pointerEvents: "none",
  },
  image: (showFileContained) => ({
    height: "auto",
    margin: "0 auto",
    maxWidth: showFileContained ? "calc(100% - 20px)" : "250px",
    borderRadius: theme.shape.borderRadius,
    maxHeight: showFileContained ? "86px" : "unset",
  }),
  wide: {
    width: "100%",
  },
}));

const FileDropzone: React.FC<Props> = ({
  preview,
  fileName,
  onDropFile,
  error,
  onDropZine,
  zineId,
  placeholder,
  showFileContained,
  height,
}: Props) => {
  const classes = useStyles({ showFileContained, height });

  const { enqueueSnackbar } = useSnackbar();
  const [loading, onStart, onEnd] = useOnOffSwitch();
  const [generateAttachmentUploadUrl] = useMutation(GENERATE_ATTACHMENT_UPLOAD_URL);
  const [file, setFile] = useState<string | undefined>(fileName);

  const [uploadZineCover] = useMutation(UPLOAD_ZINE_COVER, {
    refetchQueries: [
      { query: QUERY_ZINE_COVER, variables: { id: zineId } },
      {
        query: QUERY_ZINE_COVERS,
        variables: {
          filters: {
            limit: ROWS_PER_PAGE_DEFAULT,
            offset: DEFAULT_OFFSET,
          },
        },
      },
    ],
  });

  const onDrop = useCallback(
    async ([file]: File[]) => {
      try {
        if (not(onDropZine)) {
          const variables = {
            filename: file.name,
          };
          const meta = {
            name: file.name,
            size: file.size,
            extension: file.type,
          };
          onStart();
          const { data } = await generateAttachmentUploadUrl({ variables });
          await upload(data?.adminGenerateAttachmentUploadUrl?.uploadURL, file);
          onDropFile({
            ...meta,
            url: staticFileUrl(data?.adminGenerateAttachmentUploadUrl?.contentURL),
          });
          setFile(data?.adminGenerateAttachmentUploadUrl?.contentURL);
        } else {
          const variables = {
            input: {
              file,
              zineId,
            },
          };
          onStart();
          const result = await uploadZineCover({ variables });
          setFile(result.data.uploadZineCover.coverUrl);
          onEnd();
        }
      } catch (error: any) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        onEnd();
      }
    },
    [
      onDropZine,
      onStart,
      generateAttachmentUploadUrl,
      onDropFile,
      zineId,
      uploadZineCover,
      onEnd,
      enqueueSnackbar,
    ],
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const renderers = {
    placeholder: () => {
      if (placeholder) {
        return placeholder;
      }
      return (
        <>
          <Typography variant="h5" color="textPrimary">
            Select files
          </Typography>
          <Typography variant="body2" color="textSecondary">
            "Drop files browse thorough your machine"
          </Typography>
        </>
      );
    },
    preview: () => {
      if (preview) {
        return <img src={staticFileUrl(file)} alt={file} className={classes.image} />;
      }
      return (
        <List>
          <ListItem>
            <ListItemIcon>
              <FileCopyIcon />
            </ListItemIcon>
            <ListItemText
              className={classes.fileName}
              primary={truncateWithEllipses(fileName ?? "")}
            />
          </ListItem>
        </List>
      );
    },
    fileContained: () => {
      if (file && showFileContained) {
        return (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            className={classes.wide}
            px={Spacing.m}
          >
            {renderers.preview()}
            <Box>
              <IconButton
                onClick={() => {
                  setFile(undefined);
                }}
                size="large"
              >
                <SvgIcon fontSize="small">
                  <DeleteIcon />
                </SvgIcon>
              </IconButton>
            </Box>
          </Box>
        );
      }
      return (
        <Box display="flex" alignItems="center" flexDirection="column">
          {renderers.placeholder()}
          {error && <FormHelperText error>{error}</FormHelperText>}
        </Box>
      );
    },
    fileOutside: () => {
      if (file && not(showFileContained)) {
        return renderers.preview();
      }
      return null;
    },
  };

  if (loading) {
    return (
      <Box className={classNames(classes.root, { [classes.disabled]: loading })}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Box className={classNames(classes.root)} {...getRootProps()}>
        <input {...getInputProps()} />

        {renderers.fileContained()}
      </Box>
      {renderers.fileOutside()}
    </Box>
  );
};

export default FileDropzone;
