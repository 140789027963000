import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
  Observable,
  fromPromise,
} from "@apollo/client";
import { type ErrorResponse, onError } from "@apollo/link-error";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import React from "react";

/**
 * Types
 */
interface Props {
  children: JSX.Element[] | JSX.Element;
}

/**
 * Helpers
 */
const logout = (): void => {
  if (window.location.pathname !== "/login") {
    window.location.pathname = "/login";
  }
};

/**
 * Links
 */
const http = createUploadLink({
  credentials: "include",
  uri: import.meta.env.VITE_API_URL,
  headers: { "Apollo-Require-Preflight": "true" },
});

let isRefreshing = false;
let pendingRequests: Array<() => void> = [];

const resolvePendingRequests = () => {
  pendingRequests.forEach((callback) => {
    callback();
  });
  pendingRequests = [];
};

function getNewToken() {
  return fetch(`${import.meta.env.VITE_AUTH_URL}/refresh`, {
    method: "POST",
    credentials: "include",
  });
}

/**
 * Error handling
 *
 * Try to use refresh token
 * in case of error when users access token is expired
 * @link https://able.bio/AnasT/apollo-graphql-async-access-token-refresh--470t1c8
 */
const error = onError(({ graphQLErrors, networkError, operation, forward }: ErrorResponse) => {
  if (graphQLErrors) {
    for (const error of graphQLErrors) {
      if (error?.message === "Unauthorized") {
        // Try refreshing access
        // biome-ignore lint/suspicious/noConfusingVoidType: <explanation>
        let forward$: Observable<boolean | void>;

        if (!isRefreshing) {
          isRefreshing = true;
          forward$ = fromPromise(
            getNewToken()
              .then((response) => {
                if (response.ok) {
                  resolvePendingRequests();
                  return true;
                }

                pendingRequests = [];
                // Handle token refresh errors e.g clear stored tokens, redirect to login, ...
                logout();
                return false;
              })
              .catch((error) => {
                console.error(error);
                pendingRequests = [];
                // Handle token refresh errors e.g clear stored tokens, redirect to login, ...
                logout();
              })
              .finally(() => {
                isRefreshing = false;
              }),
          ).filter((value) => Boolean(value));
        } else {
          // Will only emit once the Promise is resolved
          forward$ = fromPromise(
            new Promise<void>((resolve) => {
              pendingRequests.push(() => {
                resolve();
              });
            }),
          );
        }

        return forward$.flatMap(() => forward(operation));
      }
      console.warn(error);
    }
  }
  if (networkError) {
    console.error(networkError);
    // window.location.replace("/heavy-load");
  }
});

/**
 * Client
 */
const client = new ApolloClient({
  cache: new InMemoryCache({ addTypename: false }),
  connectToDevTools: import.meta.env.DEV,
  link: ApolloLink.from([error, http as any]),
});

const Apollo: React.FC<Props> = ({ children }: Props) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default Apollo;
