import React from "react";
import { useParams } from "react-router";
import { type VideoSetModel, useAdminGetVideoSetQuery } from "src/types/graphql";

import VideosPageEditVideoSetCont from "../containers/VideosPageEditVideoSetCont";

const VideoSetPage: React.FC = () => {
  const params = useParams<Record<string, string>>();

  const { data, loading } = useAdminGetVideoSetQuery({
    fetchPolicy: "network-only",
    variables: {
      id: params?.videoSetId ?? "",
    },
  });

  if (data?.adminGetVideoSet) {
    return (
      <VideosPageEditVideoSetCont
        videoSet={data.adminGetVideoSet as VideoSetModel}
        loading={loading}
      />
    );
  }

  return <></>;
};

export default VideoSetPage;
