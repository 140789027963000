import React, { useEffect } from "react";
import {
  Controller,
  type ControllerRenderProps,
  type UseFormStateReturn,
  useForm,
} from "react-hook-form";

import {
  Button,
  CardContent,
  FormControl,
  Grid,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { format } from "date-fns";
import { fromZonedTime } from "date-fns-tz";
import { useSnackbar } from "notistack";
import { isEmpty, isNil, map, not, prop } from "ramda";
import * as yup from "yup";

import { Sizing, Spacing } from "../types/enum";
import {
  EventCategory,
  EventEnglishLevel,
  EventKind,
  type EventModel,
  EventPermissions,
  type TopicModel,
} from "../types/graphql";

import {
  EVENT_CATEGORY_HLT_EVENTS,
  EVENT_CATEGORY_MEMBER_EVENTS,
  EVENT_ENGLISH_LEVEL_NAMES,
  USER_PERMISSIONS,
} from "src/helpers/const";
import { type AutocompleteOptionModel } from "src/types/generic";
import Autocomplete from "../components/Autocomplete";
import { EDIT_EVENT } from "../gql/mutations/event";

/**
 * Types
 */
interface Props {
  event: EventModel;
  topics: TopicModel[];
}

/**
 * Schema
 */
const schema = yup.object().shape({
  title: yup.string().required(),
  description: yup.string().required(),
  location: yup.string().nullable(),
  meetingID: yup.string().nullable(),
  joinURL: yup.string().required(),
  publicURL: yup.string().nullable(),
  startURL: yup.string().nullable(),
  startDate: yup.date().required(),
  endDate: yup.date().nullable(),
  topicId: yup.string().nullable(),
  isOnline: yup.bool().required(),
  isPublic: yup.bool().required(),
  isDeleted: yup.bool().required(),
  permissions: yup.array().required(),
  isForUnverified: yup.bool().required(),
  englishLevel: yup.mixed<EventEnglishLevel>().oneOf(Object.values(EventEnglishLevel)).required(),
  isDraft: yup.bool().required(),
  eventCategory: yup.mixed<EventCategory>().oneOf(Object.values(EventCategory)).required(),
});
type FormValues = yup.InferType<typeof schema>;

/**
 * Helpers
 */
const humanize = (permissions: EventPermissions) => USER_PERMISSIONS.get(permissions) as string;
const optionize = (permission: EventPermissions) => ({
  id: permission,
  title: humanize(permission),
});

/**
 * Constants
 */
const possiblePermissions = [
  EventPermissions.ZeroToOne,
  EventPermissions.OneToThree,
  EventPermissions.ThreeToTwelve,
  EventPermissions.MoreThanTwelveMonths,
];
const OPTIONS = Object.values(possiblePermissions).map(optionize);

const EventEditPageFormCont: React.FC<Props> = ({ event, topics }: Props) => {
  const [editEvent, { loading }] = useMutation(EDIT_EVENT);

  const isOneToOne = event.kind === EventKind.OneToOne;

  const HLTEvent =
    event.host?.kind === EventPermissions.HeyLadyTeam ||
    event.host?.kind === EventPermissions.HeyLadyAdmin;

  const defaultValues = {
    title: event.title,
    topic: event.topic && { id: event.topic?.id, title: event.topic?.title },
    location: event.location,
    isOnline: event.isOnline,
    isPublic: event.isPublic,
    isDeleted: event.isDeleted,
    isDraft: event.isDraft,
    permissions: map(optionize, event.permissions ?? []),
    isForUnverified: event.isForUnverified,
    englishLevel: event.englishLevel,
    eventCategory: event.eventCategory,
    description: event.description,
    meetingID: event.meta?.meetingID,
    joinURL: event.meta?.joinURL,
    publicURL: event.meta?.publicURL,
    startURL: event.meta?.startURL,
    startDate: format(event.startDate ?? new Date(), "yyyy-MM-dd'T'HH:mm"),
    endDate: format(event.endDate ?? new Date(), "yyyy-MM-dd'T'HH:mm"),
  };

  const { register, control, handleSubmit, trigger, formState } = useForm<FormValues>({
    mode: "all",
    resolver: yupResolver(schema),
    // @ts-expect-error too many types to fix
    defaultValues,
  });

  useEffect(() => {
    void trigger();
  }, [trigger]);

  const { enqueueSnackbar } = useSnackbar();

  const eventCategories = Object.values(EventCategory).filter(
    (category) => ![EventCategory.Conversation, EventCategory.StudyAndLearn].includes(category),
  );

  const renderers = {
    topic: ({
      field: { value, onChange },
    }: {
      field: ControllerRenderProps<FormValues, "topicId">;
      formState: UseFormStateReturn<FormValues>;
    }) => {
      const error = not(isNil(formState?.errors?.topicId));
      const extendedTopics = [
        {
          id: "",
          title: "-----",
          createdAt: new Date(),
          updatedAt: new Date(),
          ideasToDiscuss: [],
        },
        ...topics,
      ];

      return (
        <Autocomplete
          label="Topic"
          options={extendedTopics}
          value={extendedTopics.find((option) => option.id === (value ?? ""))}
          onChange={(option) => {
            onChange((option as AutocompleteOptionModel)?.id);
          }}
          error={error}
          helperText={error ? "This field is required" : undefined}
        />
      );
    },

    switch: <TName extends keyof FormValues>({
      field: { value, onChange },
    }: {
      field: ControllerRenderProps<FormValues, TName>;
    }) => (
      <Switch
        checked={Boolean(value)}
        edge="start"
        onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
          onChange(target.checked);
        }}
      />
    ),

    permissions: ({
      field: { value, onChange },
    }: { field: ControllerRenderProps<FormValues, "permissions"> }) => {
      return (
        <Autocomplete
          multiple
          label="Permissions"
          options={OPTIONS}
          value={value}
          onChange={onChange}
        />
      );
    },

    englishLevel: ({
      field: { value, onChange },
    }: { field: ControllerRenderProps<FormValues, "englishLevel"> }) => {
      return (
        <TextField
          label="English Level"
          variant="outlined"
          value={value}
          select
          onChange={({ target }: React.ChangeEvent<any>) => {
            onChange(target.value);
          }}
        >
          {Object.values(EventEnglishLevel).map((value) => (
            <MenuItem value={value} key={value}>
              {EVENT_ENGLISH_LEVEL_NAMES.get(value)}
            </MenuItem>
          ))}
        </TextField>
      );
    },

    eventCategory: ({
      field: { value, onChange },
    }: { field: ControllerRenderProps<FormValues, "eventCategory"> }) => {
      return (
        <TextField
          label="Event category"
          variant="outlined"
          value={value}
          select
          onChange={({ target }: React.ChangeEvent<any>) => {
            onChange(target.value);
          }}
        >
          {eventCategories.map((value) => (
            <MenuItem value={value} key={value}>
              {HLTEvent
                ? EVENT_CATEGORY_HLT_EVENTS.get(value)
                : EVENT_CATEGORY_MEMBER_EVENTS.get(value)}
            </MenuItem>
          ))}
        </TextField>
      );
    },
  };

  const toUTC = (date: Date): Date => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return fromZonedTime(date, timeZone);
  };

  const onSubmit = async (form: any): Promise<void> => {
    const variables = {
      id: event?.id,
      input: {
        title: form?.title,
        topicId: isEmpty(form?.topic?.id) ? null : form?.topic?.id,
        location: form?.location,
        isOnline: form?.isOnline,
        isPublic: form?.isPublic,
        isDraft: form?.isDraft,
        isDeleted: form?.isDeleted,
        permissions: map(prop("id"), form?.permissions ?? []),
        isForUnverified: form?.isForUnverified,
        englishLevel: form?.englishLevel,
        eventCategory: form?.eventCategory,
        description: form?.description,
        startDate: toUTC(event?.startDate ?? new Date()).toISOString(),
        endDate: toUTC(event?.endDate ?? new Date()).toISOString(),
      },
    } as any;

    if (form?.startURL) {
      variables.input.meta = {
        ...event.meta,
        startURL: form?.startURL,
        joinURL: form?.joinURL,
        meetingID: form?.meetingID,
      };
    }

    try {
      await editEvent({ variables });
      enqueueSnackbar("Event was updated successfully", { variant: "success" });
    } catch (_error) {
      enqueueSnackbar("There was a problem updating the event", { variant: "error" });
    }
  };

  return (
    <CardContent>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={Spacing.m}>
          <Grid item xs={Sizing.Full}>
            <FormControl fullWidth>
              <TextField
                label="Title"
                variant="outlined"
                {...register("title")}
                error={not(isNil(formState?.errors?.title))}
                helperText={
                  not(isNil(formState?.errors?.title)) ? "This field is required" : undefined
                }
              />
            </FormControl>
          </Grid>

          <Grid item xs={Sizing.Full}>
            <FormControl fullWidth>
              <TextField
                multiline
                label="Description"
                variant="outlined"
                {...register("description")}
                error={not(isNil(formState?.errors?.description))}
                helperText={
                  not(isNil(formState?.errors?.description)) ? "This field is required" : undefined
                }
              />
            </FormControl>
          </Grid>

          <Grid item xs={Sizing.Half}>
            <FormControl fullWidth>
              <TextField
                label="Start Date"
                variant="outlined"
                type="datetime-local"
                {...register("startDate")}
                error={not(isNil(formState?.errors?.startDate))}
                helperText={
                  not(isNil(formState?.errors?.startDate)) ? "This field is required" : undefined
                }
              />
            </FormControl>
          </Grid>

          <Grid item xs={Sizing.Half}>
            <FormControl fullWidth>
              <TextField
                label="End Date"
                variant="outlined"
                type="datetime-local"
                {...register("endDate")}
                error={not(isNil(formState?.errors?.endDate))}
                helperText={
                  not(isNil(formState?.errors?.endDate)) ? "This field is required" : undefined
                }
              />
            </FormControl>
          </Grid>

          <Grid item xs={Sizing.Half}>
            <FormControl fullWidth>
              <TextField label="Location" variant="outlined" {...register("location")} />
            </FormControl>
          </Grid>

          <Grid item xs={Sizing.Half}>
            <FormControl fullWidth>
              <Controller name="topicId" control={control} render={renderers.topic} />
            </FormControl>
          </Grid>

          <Grid item xs={Sizing.Full}>
            <FormControl fullWidth>
              <TextField
                label="Meeting ID"
                variant="outlined"
                {...register("meetingID")}
                error={not(isNil(formState?.errors?.meetingID))}
                helperText={
                  not(isNil(formState?.errors?.meetingID)) ? "This field is required" : undefined
                }
              />
            </FormControl>
          </Grid>

          <Grid item xs={Sizing.Half}>
            <FormControl fullWidth>
              <TextField
                label="Meeting Join URL"
                variant="outlined"
                {...register("joinURL")}
                error={not(isNil(formState?.errors?.joinURL))}
                helperText={
                  not(isNil(formState?.errors?.joinURL)) ? "This field is required" : undefined
                }
              />
            </FormControl>
          </Grid>

          <Grid item xs={Sizing.Half}>
            <FormControl fullWidth>
              <TextField
                label="Meeting Start URL"
                variant="outlined"
                {...register("startURL")}
                error={not(isNil(formState?.errors?.startURL))}
                helperText={
                  not(isNil(formState?.errors?.startURL)) ? "This field is required" : undefined
                }
              />
            </FormControl>
          </Grid>

          <Grid item xs={Sizing.Half}>
            <FormControl fullWidth>
              <TextField
                label="Meeting Public URL"
                variant="outlined"
                {...register("publicURL")}
                error={not(isNil(formState?.errors?.joinURL))}
                helperText={
                  not(isNil(formState?.errors?.joinURL)) ? "This field is required" : undefined
                }
              />
            </FormControl>
          </Grid>

          <Grid item xs={Sizing.Half}>
            <FormControl fullWidth>
              <Controller name="permissions" control={control} render={renderers.permissions} />
            </FormControl>
          </Grid>

          <Grid item xs={Sizing.Half}>
            <FormControl fullWidth>
              <Controller name="englishLevel" control={control} render={renderers.englishLevel} />
            </FormControl>
          </Grid>

          {not(isOneToOne) ? (
            <Grid item xs={Sizing.Half}>
              <FormControl fullWidth>
                <Controller
                  name="eventCategory"
                  control={control}
                  render={renderers.eventCategory}
                />
              </FormControl>
            </Grid>
          ) : null}

          <Grid item xs={Sizing.Half}>
            <Typography variant="h5" color="textPrimary">
              For unverfied users
            </Typography>
            <Typography variant="body2" color="textSecondary">
              This will mark the event for unverified users
            </Typography>
            <Controller name="isForUnverified" control={control} render={renderers.switch} />
          </Grid>

          <Grid item xs={Sizing.Half}>
            <Typography variant="h5" color="textPrimary">
              Public Event
            </Typography>
            <Typography variant="body2" color="textSecondary">
              This will make the event public
            </Typography>
            <Controller name="isPublic" control={control} render={renderers.switch} />
          </Grid>

          <Grid item xs={Sizing.Half}>
            <Typography variant="h5" color="textPrimary">
              Online event
            </Typography>
            <Typography variant="body2" color="textSecondary">
              This will make the event online
            </Typography>
            <Controller name="isOnline" control={control} render={renderers.switch} />
          </Grid>

          <Grid item xs={Sizing.Half}>
            <Typography variant="h5" color="textPrimary">
              Draft
            </Typography>
            <Typography variant="body2" color="textSecondary">
              This will make the event draft
            </Typography>
            <Controller name="isDraft" control={control} render={renderers.switch} />
          </Grid>

          <Grid item xs={Sizing.Half}>
            <Typography variant="h5" color="textPrimary">
              Deleted
            </Typography>
            <Typography variant="body2" color="textSecondary">
              This will mark the event as deleted
            </Typography>
            <Controller name="isDeleted" control={control} render={renderers.switch} />
          </Grid>

          <Grid item xs={Sizing.Full}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              disabled={loading || not(formState.isDirty) || not(formState.isValid)}
            >
              Update Event
            </Button>
          </Grid>
        </Grid>
      </form>
    </CardContent>
  );
};

export default EventEditPageFormCont;
