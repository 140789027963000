import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { append, insert, not } from "ramda";
import {
  type ChangeEvent,
  type ReactNode,
  useCallback,
  useMemo,
  useReducer,
  useState,
} from "react";
import { type FieldValues, type SubmitHandler, useForm } from "react-hook-form";
import { type UserModel, useMeQuery } from "src/types/graphql";
import type * as yup from "yup";
import SortableListItem from "../components/SortableListItem";
import { UPLOAD_ZINE_COVER } from "../gql/mutations/zineCover";
import { PAGE_DEFAULT, ROWS_PER_PAGE_DEFAULT } from "./const";
import { without } from "./sortable";
import { noop } from "./utils";

export const useAuth = () => {
  const { data } = useMeQuery();

  return {
    me: data?.me as UserModel,
    authenticated: !!data?.me,
  } as const;
};

export const useSetState = <T,>(initialState: T) => {
  return useReducer((prevState: T, state: T) => ({ ...prevState, ...state }), initialState);
};

export const useSwitch = (initialState = false) => {
  const [state, setState] = useState(initialState);

  const onSwitch = useCallback(() => {
    setState(not(state));
  }, [state]);

  return [state, onSwitch] as const;
};

export const useOnOffSwitch = (initialState = false) => {
  const [state, setSwitch] = useSetState<{ on: boolean }>({
    on: initialState,
  });

  const onOn = () => {
    setSwitch({
      on: true,
    });
  };

  const onOff = () => {
    setSwitch({
      on: false,
    });
  };

  return [state.on, onOn, onOff] as const;
};

export const usePagination = <T,>(data: T[] = []) => {
  const [page, setPage] = useState<number>(PAGE_DEFAULT);
  const [rowsPerPage, setRowsPerPage] = useState<number>(ROWS_PER_PAGE_DEFAULT);

  const items = useMemo(() => {
    return data;
  }, [data]);

  const onChangePage = useCallback((_: any, nextPage: number) => {
    setPage(nextPage);
  }, []);

  const onResetPage = () => {
    setPage(PAGE_DEFAULT);
  };

  const onChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number.parseInt(event.target.value));
  };

  return {
    page,
    rowsPerPage,
    items,
    onChangePage,
    onChangeRowsPerPage,
    onResetPage,
  } as const;
};

export const useSortableList = <T extends FieldValues>({
  data,
  schema,
  render,
  onAfterSubmit = noop,
  setValue,
  keyFn,
}: {
  data: T[];
  schema: yup.ObjectSchema<any>;
  onAfterSubmit: () => void;
  render: (item: T) => ReactNode;
  setValue?: (value: T[]) => void;
  keyFn: (item: T) => string;
}) => {
  const list = data ?? [];

  const { register, formState, handleSubmit } = useForm<T>({
    resolver: yupResolver(schema),
  });

  const onSort = (index: number, destination: number) => {
    const updatedList = insert(destination, list[index], without<T>(list, index));
    if (setValue) {
      setValue(updatedList);
    }
  };

  const onDelete = (index: number) => {
    const updatedList = without<T>(list, index);
    if (setValue) {
      setValue(updatedList);
    }
  };

  const onSubmit: SubmitHandler<T> = (item: T) => {
    const updatedList = append({ ...item }, list);
    if (setValue) {
      setValue(updatedList);
    }
    onAfterSubmit();
  };

  const renderer = list.map((item, index) => (
    <SortableListItem
      key={keyFn(item)}
      index={index}
      total={list.length}
      onSort={onSort}
      onDelete={onDelete}
    >
      {render(item)}
    </SortableListItem>
  ));

  return {
    renderer,
    register,
    formState,
    handleSubmit,
    onSubmit,
  } as const;
};

export const useUploadZineCover = (zineId?: string) => {
  const [url, setUrl] = useState(null);

  const [uploadZineCover, { loading }] = useMutation(UPLOAD_ZINE_COVER);

  const onDrop = async ([file]: File[]): Promise<void> => {
    const variables = {
      input: {
        file,
        articleId: zineId,
      },
    };

    try {
      const response = await uploadZineCover({ variables });
      setUrl(response.data?.uploadZineCover.coverUrl);
    } catch (error) {
      console.error(error);
    }
  };

  return {
    url,
    loading,
    onDrop,
  } as const;
};
