import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  MenuItem,
  TextField,
  type Theme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useSnackbar } from "notistack";
import { head, not, values } from "ramda";
import React, { useEffect } from "react";
import { geocodeByPlaceId } from "react-google-places-autocomplete";
import { Controller, type ControllerRenderProps, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import {
  EnglishLevel,
  EnglishSkill,
  EventPermissions,
  Interest,
  MembershipPlans,
  type OccupationModel,
  SubscriptionStatus,
  UserBadgeType,
} from "src/types/graphql";

import { type AutocompleteRenderOptionState } from "@mui/material/Autocomplete/Autocomplete";
import {
  BooleanOptions,
  ENGLISH_LEVELS,
  ENGLISH_SKILLS_NAMES,
  INTERESTS_NAMES,
  OPEN_TO_SPEAKING_PARTNERS,
  SUBSCRIPTION_STATUSES,
  SUBSCRIPTION_TYPES,
  USER_BADGE_TYPES,
  USER_PERMISSIONS,
  UrlFilterParams,
  VERIFICATION_STATUSES,
} from "../helpers/const";
import { Sizing, Spacing } from "../types/enum";
import { type AutocompleteOptionModel } from "../types/generic";

import Autocomplete from "./Autocomplete";
import UsersLocationFilter from "./UsersLocationFilter";

/**
 * Types
 */
interface Props {
  filters: any;
  loading: boolean;
  occupationOptions: OccupationModel[];
  clearSelection: () => void;
}

interface PlaceModel {
  city?: string;
  country?: string;
}

interface Option<T> {
  id: T;
  title: string;
}

/**
 * Helpers
 */

const createOption = <T,>(titleMap: Map<T, string>, id: T): Option<T> => {
  const title = titleMap.get(id) || "";
  return { id, title };
};

const personalInterestsOptions = (interest: Interest) => createOption(INTERESTS_NAMES, interest);
const englishSkillsOptions = (skill: EnglishSkill) => createOption(ENGLISH_SKILLS_NAMES, skill);

const cityAndCountry = (addresses: google.maps.GeocoderAddressComponent[] = []): PlaceModel => {
  return addresses.reduce((accumulator, address) => {
    if (address.types.includes("sublocality") || address.types.includes("locality")) {
      return { ...accumulator, city: address.long_name };
    }
    if (address.types.includes("country")) {
      return { ...accumulator, country: address.long_name, countryId: address.short_name };
    }
    return accumulator;
  }, {});
};

const loadScript = () => {
  const scriptTag = document.createElement("script");
  scriptTag.src = `https://maps.googleapis.com/maps/api/js?key=${
    import.meta.env.VITE_GOOGLE_PLACES_API_KEY
  }&libraries=places&language=en`;
  document.head.appendChild(scriptTag);
};

/**
 * Constants
 */
const INTERESTS_OPTIONS = values(Interest).map(personalInterestsOptions);
const SKILLS_OPTIONS = values(EnglishSkill).map(englishSkillsOptions);
const ENGLISH_LEVEL_OPTIONS = Object.values(EnglishLevel);
const BOOLEAN_OPTIONS = Object.values(BooleanOptions);
const EXCLUDED_SUBSCRIPTIONS = ["FUTURE", "INCOMPLETE", "INCOMPLETE_EXPIRED", "PAST_DUE", "UNPAID"];
const FILTERED_SUBSCRIPTIONS = Object.values(SubscriptionStatus).filter((key) =>
  not(EXCLUDED_SUBSCRIPTIONS.includes(key)),
);
const EXCLUDED_BADGES = [
  UserBadgeType.Verified,
  UserBadgeType.BigSister,
  UserBadgeType.PerfectProfile,
];
const BADGE_OPTIONS = values(UserBadgeType).filter((key) => not(EXCLUDED_BADGES.includes(key)));

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) => ({
  select: {
    "& .MuiInputBase-input": {
      height: "32px",
      paddingTop: theme.spacing(Spacing.s),
    },
  },
}));

const UsersFilters: React.FC<Props> = ({
  filters,
  loading,
  occupationOptions,
  clearSelection,
}: Props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [, setSearchParams] = useSearchParams();

  type FormValues = typeof filters;

  const { control, handleSubmit, reset } = useForm<FormValues>({
    mode: "all",
    defaultValues: {
      plan: filters.plan,
      subscriptionStatus: filters.subscriptionStatus,
      kind: filters.kind,
      location: filters.location,
      interests: filters.interests.map((interest: Interest) => personalInterestsOptions(interest)),
      englishSkills: filters.englishSkills.map((skill: EnglishSkill) =>
        englishSkillsOptions(skill),
      ),
      englishLevels: filters.englishLevels,
      openToSpeakingPartners: filters.openToSpeakingPartners,
      occupations: occupationOptions.filter((o) => filters.occupations.includes(o.id)),
      badges: filters.badges,
      verificationStatus: filters.verificationStatus,
    },
  });

  const onSubmit = async (form: FormValues): Promise<void> => {
    const getCityAndCountry = async (id: string) => {
      try {
        const data = await geocodeByPlaceId(id);
        const places = head(data);
        const address = cityAndCountry(places?.address_components);
        return { id, ...address };
      } catch (error: any) {
        enqueueSnackbar(error.message, { variant: "error" });
      }
    };

    const location = await Promise.all(
      form.location?.map(async (option: AutocompleteOptionModel) => ({
        ...(await getCityAndCountry(option.id)),
        name: option.name,
      })),
    );

    const newFilters = {
      plan: form.plan,
      subscriptionStatus: form.subscriptionStatus,
      kind: form.kind,
      location,
      interests: form.interests?.map(
        (interest: { id: string; title: string }) => interest.id as Interest,
      ),
      englishSkills: form.englishSkills?.map(
        (skill: { id: string; title: string }) => skill.id as EnglishSkill,
      ),
      englishLevels: form.englishLevels,
      openToSpeakingPartners: form.openToSpeakingPartners,
      occupations: form.occupations?.map(
        (occupation: { id: string; title: string }) => occupation.id,
      ),
      badges: form.badges,
      verificationStatus: form.verificationStatus,
    };

    const query = new URLSearchParams();

    query.set(UrlFilterParams.Subscription, newFilters.subscriptionStatus);
    query.set(UrlFilterParams.Plan, newFilters.plan);
    query.set(UrlFilterParams.Kind, newFilters.kind);
    query.set(UrlFilterParams.Location, JSON.stringify(newFilters.location));
    query.set(UrlFilterParams.Interests, newFilters.interests);
    query.set(UrlFilterParams.Skills, newFilters.englishSkills);
    query.set(UrlFilterParams.Levels, newFilters.englishLevels);
    query.set(UrlFilterParams.Open, newFilters.openToSpeakingPartners);
    query.set(UrlFilterParams.Occupations, newFilters.occupations);
    query.set(UrlFilterParams.Badges, newFilters.badges);
    query.set(UrlFilterParams.VerificationStatus, newFilters.verificationStatus);

    setSearchParams(query);
  };

  const clearFilters = () => {
    const query = new URLSearchParams();
    setSearchParams(query);
    reset({
      plan: [],
      subscriptionStatus: [],
      kind: [],
      location: [],
      interests: [],
      englishSkills: [],
      englishLevels: [],
      openToSpeakingPartners: [],
      occupations: [],
      badges: [],
      verificationStatus: [],
    });
  };

  const renderers = {
    autocompleteOption: (
      props: React.HTMLAttributes<HTMLLIElement>,
      option: AutocompleteOptionModel,
      state: AutocompleteRenderOptionState,
    ) => {
      return (
        <Box component="li" {...props} key={option.id}>
          <Checkbox checked={state.selected} color="primary" id={option.id} />
          {option.title || option.name}
        </Box>
      );
    },
    plan: ({
      field: { value, onChange },
    }: { field: ControllerRenderProps<FormValues, "plan"> }) => {
      return (
        <TextField
          label="Subscription Type"
          variant="outlined"
          value={value}
          select
          SelectProps={{ multiple: true }}
          onChange={({ target }: React.ChangeEvent<any>) => {
            onChange(target.value);
          }}
          className={classes.select}
        >
          {Object.values(MembershipPlans).map((option) => (
            <MenuItem value={option} key={option}>
              <Checkbox color="primary" checked={value.includes(option)} />
              {SUBSCRIPTION_TYPES.get(option)}
            </MenuItem>
          ))}
        </TextField>
      );
    },
    subscriptionStatus: ({
      field: { value, onChange },
    }: {
      field: ControllerRenderProps<FormValues, "subscriptionStatus">;
    }) => {
      return (
        <TextField
          label="Subscription Status"
          variant="outlined"
          value={value}
          select
          SelectProps={{ multiple: true }}
          onChange={({ target }: React.ChangeEvent<any>) => {
            onChange(target.value);
          }}
          className={classes.select}
        >
          {FILTERED_SUBSCRIPTIONS.map((option) => (
            <MenuItem value={option} key={option}>
              <Checkbox color="primary" checked={value.includes(option)} />
              {SUBSCRIPTION_STATUSES.get(option)}
            </MenuItem>
          ))}
        </TextField>
      );
    },
    kind: ({
      field: { value, onChange },
    }: { field: ControllerRenderProps<FormValues, "kind"> }) => {
      return (
        <TextField
          label="Role"
          variant="outlined"
          value={value}
          select
          SelectProps={{ multiple: true }}
          onChange={({ target }: React.ChangeEvent<any>) => {
            onChange(target.value);
          }}
          className={classes.select}
        >
          {Object.values(EventPermissions).map((option) => (
            <MenuItem value={option} key={option}>
              <Checkbox color="primary" checked={value.includes(option)} />
              {USER_PERMISSIONS.get(option)}
            </MenuItem>
          ))}
        </TextField>
      );
    },
    location: ({
      field: { value, onChange },
    }: { field: ControllerRenderProps<FormValues, "location"> }) => {
      return <UsersLocationFilter label="Location" value={value} onChange={onChange} />;
    },
    skills: ({
      field: { value, onChange },
    }: { field: ControllerRenderProps<FormValues, "englishSkills"> }) => {
      return (
        <Autocomplete
          multiple
          label="English Interests"
          options={SKILLS_OPTIONS}
          renderOption={renderers.autocompleteOption}
          value={value}
          onChange={onChange}
          disableCloseOnSelect
          limitTags={3}
        />
      );
    },
    interests: ({
      field: { value, onChange },
    }: { field: ControllerRenderProps<FormValues, "interests"> }) => {
      return (
        <Autocomplete
          multiple
          label="Personal Interests"
          options={INTERESTS_OPTIONS}
          renderOption={renderers.autocompleteOption}
          value={value}
          onChange={onChange}
          disableCloseOnSelect
          limitTags={3}
        />
      );
    },
    englishLevels: ({
      field: { value, onChange },
    }: { field: ControllerRenderProps<FormValues, "englishLevels"> }) => {
      return (
        <TextField
          label="English Level"
          variant="outlined"
          value={value}
          select
          SelectProps={{ multiple: true }}
          onChange={({ target }: React.ChangeEvent<any>) => {
            onChange(target.value);
          }}
          className={classes.select}
        >
          {ENGLISH_LEVEL_OPTIONS.slice(2).map((option) => (
            <MenuItem value={option} key={option}>
              <Box mb={Spacing.xs} component="span">
                <Checkbox color="primary" checked={value.includes(option)} />
                {ENGLISH_LEVELS.get(option)}
              </Box>
            </MenuItem>
          ))}
        </TextField>
      );
    },
    open: ({
      field: { value, onChange },
    }: { field: ControllerRenderProps<FormValues, "openToSpeakingPartners"> }) => {
      return (
        <TextField
          label="Speaking Partners"
          variant="outlined"
          value={value}
          SelectProps={{ multiple: true }}
          select
          onChange={({ target }: React.ChangeEvent<any>) => {
            onChange(target.value);
          }}
          className={classes.select}
        >
          {BOOLEAN_OPTIONS.map((option) => (
            <MenuItem value={option} key={option}>
              <Box mb={Spacing.xs} component="span">
                <Checkbox color="primary" checked={value.includes(option)} />
                {OPEN_TO_SPEAKING_PARTNERS.get(option)}
              </Box>
            </MenuItem>
          ))}
        </TextField>
      );
    },
    occupations: ({
      field: { value, onChange },
    }: { field: ControllerRenderProps<FormValues, "occupations"> }) => {
      return (
        <Autocomplete
          multiple
          label="Occupations"
          options={occupationOptions as AutocompleteOptionModel[]}
          value={value}
          onChange={onChange}
          disableCloseOnSelect
          limitTags={3}
          groupBy={(option) => option.groupName ?? "Other"}
          renderOption={renderers.autocompleteOption}
        />
      );
    },
    badges: ({
      field: { value, onChange },
    }: { field: ControllerRenderProps<FormValues, "badges"> }) => {
      return (
        <TextField
          label="Badges"
          variant="outlined"
          value={value}
          select
          SelectProps={{ multiple: true }}
          onChange={({ target }: React.ChangeEvent<any>) => {
            onChange(target.value);
          }}
          className={classes.select}
        >
          {BADGE_OPTIONS.map((option) => (
            <MenuItem value={option} key={option}>
              <Checkbox color="primary" checked={value.includes(option)} />
              {USER_BADGE_TYPES.get(option)}
            </MenuItem>
          ))}
        </TextField>
      );
    },
    verificationStatus: ({
      field: { value, onChange },
    }: {
      field: ControllerRenderProps<FormValues, "verificationStatus">;
    }) => {
      return (
        <TextField
          label="Verification Status"
          variant="outlined"
          value={value}
          SelectProps={{ multiple: true }}
          select
          onChange={({ target }: React.ChangeEvent<any>) => {
            onChange(target.value);
          }}
          className={classes.select}
        >
          {BOOLEAN_OPTIONS.map((option) => (
            <MenuItem value={option} key={option}>
              <Box mb={Spacing.xs} component="span">
                <Checkbox color="primary" checked={value.includes(option)} />
                {VERIFICATION_STATUSES.get(option)}
              </Box>
            </MenuItem>
          ))}
        </TextField>
      );
    },
  };

  useEffect(() => {
    loadScript();
    return () => {
      const scriptTag = document.querySelector(
        'script[src^="https://maps.googleapis.com/maps/api/js"]',
      );
      if (scriptTag) {
        document.head.removeChild(scriptTag);
      }
    };
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid spacing={Spacing.m} container>
        <Grid item xs={Sizing.OneFourth}>
          <FormControl fullWidth>
            <Controller
              name="subscriptionStatus"
              control={control}
              render={renderers.subscriptionStatus}
            />
          </FormControl>
        </Grid>
        <Grid item xs={Sizing.OneFourth}>
          <FormControl fullWidth>
            <Controller name="plan" control={control} render={renderers.plan} />
          </FormControl>
        </Grid>
        <Grid item xs={Sizing.OneFourth}>
          <FormControl fullWidth>
            <Controller name="kind" control={control} render={renderers.kind} />
          </FormControl>
        </Grid>
        <Grid item xs={Sizing.OneFourth}>
          <FormControl fullWidth>
            <Controller
              name="verificationStatus"
              control={control}
              render={renderers.verificationStatus}
            />
          </FormControl>
        </Grid>
        <Grid item xs={Sizing.OneFourth}>
          <FormControl fullWidth>
            <Controller name="englishSkills" control={control} render={renderers.skills} />
          </FormControl>
        </Grid>
        <Grid item xs={Sizing.OneFourth}>
          <FormControl fullWidth>
            <Controller name="interests" control={control} render={renderers.interests} />
          </FormControl>
        </Grid>
        <Grid item xs={Sizing.OneFourth}>
          <FormControl fullWidth>
            <Controller name="englishLevels" control={control} render={renderers.englishLevels} />
          </FormControl>
        </Grid>
        <Grid item xs={Sizing.OneFourth}>
          <FormControl fullWidth>
            <Controller name="openToSpeakingPartners" control={control} render={renderers.open} />
          </FormControl>
        </Grid>
        <Grid item xs={Sizing.OneFourth}>
          <FormControl fullWidth>
            <Controller name="occupations" control={control} render={renderers.occupations} />
          </FormControl>
        </Grid>
        <Grid item xs={Sizing.OneFourth}>
          <FormControl fullWidth>
            <Controller name="location" control={control} render={renderers.location} />
          </FormControl>
        </Grid>
        <Grid item xs={Sizing.OneFourth}>
          <FormControl fullWidth>
            <Controller name="badges" control={control} render={renderers.badges} />
          </FormControl>
        </Grid>
        <Grid item xs={Sizing.OneFourth}>
          <Box display="flex" justifyContent="flex-end">
            <Box mr={Spacing.m}>
              <Button variant="outlined" onClick={clearFilters}>
                Clear filters
              </Button>
            </Box>

            <Button variant="contained" type="submit" disabled={loading} onClick={clearSelection}>
              Show Results
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default UsersFilters;
