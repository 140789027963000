import { gql } from "@apollo/client";

export const QUERY_COVERS = gql`
  query adminListPaginatedCovers($filters: AdminFilterCoversInput!) {
    adminListPaginatedCovers(filters: $filters) {
      items {
        id
        title
        primary {
          mobile
          desktop
        }
        secondary {
          mobile
          desktop
        }
        tertiary {
          mobile
          desktop
        }
      }
      total
    }
  }
`;

export const QUERY_COVER = gql`
  query adminGetCover($id: String!) {
    adminGetCover(id: $id) {
      id
      title
      primary {
        mobile
        desktop
      }
      secondary {
        mobile
        desktop
      }
      tertiary {
        mobile
        desktop
      }
    }
  }
`;
