import { gql } from "@apollo/client";

export const QUERY_ILLUSTRATION = gql`
  query adminGetIllustration($id: String!) {
    adminGetIllustration(id: $id) {
      id
      title
      url
    }
  }
`;

export const QUERY_ILLUSTRATIONS = gql`
  query adminListPaginatedIllustrations($filters: AdminFilterIllustrationsInput!) {
    adminListPaginatedIllustrations(filters: $filters) {
      items {
        id
        title
        url
      }
      total
    }
  }
`;
