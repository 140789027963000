import PanToolIcon from "@mui/icons-material/PanTool";
import { Box, Typography } from "@mui/material";
import React from "react";

import makeStyles from "@mui/styles/makeStyles";

import { Spacing } from "../types/enum";

/**
 * Styles
 */
const useStyles = makeStyles({
  root: {
    width: "100%",
    minHeight: "180px",
  },
});

const LessonEditPageSectionInstructions: React.FC = () => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      className={classes.root}
    >
      <PanToolIcon />
      <Box mb={Spacing.xs} mt={Spacing.sm}>
        <Typography variant="body1" align="center">
          Drag and drop
        </Typography>
      </Box>
      <Typography variant="body2" align="center" color="textSecondary">
        Start with drag and drop elements from the right panel.
      </Typography>
    </Box>
  );
};

export default LessonEditPageSectionInstructions;
