import {
  Box,
  Card,
  FormControl,
  Grid,
  IconButton,
  SvgIcon,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { inc } from "ramda";
import React from "react";
import { type Control, Controller, type UseFormRegisterReturn } from "react-hook-form";

import {
  AdminGetVideoSetDocument,
  type ColourCode,
  type VideoModel,
  useAdminDeleteVideoMutation,
  useAdminOrderVideoMutation,
} from "../types/graphql";

import { MoveElement, VIDEO_SET_COLOURS } from "../helpers/const";
import { useSwitch } from "../helpers/hooks";
import { Sizing, Spacing } from "../types/enum";
import { type AttachmentModel } from "../types/generic";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { ChevronDown, ChevronUp, Trash2 as DeleteIcon } from "react-feather";

import FileDropzone from "../components/FileDropzone";

/**
 * Types
 */
interface Props {
  index: number;
  videoSetID: string;
  video: VideoModel;
  colour: ColourCode;
  register: (field: any) => UseFormRegisterReturn;
  control: Control<any>;
  total: number;
}

const VideosPageEditVideoCont: React.FC<Props> = ({
  index,
  videoSetID,
  video,
  colour,
  register,
  control,
  total,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [expanded, onExpand] = useSwitch();

  const [deleteVideoMutation] = useAdminDeleteVideoMutation({
    refetchQueries: [
      {
        query: AdminGetVideoSetDocument,
        variables: {
          id: videoSetID,
        },
      },
    ],
  });

  const [editVideoOrder, { loading }] = useAdminOrderVideoMutation({
    refetchQueries: [
      {
        query: AdminGetVideoSetDocument,
        variables: {
          id: videoSetID,
        },
      },
    ],
  });

  const deleteVideo = async () => {
    const variables = {
      id: video.id,
    };

    try {
      await deleteVideoMutation({ variables });
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const onSort = async (position: MoveElement) => {
    const variables = {
      id: video.id,
      input: position,
    };

    try {
      await editVideoOrder({ variables });
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const renderers = {
    switch: ({ field: { value, onChange } }: { field: any }) => {
      return (
        <Switch
          edge="start"
          checked={value}
          color="secondary"
          onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => onChange(target.checked)}
        />
      );
    },
    placeholder: () => {
      return (
        <Box>
          <Typography variant="h5" color="textPrimary" align="center">
            Select files
          </Typography>
          <Typography variant="body2" color="textSecondary" align="center">
            Drag and drop file here
          </Typography>
          <Typography variant="body2" color="textSecondary" align="center">
            Format is 16:9 ratio
          </Typography>
        </Box>
      );
    },
    file: ({ field: { value, onChange } }: { field: any }) => {
      return (
        <FileDropzone
          preview
          showFileContained
          placeholder={renderers.placeholder()}
          fileName={value ?? undefined}
          onDropFile={(attachment: AttachmentModel) => onChange(attachment?.url)}
        />
      );
    },
  };

  return (
    <Card>
      <Box display="flex" justifyContent="space-between" alignItems="center" p={Spacing.sm}>
        <Box display="flex" alignItems="center">
          {expanded ? (
            <Box p={Spacing.s}>
              <FormControl fullWidth>
                <TextField
                  label="Title"
                  variant="outlined"
                  {...register(`videos.${index}.title` as const)}
                />
              </FormControl>
            </Box>
          ) : (
            <Box
              ml={Spacing.m}
              p={Spacing.s}
              sx={{ backgroundColor: VIDEO_SET_COLOURS.get(colour), borderRadius: Spacing.s }}
            >
              <Typography color="textPrimary">{video.title}</Typography>
            </Box>
          )}
        </Box>

        <Box display="flex" alignItems="center">
          <Box mr={Spacing.xl}>
            {index ? (
              <IconButton size="small" onClick={() => onSort(MoveElement.UP)} disabled={loading}>
                <ArrowUpwardIcon fontSize="small" />
              </IconButton>
            ) : null}
            {inc(index) < total ? (
              <IconButton size="small" onClick={() => onSort(MoveElement.DOWN)} disabled={loading}>
                <ArrowDownwardIcon fontSize="small" />
              </IconButton>
            ) : null}
          </Box>
          <Controller
            key={index}
            name={`videos.${index}.isActive`}
            control={control}
            render={renderers.switch}
          />

          <SvgIcon fontSize="large" onClick={onExpand}>
            {expanded ? <ChevronUp /> : <ChevronDown />}
          </SvgIcon>
          <IconButton onClick={deleteVideo} size="large">
            <SvgIcon fontSize="small">
              <DeleteIcon />
            </SvgIcon>
          </IconButton>
        </Box>
      </Box>
      {expanded ? (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          ml={Spacing.xl}
          pl={Spacing.m}
          pr={Spacing.ml}
          pb={Spacing.ml}
        >
          <Grid container spacing={Spacing.m}>
            <Grid item xs={Sizing.Full} lg={Sizing.TwoThirds}>
              <FormControl fullWidth>
                <TextField
                  label="Video URL"
                  variant="outlined"
                  {...register(`videos.${index}.videoURL` as const)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={Sizing.Full} lg={Sizing.OneThird}>
              <Controller
                name={`videos.${index}.thumbnail`}
                control={control}
                render={renderers.file}
              />
            </Grid>
          </Grid>
        </Box>
      ) : null}
    </Card>
  );
};

export default VideosPageEditVideoCont;
