import { Box } from "@mui/material";
import React from "react";
import CopyLinkToElement from "src/components/Topics/CopyLinkToElement.tsx";
import { Spacing } from "src/types/enum";
import LessonEditPageSectionButton from "./LessonEditPageSectionButton";
import LessonEditPageSectionImage, { ImageWithUrl } from "./LessonEditPageSectionImage";

/**
 * Types
 */
interface ImageAndButtonDetails {
  buttonType: string;
  buttonAlignment: string;
  buttonText: string;
  buttonURL: string;
  fileUrl: ImageWithUrl | string;
}

interface Props {
  value: ImageAndButtonDetails;
  setValue: (value: ImageAndButtonDetails) => void;
  id: string;
  topicId: string;
  lessonPath: string;
  step?: number | null;
}

const LessonEditPageSectionImageAndButton: React.FC<Props> = ({
  value,
  setValue,
  id,
  topicId,
  lessonPath,
  step,
}: Props) => {
  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        <CopyLinkToElement id={id} topicId={topicId} lessonPath={lessonPath} step={step} />
      </Box>
      <Box mb={Spacing.l}>
        <LessonEditPageSectionImage
          value={value.fileUrl}
          setValue={(fileUrl) => {
            setValue({ ...value, fileUrl });
          }}
        />
      </Box>
      <LessonEditPageSectionButton
        value={value}
        setValue={(button) => {
          setValue({ ...value, ...button });
        }}
      />
    </Box>
  );
};

export default LessonEditPageSectionImageAndButton;
