import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import { not } from "ramda";
import React from "react";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  TextField,
  Typography,
  adaptV4Theme,
} from "@mui/material";

import { yupResolver } from "@hookform/resolvers/yup";
import { type SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";

import { CREATE_THEME } from "../gql/mutations/theme";
import { FILTER_THEMES } from "../gql/queries/theme";

import { Spacing } from "../types/enum";

/**
 * Types
 */
interface Props {
  open: boolean;
  onClose: () => void;
  limit: number;
  offset: number;
  isStandard?: boolean;
  isTopicLibrary?: boolean;
  isResource?: boolean;
  isGrammarLibrary?: boolean;
}

/**
 * Schema
 */
const schema = yup.object().shape({
  title: yup.string().required(),
});

interface FormValues {
  title: string;
}

const ThemesPageCreateThemeCont: React.FC<Props> = ({
  open,
  onClose,
  limit,
  offset,
  isStandard,
  isTopicLibrary,
  isResource,
  isGrammarLibrary,
}: Props) => {
  const [createTheme, { loading }] = useMutation(CREATE_THEME, {
    refetchQueries: [{ query: FILTER_THEMES, variables: { filters: { limit, offset } } }],
  });

  const { register, formState, handleSubmit } = useForm<FormValues>({
    mode: "all",
    resolver: yupResolver(schema),
  });

  const { enqueueSnackbar } = useSnackbar();

  const onSubmit: SubmitHandler<FormValues> = async (form) => {
    const variables = {
      input: {
        title: form.title,
        isStandard: Boolean(isStandard),
        isTopicLibrary: Boolean(isTopicLibrary),
        isGrammarLibrary: Boolean(isGrammarLibrary),
        isResource: Boolean(isResource),
      },
    };

    try {
      // @ts-expect-error Migrate styles in material ui
      await createTheme(adaptV4Theme({ variables }));
      enqueueSnackbar("Theme successfully created", { variant: "success" });
      onClose();
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return (
    <Dialog maxWidth="lg" open={open} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Box mb={Spacing.sm}>
            <Typography variant="body1" color="textPrimary">
              Theme
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Please input the title of the theme that you would like to create.
            </Typography>
          </Box>

          <FormControl fullWidth>
            <TextField label="Title" variant="outlined" {...register("title")} />
          </FormControl>
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit" disabled={not(formState.isValid) || loading}>
            Create
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ThemesPageCreateThemeCont;
