import { Box, Checkbox, FormControl, FormLabel, Grid, TextField } from "@mui/material";
import React from "react";

import CopyLinkToElement from "src/components/Topics/CopyLinkToElement.tsx";
import { Sizing, Spacing } from "../types/enum";
import { type AttachmentModel } from "../types/generic";
import FileDropzone from "./FileDropzone";

/**
 * Types
 */
export interface ImageWithUrl {
  fileUrl: string;
  url?: string;
  openInNewTab: boolean;
}

interface Props {
  value: ImageWithUrl | string;
  setValue: (value: ImageWithUrl) => void;
  id?: string;
  topicId?: string;
  lessonPath?: string;
  step?: number | null;
}

const LessonEditPageSectionImage: React.FC<Props> = ({
  value,
  setValue,
  id,
  topicId,
  lessonPath,
  step,
}: Props) => {
  const image =
    typeof value === "string" ? { fileUrl: value, url: "", openInNewTab: false } : value;

  return (
    <Grid container spacing={Spacing.s}>
      <Grid item xs={Sizing.Full}>
        <FormControl fullWidth>
          <Box mb={Spacing.s} display="flex" alignItems="center" justifyContent="space-between">
            <FormLabel>Image</FormLabel>
            {id ? (
              <CopyLinkToElement
                id={id}
                topicId={topicId ?? ""}
                lessonPath={lessonPath ?? ""}
                step={step}
              />
            ) : null}
          </Box>
          <FileDropzone
            preview
            showFileContained
            fileName={image?.fileUrl}
            onDropFile={(attachment: AttachmentModel) => {
              setValue({ ...image, fileUrl: attachment.url ?? "" });
            }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={Sizing.Half}>
        <FormControl fullWidth>
          <FormLabel>URL</FormLabel>
          <TextField
            fullWidth
            value={image?.url}
            onChange={(event) => {
              setValue({ ...image, url: event.target.value });
            }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={Sizing.Half}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-end"
          height="100%"
          width="100%"
        >
          <FormLabel>Open link in new tab?</FormLabel>
          <FormControl>
            <Checkbox
              checked={image?.openInNewTab}
              onChange={(event) => {
                setValue({ ...image, openInNewTab: event.target.checked });
              }}
            />
          </FormControl>
        </Box>
      </Grid>
    </Grid>
  );
};

export default LessonEditPageSectionImage;
