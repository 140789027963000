import { yupResolver } from "@hookform/resolvers/yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { Alert, Box, Button, CircularProgress, TextField, Typography } from "@mui/material";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import QRCode from "react-qr-code";
import { useNavigate } from "react-router-dom";
import { useMount } from "react-use";
import { LIST_TOTP } from "src/gql/queries/totp";
import { Spacing } from "src/types/enum";
import {
  MutationStatus,
  useConfirmTotpMutation,
  useCreateTotpMutation,
  useRemoveTotpMutation,
} from "src/types/graphql";
import * as yup from "yup";

interface FormValues {
  deviceName: string;
  token: string;
}

const schema = yup.object().shape({
  deviceName: yup.string().required(),
  token: yup.string().length(6).required(),
});

export default function AccountSettingsNewDevicePage() {
  const navigate = useNavigate();
  const [createTotpMutation, { data: createData, loading: createLoading, error: createError }] =
    useCreateTotpMutation();
  const [confirmTotpMutation, { loading: confirmLoading, error: confirmError }] =
    useConfirmTotpMutation({ refetchQueries: [LIST_TOTP] });
  const [removeTotpMutation] = useRemoveTotpMutation();
  const { register, handleSubmit } = useForm<FormValues>({
    mode: "all",
    resolver: yupResolver(schema),
  });

  const onSubmit = async (form: FormValues): Promise<void> => {
    const result = await confirmTotpMutation({
      variables: {
        input: {
          id: createData!.createTotp.id,
          deviceName: form.deviceName,
          token: form.token,
        },
      },
    });

    if (result.data?.confirmTotp.status === MutationStatus.Success) {
      navigate("/account");
    }
  };

  const cancel = useCallback(() => {
    if (createData?.createTotp.id) {
      removeTotpMutation({ variables: { totpId: createData?.createTotp.id } });
    }
    navigate("/account");
  }, [navigate, createData?.createTotp.id, removeTotpMutation]);

  useMount(() => {
    createTotpMutation();
  });

  if (createLoading) {
    return <CircularProgress />;
  }

  if (createError || !createData) {
    return <p>An error happened while creating new secret</p>;
  }

  return (
    <>
      <Box mb={Spacing.m} display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h3" color="textPrimary">
          Account settings
        </Typography>
      </Box>
      <Box mb={Spacing.m} display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h4" color="textPrimary">
          New authenticator device
        </Typography>
      </Box>

      <Box mb={Spacing.m} display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h4" color="textPrimary">
          Please scan this QR code with your authenticator device.
        </Typography>
      </Box>

      <Box
        mb={Spacing.m}
        display="flex"
        justifyContent="center"
        sx={{ background: "#fff", padding: "3em" }}
      >
        <QRCode value={createData.createTotp.uri as string} />
      </Box>

      <Box mb={Spacing.m}>
        <Typography variant="body1" color="textPrimary">
          Or copy this code:
        </Typography>
        <Box />
        <Typography variant="body1">
          <code>{createData.createTotp.uri}</code>
        </Typography>
      </Box>

      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          fullWidth
          {...register("deviceName")}
          label="Device name"
          margin="normal"
          type="string"
          variant="outlined"
        />

        <TextField
          fullWidth
          {...register("token")}
          label="Token"
          margin="normal"
          name="token"
          type="string"
          variant="outlined"
        />

        <Box mb={Spacing.m} display="flex" alignItems="center" justifyContent="space-between">
          <Button variant="contained" onClick={cancel}>
            Cancel
          </Button>
          <LoadingButton
            color="secondary"
            fullWidth
            loading={confirmLoading}
            size="large"
            type="submit"
            variant="contained"
          >
            Verify and confirm
          </LoadingButton>
        </Box>
        {confirmError ? (
          <Box mt={Spacing.sm}>
            <Alert severity="error">{confirmError.message}</Alert>
          </Box>
        ) : null}
      </form>
    </>
  );
}
