import { Box, FormControl, FormLabel, TextField } from "@mui/material";
import React from "react";
import CopyLinkToElement from "src/components/Topics/CopyLinkToElement.tsx";
import { Spacing } from "src/types/enum";

/**
 * Types
 */
interface Props {
  value: string;
  setValue: (value: string) => void;
  id: string;
  topicId: string;
  lessonPath: string;
  step?: number | null;
}

const LessonEditPageSectionEmbedded: React.FC<Props> = ({
  value,
  setValue,
  id,
  topicId,
  lessonPath,
  step,
}: Props) => {
  return (
    <FormControl fullWidth>
      <Box mb={Spacing.s} display="flex" alignItems="center" justifyContent="space-between">
        <FormLabel>Paste HTML code</FormLabel>
        <CopyLinkToElement id={id} topicId={topicId} lessonPath={lessonPath} step={step} />
      </Box>
      <TextField
        fullWidth
        defaultValue={value}
        name="embedded"
        variant="outlined"
        onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
          setValue(target.value);
        }}
      />
    </FormControl>
  );
};

export default LessonEditPageSectionEmbedded;
