import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { Trash2 as DeleteIcon, Edit as EditIcon } from "react-feather";
import { Link as RouterLink } from "react-router-dom";

import useClipboard from "react-use-clipboard";

import {
  Box,
  Checkbox,
  IconButton,
  Link,
  SvgIcon,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { Spacing } from "../types/enum";
import { type ThemeModel } from "../types/graphql";

import { useOnOffSwitch } from "../helpers/hooks";

import ConfirmationModal from "./ConfirmationModal";
import Label from "./Label";

/**
 * Types
 */
interface Props {
  theme: ThemeModel;
  onDelete: () => void;
}

/**
 * Styles
 */
const useStyles = makeStyles({
  title: {
    maxWidth: "240px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});

const ThemesPageThemeDetails: React.FC<Props> = ({ theme, onDelete }: Props) => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [open, onOpen, onClose] = useOnOffSwitch();
  const [isCopied, setCopied] = useClipboard(theme?.id);

  useEffect(() => {
    if (isCopied) {
      enqueueSnackbar("ID successfully copied", { variant: "success" });
    }
  });

  return (
    <TableRow key={theme.id} hover>
      <TableCell padding="checkbox">
        <Checkbox />
      </TableCell>

      <TableCell>
        <Box display="flex" alignItems="center">
          <Box ml={Spacing.sm}>
            <Link color="inherit" component={RouterLink} to={`/theme/${theme?.id}/edit`}>
              <Typography variant="body2" className={classes.title}>
                {theme?.title}
              </Typography>
            </Link>
          </Box>
        </Box>
      </TableCell>

      <TableCell>
        <Label variant="info" onClick={setCopied}>
          {theme.id}
        </Label>
      </TableCell>

      <TableCell>
        <Box display="flex" alignItems="center">
          {theme?.isCurrent ? (
            <Label variant="success">Active</Label>
          ) : (
            <Label variant="error">Not Active</Label>
          )}
        </Box>
      </TableCell>

      <TableCell align="right">
        <Link component={RouterLink} to={`/theme/${theme?.id}/edit`}>
          <IconButton size="large">
            <SvgIcon fontSize="small">
              <EditIcon />
            </SvgIcon>
          </IconButton>
        </Link>
        <IconButton onClick={onOpen} size="large">
          <SvgIcon fontSize="small">
            <DeleteIcon />
          </SvgIcon>
        </IconButton>
      </TableCell>

      <ConfirmationModal open={open} onSubmit={onDelete} onClose={onClose} />
    </TableRow>
  );
};

export default ThemesPageThemeDetails;
