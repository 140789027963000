import { gql } from "@apollo/client";

export const CREATE_FLAG = gql`
  mutation adminCreateFeatureFlag($input: CreateFeatureFlagInput!) {
    adminCreateFeatureFlag(input: $input) {
      id
      label
      active
    }
  }
`;

export const EDIT_FLAG = gql`
  mutation adminEditFeatureFlag($id: String!, $input: EditFeatureFlagInput!) {
    adminEditFeatureFlag(id: $id, input: $input) {
      id
      label
      active
    }
  }
`;

export const DELETE_FLAG = gql`
  mutation adminDeleteFeatureFlag($id: String!) {
    adminDeleteFeatureFlag(id: $id) {
      reason
      status
    }
  }
`;
