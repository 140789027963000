import { useSnackbar } from "notistack";
import React, { useState, useEffect } from "react";

import {
  Box,
  Button,
  Card,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";

import { DEFAULT_OFFSET, ROWS_PER_PAGE_DEFAULT, ROWS_PER_PAGE_OPTIONS } from "../helpers/const";
import { useOnOffSwitch, usePagination } from "../helpers/hooks";

import { Spacing } from "../types/enum";
import {
  AdminListPaginatedBannersDocument,
  type BannerModel,
  useAdminDeleteBannerMutation,
  useAdminListPaginatedBannersQuery,
} from "../types/graphql";

import SlidersPageSliderDetails from "../components/SlidersPageSliderDetails";
import SlidersPageCreateCont from "../containers/SlidersPageCreateCont";

/**
 * Constants
 */
const NO_RESULTS = 0;

const SlidersPage: React.FC = () => {
  const [open, onOpen, onClose] = useOnOffSwitch();
  const [offset, setOffset] = useState<number>(DEFAULT_OFFSET);
  const [limit, setLimit] = useState<number>(ROWS_PER_PAGE_DEFAULT);
  const { enqueueSnackbar } = useSnackbar();

  const [deleteBanner] = useAdminDeleteBannerMutation({
    refetchQueries: [
      {
        query: AdminListPaginatedBannersDocument,
        variables: {
          filters: {
            limit,
            offset,
          },
        },
      },
    ],
  });

  const { data, loading } = useAdminListPaginatedBannersQuery({
    fetchPolicy: "network-only",
    variables: {
      filters: {
        limit,
        offset,
      },
    },
  });

  const { page, rowsPerPage, items, onChangePage, onChangeRowsPerPage } =
    usePagination<BannerModel>(data?.adminListPaginatedBanners?.items as BannerModel[]);

  useEffect(() => {
    setOffset(page * rowsPerPage);
  }, [page, rowsPerPage]);

  useEffect(() => {
    setLimit(rowsPerPage);
  }, [rowsPerPage]);

  const onDelete = async (id: string) => {
    const variables = {
      id,
    };

    try {
      await deleteBanner({ variables });
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return (
    <>
      <Box mb={Spacing.m} display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h3" color="textPrimary">
          Home Page Sliders
        </Typography>

        <Button variant="contained" color="secondary" onClick={onOpen}>
          Add Slider
        </Button>
      </Box>

      <Card>
        {loading ? (
          <Box display="flex" justifyContent="center" p={Spacing.xxl}>
            <CircularProgress />
          </Box>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Status</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items?.map((banner: BannerModel) => (
                <SlidersPageSliderDetails key={banner?.id} banner={banner} onDelete={onDelete} />
              ))}
            </TableBody>

            <TableFooter>
              <TableRow>
                <TablePagination
                  page={page}
                  count={data?.adminListPaginatedBanners.total ?? NO_RESULTS}
                  onPageChange={onChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={onChangeRowsPerPage}
                  rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                />
              </TableRow>
            </TableFooter>
          </Table>
        )}
      </Card>

      <SlidersPageCreateCont open={open} onClose={onClose} limit={limit} offset={0} />
    </>
  );
};

export default SlidersPage;
