import { useMutation } from "@apollo/client";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton, type Theme, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate } from "react-router";

import makeStyles from "@mui/styles/makeStyles";

import { DELETE_LESSON } from "../gql/mutations/lesson";
import { QUERY_TOPIC_THEMES_AND_ILLUSTRATIONS } from "../gql/queries/topic";

import { Spacing } from "../types/enum";

/**
 * Types
 */
interface Props {
  title?: string;
  lessonId: string;
  topicId: string;
}

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    justifyContent: "space-between",
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.action.disabled}`,
    "&:hover": {
      backgroundColor: theme.palette.background.dark,
    },
    "&:last-of-type": {
      marginBottom: "unset",
    },
  },
}));

const TopicEditPageLesson: React.FC<Props> = ({ title, lessonId, topicId }: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const [deleteLesson, { loading }] = useMutation(DELETE_LESSON, {
    refetchQueries: [{ query: QUERY_TOPIC_THEMES_AND_ILLUSTRATIONS, variables: { id: topicId } }],
  });

  const classes = useStyles();
  const history = useNavigate();

  const onEdit = () => {
    history(`/lesson/${lessonId}/edit`);
  };

  const onDelete = async () => {
    const variables = {
      id: lessonId,
    };

    try {
      await deleteLesson({ variables });
      enqueueSnackbar("Lesson successfully deleted", { variant: "success" });
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return (
    <Box p={Spacing.m} mb={Spacing.sm} className={classes.root} display="flex" alignItems="center">
      <Box display="flex" flexDirection="column">
        {title ? (
          <Typography variant="h5" color="textPrimary">
            {title}
          </Typography>
        ) : null}

        <Box mt={Spacing.s}>
          <Typography variant="body2" color="textSecondary">
            Click to edit or add details for the section.
          </Typography>
        </Box>
      </Box>

      <Box display="flex" alignItems="center">
        <IconButton onClick={onEdit} size="large">
          <EditIcon />
        </IconButton>
        <IconButton disabled={loading} onClick={onDelete} size="large">
          <DeleteIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default TopicEditPageLesson;
