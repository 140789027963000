import { Typography } from "@mui/material";
import { Spacing } from "../../types/enum";

const BulkInviteEmailsFormHeader: React.FC = () => {
  return (
    <>
      <Typography variant="body1" color="textPrimary" fontWeight="bold" mb={Spacing.s}>
        Bulk Invite
      </Typography>
      <Typography variant="body2" color="textPrimary" mb={Spacing.sm}>
        Copy and paste comma separated emails into the field below
      </Typography>
    </>
  );
};

export default BulkInviteEmailsFormHeader;
