import React from "react";
import { useParams } from "react-router";
import { type SliderModel, useAdminGetSlideQuery } from "src/types/graphql";

import EditSlideFormCont from "../containers/EditSlideFormCont";

const SlidePage: React.FC = () => {
  const params = useParams();

  const { data, loading } = useAdminGetSlideQuery({
    fetchPolicy: "network-only",
    variables: {
      id: params?.slideId ?? "",
    },
  });

  if (data?.adminGetSlide) {
    return (
      <EditSlideFormCont
        slide={data.adminGetSlide as SliderModel}
        slideNumber={Number(params?.slideNo)}
        loading={loading}
      />
    );
  }

  return <></>;
};

export default SlidePage;
