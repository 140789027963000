import { Box, Card, CardHeader, CircularProgress, Divider, Table, TableBody } from "@mui/material";
import { format } from "date-fns";
import React from "react";
import { useParams } from "react-router";

import { EventKind, useGetEventAdminQuery } from "../types/graphql";

import EntityPageDetailsCardRow from "../components/EntityPageDetailsCardRow";
import Label from "../components/Label";

/**
 * Constants
 */
const kinds = new Map<EventKind, string>([
  [EventKind.ConversationStarter, "Conversation Starter"],
  [EventKind.GeneralEvent, "General Event"],
  [EventKind.GrammarSpot, "Grammar Spot"],
  [EventKind.Interview, "Interview"],
  [EventKind.NewMemberMeetup, "New Member Meetup"],
  [EventKind.OfficeHours, "Office Hours"],
  [EventKind.OneToOne, "One To One"],
  [EventKind.TeacherTalk, "Teacher Talk"],
  [EventKind.GroupEvent, "Group Event"],
]);

const EventDetailsPage: React.FC = () => {
  const { eventId } = useParams<Record<string, string>>();

  const { data, loading } = useGetEventAdminQuery({
    fetchPolicy: "network-only",
    variables: {
      id: eventId ?? "",
    },
  });

  if (loading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  const event = data?.getEventAdmin;

  const renderers = {
    chip: (condition: boolean | null | undefined) => {
      if (condition) {
        return <Label variant="success">Yes</Label>;
      }
      return <Label variant="error">No</Label>;
    },
    date: (date: Date) => {
      if (date) {
        return <Label variant="info">{format(date, "EEEE, LLL Mo, yyyy")}</Label>;
      }
      return null;
    },
  };

  return (
    <Card>
      <CardHeader title="Event info" />
      <Divider />
      <Table>
        <TableBody>
          <EntityPageDetailsCardRow title="Title" content={event?.title} />
          <EntityPageDetailsCardRow
            title="ID"
            content={<Label variant="info">{event?.id}</Label>}
          />
          <EntityPageDetailsCardRow title="Organizer" content={event?.organizer?.name} />
          <EntityPageDetailsCardRow title="Start Date" content={renderers.date(event?.startDate)} />
          <EntityPageDetailsCardRow title="End Date" content={renderers.date(event?.endDate)} />
          <EntityPageDetailsCardRow
            title="Kind"
            content={kinds.get(event?.kind || EventKind.ConversationStarter)}
          />
          <EntityPageDetailsCardRow
            title="Zoom meeting start url"
            content={event?.meta?.startURL}
          />
          <EntityPageDetailsCardRow title="Zoom meeting join url" content={event?.meta?.joinURL} />
          <EntityPageDetailsCardRow title="Location" content={event?.location} />
          <EntityPageDetailsCardRow title="Online" content={renderers.chip(event?.isOnline)} />
          <EntityPageDetailsCardRow title="Public" content={renderers.chip(event?.isPublic)} />
        </TableBody>
      </Table>
    </Card>
  );
};

export default EventDetailsPage;
