import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import { isNil, not } from "ramda";
import React from "react";
import {
  Controller,
  type ControllerRenderProps,
  type UseFormStateReturn,
  useForm,
} from "react-hook-form";
import * as yup from "yup";

import {
  Button,
  CardContent,
  FormControl,
  Grid,
  LinearProgress,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import { EDIT_THEME } from "../gql/mutations/theme";

import { Sizing, Spacing } from "../types/enum";
import { type CoverModel, type ThemeModel } from "../types/graphql";

import { type InferType } from "yup";
import Autocomplete from "../components/Autocomplete";
import Card from "../components/Card";

/**
 * Types
 */
interface Props {
  theme: ThemeModel;
  covers: CoverModel[];
}

/**
 * Schema
 */
const schema = yup.object().shape({
  isCurrent: yup.bool().required(),
  isTopicLibrary: yup.bool().required(),
  isGrammarLibrary: yup.bool().required(),
  isResource: yup.bool().required(),
  isStandard: yup.bool().required(),
  description: yup.string().required(),
  title: yup.string().required().required(),
  cover: yup
    .object()
    .shape({
      id: yup.string().required(),
      title: yup.string().required(),
    })
    .required(),
});
type FormValues = InferType<typeof schema>;

const ThemeEditPageFormCont: React.FC<Props> = ({ theme, covers }: Props) => {
  const [editTheme, { loading }] = useMutation(EDIT_THEME);

  const defaultValues = {
    title: theme.title,
    description: theme.description ?? undefined,
    cover: { id: theme.cover?.id ?? "", title: theme.cover?.title ?? "" },
    isCurrent: theme.isCurrent ?? false,
    isTopicLibrary: theme.isTopicLibrary ?? false,
    isGrammarLibrary: theme.isGrammarLibrary ?? false,
    isResource: theme.isResource ?? false,
    isStandard: theme.isStandard ?? false,
  };
  const { register, control, formState, handleSubmit } = useForm<FormValues>({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { enqueueSnackbar } = useSnackbar();

  const renderers = {
    autocomplete: ({
      field: { value, onChange },
      formState,
    }: {
      field: ControllerRenderProps<FormValues, "cover">;
      formState: UseFormStateReturn<FormValues>;
    }) => {
      const error = not(isNil(formState?.errors.cover));

      return (
        <Autocomplete
          label="Cover"
          options={covers}
          value={value}
          error={error}
          helperText={error ? "This field is required" : undefined}
          onChange={onChange}
        />
      );
    },
    switch: <TName extends keyof FormValues>({
      field: { value, onChange },
    }: {
      field: ControllerRenderProps<FormValues, TName>;
    }) => (
      <Switch
        edge="start"
        checked={!!value}
        color="secondary"
        onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
          onChange(target.checked);
        }}
      />
    ),
  };

  const onSubmit = async (form: any): Promise<void> => {
    const variables = {
      id: theme?.id,
      input: {
        title: form?.title,
        description: form?.description,
        coverId: form?.cover?.id,
        isCurrent: form?.isCurrent,
        isTopicLibrary: form?.isTopicLibrary,
        isGrammarLibrary: form?.isGrammarLibrary,
        isResource: form?.isResource,
        isStandard: form?.isStandard,
      },
    };

    try {
      await editTheme({ variables });
      enqueueSnackbar("Theme successfully updated", { variant: "success" });
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return (
    <Card overflow>
      {loading ? <LinearProgress /> : null}

      <CardContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid spacing={Spacing.m} container>
            <Grid item xs={Sizing.Full}>
              <FormControl fullWidth>
                <TextField
                  label="Title"
                  variant="outlined"
                  {...register("title")}
                  error={not(isNil(formState?.errors?.title))}
                  helperText={
                    not(isNil(formState?.errors?.title)) ? "This field is required" : undefined
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={Sizing.Full}>
              <FormControl fullWidth>
                <TextField
                  label="Description"
                  variant="outlined"
                  {...register("description")}
                  error={not(isNil(formState?.errors?.description))}
                  helperText={
                    not(isNil(formState?.errors?.description))
                      ? "This field is required"
                      : undefined
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={Sizing.Full}>
              <FormControl fullWidth>
                <Controller name="cover" control={control} render={renderers.autocomplete} />
              </FormControl>
            </Grid>

            <Grid item xs={Sizing.OneFourth}>
              <Typography variant="h5" color="textPrimary">
                Standard
              </Typography>
              <Typography variant="body2" color="textSecondary">
                This will mark theme as standard
              </Typography>
              <Controller name="isStandard" control={control} render={renderers.switch} />
            </Grid>

            <Grid item xs={Sizing.Full} md={Sizing.OneFourth}>
              <Typography variant="h5" color="textPrimary">
                Topic Library
              </Typography>
              <Typography variant="body2" color="textSecondary">
                This will mark theme as topic library
              </Typography>
              <Controller name="isTopicLibrary" control={control} render={renderers.switch} />
            </Grid>

            <Grid item xs={Sizing.Full} md={Sizing.OneFourth}>
              <Typography variant="h5" color="textPrimary">
                Grammar
              </Typography>
              <Typography variant="body2" color="textSecondary">
                This will mark theme as grammar
              </Typography>
              <Controller name="isGrammarLibrary" control={control} render={renderers.switch} />
            </Grid>

            <Grid item xs={Sizing.Full} md={Sizing.OneFourth}>
              <Typography variant="h5" color="textPrimary">
                Resource
              </Typography>
              <Typography variant="body2" color="textSecondary">
                This will mark theme as resource
              </Typography>
              <Controller name="isResource" control={control} render={renderers.switch} />
            </Grid>

            <Grid item xs={Sizing.Full} md={Sizing.OneFourth}>
              <Typography variant="h5" color="textPrimary">
                Active
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Show this theme on topics page
              </Typography>
              <Controller name="isCurrent" control={control} render={renderers.switch} />
            </Grid>

            <Grid item xs={Sizing.Full}>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                fullWidth
                disabled={loading || not(formState.isDirty) || not(formState.isValid)}
              >
                Update theme
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export default ThemeEditPageFormCont;
