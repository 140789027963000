import React from "react";
import * as yup from "yup";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormLabel,
  TextField,
  Typography,
} from "@mui/material";

import CopyLinkToElement from "src/components/Topics/CopyLinkToElement.tsx";
import { useOnOffSwitch, useSortableList } from "../helpers/hooks";
import { Spacing } from "../types/enum";
import { type IdeaModel } from "../types/generic";

/**
 * Types
 */

interface Props {
  value: IdeaModel[];
  setValue: (ideas: IdeaModel[]) => void;
  id: string;
  topicId: string;
  lessonPath: string;
  step?: number | null;
}

/**
 * Schema
 */
const schema = yup.object().shape({
  idea: yup.string().required(),
});

const LessonEditPageSectionIdeas: React.FC<Props> = ({
  value,
  setValue,
  id,
  topicId,
  lessonPath,
  step,
}: Props) => {
  const [open, onOpen, onClose] = useOnOffSwitch();

  const { renderer, register, handleSubmit, onSubmit } = useSortableList<IdeaModel>({
    schema,
    data: value,
    render: (item) => {
      return (
        <Box display="flex" flexDirection="column" key={item.idea}>
          <Typography variant="body2" color="textPrimary">
            {item.idea}
          </Typography>
        </Box>
      );
    },
    onAfterSubmit: onClose,
    setValue,
    keyFn: (item) => item.idea,
  });

  return (
    <FormControl fullWidth>
      <Box mb={Spacing.s} display="flex" alignItems="center" justifyContent="space-between">
        <FormLabel>Ideas</FormLabel>
        <CopyLinkToElement id={id} topicId={topicId} lessonPath={lessonPath} step={step} />
      </Box>

      {renderer}

      <Box mt={Spacing.s}>
        <Button fullWidth variant="contained" onClick={onOpen}>
          Create idea
        </Button>
      </Box>

      <Dialog open={open} onClose={onClose}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Box mb={Spacing.sm}>
              <Typography variant="body1" color="textPrimary">
                Idea
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Please input the idea that you would like to add.
              </Typography>
            </Box>

            <TextField fullWidth variant="outlined" label="Idea" {...register("idea")} />
          </DialogContent>

          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="submit">Create</Button>
          </DialogActions>
        </form>
      </Dialog>
    </FormControl>
  );
};

export default LessonEditPageSectionIdeas;
