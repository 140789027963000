import { gql } from "@apollo/client";

export const CREATE_COVER = gql`
  mutation adminCreateCover($input: CreateCoverInput!) {
    adminCreateCover(input: $input) {
      id
      title
      primary {
        mobile
        desktop
      }
      secondary {
        mobile
        desktop
      }
      tertiary {
        mobile
        desktop
      }
    }
  }
`;

export const EDIT_COVER = gql`
  mutation adminEditCover($id: String!, $input: EditCoverInput!) {
    adminEditCover(id: $id, input: $input) {
      id
      title
      primary {
        mobile
        desktop
      }
      secondary {
        mobile
        desktop
      }
      tertiary {
        mobile
        desktop
      }
    }
  }
`;

export const DELETE_COVER = gql`
  mutation adminDeleteCover($id: String!) {
    adminDeleteCover(id: $id) {
      status
    }
  }
`;
