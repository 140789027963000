import { useQuery } from "@apollo/client";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, CircularProgress, Link, Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router";
import { Link as RouterLink } from "react-router-dom";

import { QUERY_FLAG } from "../gql/queries/flag";

import FlagEditFormCont from "../containers/FlagEditFormCont";
import { truncateWithEllipses } from "../helpers/utils";
import { Spacing } from "../types/enum";

const FlagEditPage: React.FC = () => {
  const params = useParams<Record<string, string>>();

  const { data, loading } = useQuery(QUERY_FLAG, {
    fetchPolicy: "network-only",
    variables: {
      id: params?.flagId,
    },
  });

  if (loading) {
    return <CircularProgress />;
  }

  const flag = data?.adminGetFeatureFlag;

  return (
    <>
      <Box display="flex" alignItems="center" mb={Spacing.m}>
        <Link color="inherit" component={RouterLink} to="/flags">
          <Box mr={Spacing.sm}>
            <ArrowBackIcon />
          </Box>
        </Link>

        <Typography variant="h3" color="textPrimary">
          {truncateWithEllipses(flag?.label)}
        </Typography>

        <Typography variant="h2" color="textPrimary">
          {flag?.active}
        </Typography>
      </Box>

      <FlagEditFormCont flag={flag} />
    </>
  );
};

export default FlagEditPage;
