import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import { isNil, not } from "ramda";
import React, { useEffect } from "react";
import {
  Controller,
  type ControllerRenderProps,
  type SubmitHandler,
  type UseFormStateReturn,
  useForm,
} from "react-hook-form";
import * as yup from "yup";

import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import { Sizing, Spacing } from "../types/enum";
import { type AdminGetArticleQuery, useAdminEditArticleMutation } from "../types/graphql";

import Autocomplete from "../components/Autocomplete";

/**
 * Types
 */
interface Props {
  article: AdminGetArticleQuery["adminGetArticle"];
  users: AdminGetArticleQuery["adminListUsers"]["items"];
}

/**
 * Schema
 */
const schema = yup.object().shape({
  title: yup.string().required(),
  author: yup
    .object()
    .shape({
      id: yup.string().required(),
      name: yup.string().required(),
    })
    .required(),
  isDraft: yup.boolean().required(),
  isFeatured: yup.boolean().required(),
  isVisible: yup.boolean().required(),
});
type FormValues = yup.InferType<typeof schema>;

const ArticlesEditFormCont: React.FC<Props> = ({ article, users }: Props) => {
  const [editArticle, { loading }] = useAdminEditArticleMutation();

  const defaultValues = {
    title: article.title,
    author: article.author && { id: article.author.id, name: article.author.name },
    isDraft: article.isDraft,
    isFeatured: article.isFeatured,
    isVisible: article.isVisible,
  };
  const { register, control, formState, handleSubmit, trigger } = useForm<FormValues>({
    mode: "all",
    resolver: yupResolver(schema),
    // @ts-expect-error graphql types have to be non nullable
    defaultValues,
  });

  useEffect(() => {
    void trigger();
  }, [trigger]);

  const { enqueueSnackbar } = useSnackbar();

  const renderers = {
    switch: <TName extends keyof FormValues>({
      field: { value, onChange },
    }: {
      field: ControllerRenderProps<FormValues, TName>;
    }) => (
      <Switch
        edge="start"
        checked={!!value}
        color="secondary"
        onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
          onChange(target.checked);
        }}
      />
    ),

    author: ({
      field: { value, onChange },
      formState,
    }: {
      field: ControllerRenderProps<FormValues, "author">;
      formState: UseFormStateReturn<FormValues>;
    }) => {
      const error = not(isNil(formState?.errors?.author));
      return (
        <Autocomplete
          label="Author"
          options={users!}
          value={value}
          onChange={onChange}
          error={error}
          helperText={error ? "This field is required" : undefined}
        />
      );
    },
  };

  const onSubmit: SubmitHandler<FormValues> = async (form) => {
    const variables = {
      id: article?.id,
      input: {
        title: form?.title,
        authorId: form?.author?.id,
        isFeatured: form?.isFeatured,
        isVisible: form?.isVisible,
        isDraft: form?.isDraft,
      },
    };

    try {
      await editArticle({ variables });
      enqueueSnackbar("Article successfully updated", { variant: "success" });
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return (
    <Card>
      <CardContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid spacing={Spacing.m} container>
            <Grid item xs={Sizing.Full}>
              <FormControl fullWidth>
                <TextField
                  label="Title"
                  variant="outlined"
                  {...register("title")}
                  error={not(isNil(formState?.errors?.title))}
                  helperText={
                    not(isNil(formState?.errors?.title)) ? "This field is required" : undefined
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={Sizing.Full}>
              <FormControl fullWidth>
                <Controller name="author" control={control} render={renderers.author} />
              </FormControl>
            </Grid>

            <Grid item xs={Sizing.OneFourth}>
              <Typography variant="h5" color="textPrimary">
                Featured
              </Typography>
              <Typography variant="body2" color="textSecondary">
                This will mark the article as featured
              </Typography>
              <Controller name="isFeatured" control={control} render={renderers.switch} />
            </Grid>

            <Grid item xs={Sizing.OneFourth}>
              <Typography variant="h5" color="textPrimary">
                Show or hide
              </Typography>
              <Typography variant="body2" color="textSecondary">
                This will mark the article as visible
              </Typography>
              <Controller name="isVisible" control={control} render={renderers.switch} />
            </Grid>

            <Grid item xs={Sizing.OneThird}>
              <Typography variant="h5" color="textPrimary">
                Draft
              </Typography>
              <Typography variant="body2" color="textSecondary">
                This will mark the article as draft
              </Typography>
              <Controller name="isDraft" control={control} render={renderers.switch} />
            </Grid>

            <Grid item xs={Sizing.Full}>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                disabled={not(formState.isValid) || loading}
                fullWidth
              >
                Update article
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export default ArticlesEditFormCont;
