import { yupResolver } from "@hookform/resolvers/yup";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  FormLabel,
  Grid,
  IconButton,
  Link,
  SvgIcon,
  Switch,
  type Theme,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useSnackbar } from "notistack";
import { omit } from "ramda";
import React, { useEffect, useMemo } from "react";
import { Controller, type ControllerRenderProps, useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import {
  AdminGetVideoSetDocument,
  AdminListPaginatedVideoSetsDocument,
  Audience,
  ColourCode,
  type VideoSetModel,
  useAdminDeleteVideoSetMutation,
  useAdminEditVideoMutation,
  useAdminEditVideoSetMutation,
} from "src/types/graphql";
import * as yup from "yup";

import { DEFAULT_OFFSET, ROWS_PER_PAGE_DEFAULT } from "../helpers/const";
import { useOnOffSwitch } from "../helpers/hooks";
import { Sizing, Spacing } from "../types/enum";

import { type InferType } from "yup";
import ConfirmationModal from "../components/ConfirmationModal";
import VideosPageCreateUpdateVideoSetCont from "./VideosPageCreateUpdateVideoSetCont";
import VideosPageCreateVideoCont from "./VideosPageCreateVideoCont";
import VideosPageEditVideoCont from "./VideosPageEditVideoCont";

/**
 * Types
 */
interface Props {
  videoSet: VideoSetModel;
  loading: boolean;
}

/**
 * Schema
 */
const schema = yup.object().shape({
  videoSetIsActive: yup.bool().required(),
  videos: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string().required(),
        title: yup.string().required(),
        videoURL: yup.string().nullable(),
        thumbnail: yup.string().nullable(),
        isActive: yup.bool().required(),
      }),
    )
    .required(),
  zero: yup.bool(),
  oneMonthPlus: yup.bool(),
  newMembers: yup.bool(),
});
type FormValues = InferType<typeof schema>;

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) => ({
  delete: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
  stretched: {
    height: "100%",
  },
}));

const VideosPageEditVideoSetCont: React.FC<Props> = ({ videoSet, loading }: Props) => {
  const classes = useStyles();

  const [isOpen, open, close] = useOnOffSwitch();
  const [isOpenCreate, onOpenCreate, onCloseCreate] = useOnOffSwitch();
  const [isEditTitle, onOpenEditTitle, onCloseEditTitle] = useOnOffSwitch();
  const history = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [deleteVideoSet] = useAdminDeleteVideoSetMutation({
    refetchQueries: [
      {
        query: AdminListPaginatedVideoSetsDocument,
        variables: {
          filters: {
            limit: ROWS_PER_PAGE_DEFAULT,
            offset: DEFAULT_OFFSET,
          },
        },
      },
    ],
  });

  const [editVideoSet] = useAdminEditVideoSetMutation({
    refetchQueries: [
      {
        query: AdminGetVideoSetDocument,
        variables: {
          id: videoSet.id,
        },
      },
    ],
  });

  const [editVideo] = useAdminEditVideoMutation();

  const defaultValues = useMemo(() => {
    return {
      videoSetIsActive: videoSet.isActive,
      videos: (videoSet.videos ?? []).map((video) => ({
        id: video.id,
        title: video.title!,
        thumbnail: video.thumbnail!,
        videoURL: video.videoURL!,
        isActive: Boolean(video.isActive),
      })),
      zero: videoSet.audience?.some((audience) => audience === Audience.Zero),
      oneMonthPlus: videoSet.audience?.some((audience) => audience === Audience.OneMonthPlus),
      newMembers: videoSet.audience?.some((audience) => audience === Audience.NewMembers),
    };
  }, [videoSet]);

  const { control, handleSubmit, register, reset } = useForm<FormValues>({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { fields } = useFieldArray({
    name: "videos",
    control,
    keyName: "fieldId",
  });

  const onSubmit = async (form: FormValues): Promise<void> => {
    const audience: Audience[] = [];

    if (form?.zero) {
      audience.push(Audience.Zero);
    }

    if (form?.oneMonthPlus) {
      audience.push(Audience.OneMonthPlus);
    }

    if (form?.newMembers) {
      audience.push(Audience.NewMembers);
    }

    const variables = {
      id: videoSet.id,
      input: {
        isActive: form?.videoSetIsActive,
        audience,
      },
    };

    try {
      await Promise.all([
        editVideoSet({ variables }),
        ...form.videos.map((video) => {
          const variables = {
            id: video.id,
            input: omit(["id"], video),
          };
          return editVideo({ variables });
        }),
      ]);
      enqueueSnackbar("Video set was updated successfully", { variant: "success" });
    } catch (_error) {
      enqueueSnackbar("There was a problem updating this video set", { variant: "error" });
    }
  };

  const onDelete = async () => {
    const variables = {
      id: videoSet.id,
    };

    try {
      await deleteVideoSet({ variables });
      history("/videos");
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const renderers = {
    switch: ({
      field: { value, onChange },
    }: {
      field: ControllerRenderProps<any>;
    }) => {
      return (
        <Switch
          edge="start"
          checked={value}
          color="secondary"
          onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
            onChange(target.checked);
          }}
        />
      );
    },
  };

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Box mb={Spacing.m}>
              <Box display="flex" alignItems="center">
                <Link color="inherit" component={RouterLink} to={"/videos"}>
                  <Box mr={Spacing.sm}>
                    <ArrowBackIcon />
                  </Box>
                </Link>
                <Box ml={Spacing.sm} display="flex" alignItems="center">
                  <Typography variant="h3" color="textPrimary">
                    {videoSet.title}
                  </Typography>
                  <Box ml={Spacing.m}>
                    <IconButton size="large" onClick={onOpenEditTitle}>
                      <SvgIcon fontSize="small">
                        <EditIcon />
                      </SvgIcon>
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item>
            <Box display="flex" flexDirection="row">
              <Box>
                <Button
                  className={classes.delete}
                  onClick={open}
                  variant="contained"
                  color="secondary"
                >
                  Delete
                </Button>
              </Box>
              <Box ml={Spacing.sm}>
                <Button variant="contained" color="secondary" type="submit" disabled={loading}>
                  Save
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={Spacing.m}>
          <Grid item xs={Sizing.Full} md={Sizing.TwoThirds}>
            {fields?.map((video: any, index) => (
              <Box mb={Spacing.sm} key={video.id}>
                <VideosPageEditVideoCont
                  key={video.id}
                  videoSetID={videoSet.id}
                  video={video}
                  colour={videoSet.colour as ColourCode}
                  register={register}
                  control={control}
                  index={index}
                  total={videoSet.videos?.length ?? 0}
                />
              </Box>
            ))}
            <Grid item xs={Sizing.Full}>
              <Button variant="contained" color="secondary" fullWidth onClick={onOpenCreate}>
                Add Video
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={Sizing.Full} md={Sizing.OneThird}>
            <Card className={classes.stretched}>
              <Box p={Spacing.m}>
                <Typography variant="h5" color="textPrimary">
                  Video Set Settings
                </Typography>

                <Box display="flex" justifyContent="space-between" my={Spacing.m}>
                  <Box>
                    <Typography variant="h5" color="textPrimary">
                      Active
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Display this set on homepage
                    </Typography>
                  </Box>

                  <Controller name="videoSetIsActive" control={control} render={renderers.switch} />
                </Box>
                <Box my={Spacing.m}>
                  <Typography variant="h5" color="textPrimary">
                    Audience
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Who should see this videos
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <FormLabel>0-1 month</FormLabel>
                  <Controller name="zero" control={control} render={renderers.switch} />
                </Box>
                <Box>
                  <Box display="flex" justifyContent="space-between">
                    <FormLabel>1 month+</FormLabel>
                    <Controller name="oneMonthPlus" control={control} render={renderers.switch} />
                  </Box>
                  <Box display="flex" justifyContent="space-between">
                    <FormLabel>New Members</FormLabel>
                    <Controller name="newMembers" control={control} render={renderers.switch} />
                  </Box>
                </Box>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </form>

      <ConfirmationModal open={isOpen} onClose={close} onSubmit={onDelete} />

      <VideosPageCreateUpdateVideoSetCont
        open={isEditTitle}
        onClose={onCloseEditTitle}
        videoSetId={videoSet.id}
        videoSetTitle={videoSet.title}
        videoSetColour={videoSet.colour as ColourCode}
      />

      {isOpenCreate ? (
        <VideosPageCreateVideoCont
          open={isOpenCreate}
          onClose={onCloseCreate}
          videoSetId={videoSet.id}
        />
      ) : null}
    </>
  );
};

export default VideosPageEditVideoSetCont;
