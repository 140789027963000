import {
  Box,
  Card,
  CardHeader,
  Chip,
  CircularProgress,
  Divider,
  Link,
  Table,
  TableBody,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { getTimezoneOffset } from "date-fns-tz";
import { isEmpty, isNil, not } from "ramda";
import React from "react";

import { Spacing } from "../types/enum";
import { type CbPlanDetails, EnglishLevel, type UserModel } from "../types/graphql";

import { INTERESTS_NAMES, USER_BADGES, USER_PERMISSIONS } from "../helpers/const";

import { isThirdPartyTesting } from "../helpers/utils";
import EntityPageDetailsCardRow from "./EntityPageDetailsCardRow";

/**
 * Types
 */
interface Props {
  user?: UserModel;
  planDetails?: CbPlanDetails;
  loading: boolean;
}

const UserPageDetailsInfoCard: React.FC<Props> = ({ user, planDetails, loading }: Props) => {
  const renderers = {
    subscription: () => {
      if (not(user?.hasActivePlan)) {
        return <>Missing subscription</>;
      }

      return (
        <Box>
          <Typography variant="body2" color="textSecondary" component="div">
            {planDetails?.name}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="div">
            {planDetails?.amount}
          </Typography>

          <Link
            href={`https://heylady${
              isThirdPartyTesting() ? "-test" : ""
            }.chargebee.com/d/subscriptions/${planDetails?.subscriptionChargebeeId}`}
          >
            <Typography variant="body2" color="textPrimary">
              Chargebee subscription
            </Typography>
          </Link>
        </Box>
      );
    },
    customer: () => {
      if (not(user?.hasActivePlan)) {
        return <>Missing subscription</>;
      }

      return (
        <Box>
          <Link
            href={`https://heylady${
              isThirdPartyTesting() ? "-test" : ""
            }.chargebee.com/d/customers/${planDetails?.customerChargebeeId}`}
          >
            <Typography variant="body2" color="textPrimary">
              Chargebee customer
            </Typography>
          </Link>
        </Box>
      );
    },
    name: () => {
      return (
        <>
          {user?.firstName} {user?.lastName}
        </>
      );
    },
    timezone: () => {
      if (isNil(user?.timezone) || isEmpty(user?.timezone)) {
        return "Not collected yet";
      }

      const offset = getTimezoneOffset(user?.timezone ?? "UTC") / (60 * 60 * 1000);
      return offset < 0 ? `UTC${offset} (${user?.timezone})` : `UTC+${offset} (${user?.timezone})`;
    },
    badge: () => {
      return (
        <>{user?.badge ? USER_BADGES.get(user?.badge) : format(user?.createdAt, "LLL yyyy")}</>
      );
    },
    permission: () => {
      return <>{user?.kind ? USER_PERMISSIONS.get(user?.kind) : "No permission selected"}</>;
    },
    cambridgeEnglishLevel: () => {
      const englishLevel = user?.cambridgeEnglishLevel;
      const cambridgeEnglishLevels = Object.values(EnglishLevel);
      const cambridgeEnglishLevel =
        typeof englishLevel === "number" ? cambridgeEnglishLevels[englishLevel] : undefined;
      return <>{cambridgeEnglishLevel}</>;
    },
    email: () => {
      return (
        <>
          {user?.email}
          <Box mt={Spacing.s}>
            {user?.isActive ? (
              <Chip variant="outlined" label="Verified" />
            ) : (
              <Chip variant="outlined" label="Not Verified" />
            )}{" "}
            {user?.hasPassword ? (
              <Chip variant="outlined" label="Password set" />
            ) : (
              <Chip variant="outlined" label="Doesn't have a password" />
            )}
          </Box>
        </>
      );
    },

    interests: () => {
      const interests = user?.interests?.map((interest) => {
        return `${INTERESTS_NAMES.get(interest)}, `;
      });

      let last = interests?.pop() ?? "";
      last = `${last.slice(0, last.length - 2)}.`;
      interests?.push(last);

      return interests;
    },
  };

  if (loading) {
    return <CircularProgress />;
  }
  return (
    <Card>
      <CardHeader title="Customer info" />
      <Divider />
      <Table>
        <TableBody>
          <EntityPageDetailsCardRow title="ID" content={user?.id} />
          <EntityPageDetailsCardRow title="Name" content={renderers.name()} />
          <EntityPageDetailsCardRow title="Staff Verified" content={user?.isVerified} />
          <EntityPageDetailsCardRow title="Badge" content={renderers.badge()} />
          <EntityPageDetailsCardRow title="Permission" content={renderers.permission()} />
          <EntityPageDetailsCardRow title="Active" content={user?.isActive} />
          <EntityPageDetailsCardRow title="Email" content={renderers.email()} />
          <EntityPageDetailsCardRow title="Country" content={user?.country} />
          <EntityPageDetailsCardRow title="City" content={user?.city} />
          <EntityPageDetailsCardRow title="Timezone" content={renderers.timezone()} />
          <EntityPageDetailsCardRow title="Subscription" content={renderers.subscription()} />
          <EntityPageDetailsCardRow title="Customer" content={renderers.customer()} />
          <EntityPageDetailsCardRow
            title="CEFR Level"
            content={renderers.cambridgeEnglishLevel()}
          />
          <EntityPageDetailsCardRow title="Nationality" content={user?.nationality} />
          <EntityPageDetailsCardRow title="Native Language" content={user?.nativeLanguage} />
          <EntityPageDetailsCardRow title="Other Language" content={user?.otherLanguage} />
          <EntityPageDetailsCardRow title="Story" content={user?.story} />
          <EntityPageDetailsCardRow title="Interests" content={renderers.interests()} />
          <EntityPageDetailsCardRow
            title="Completed profile"
            content={`${user?.completedPercentage}%`}
          />
        </TableBody>
      </Table>
    </Card>
  );
};

export default UserPageDetailsInfoCard;
