import { gql } from "@apollo/client";

export const QUERY_ARTICLE = gql`
  query adminGetArticle($id: String!, $filters: AdminFilterUsersInput!) {
    adminGetArticle(articleID: $id) {
      id
      title
      content
      coverUrl
      isFeatured
      tags
      isVisible
      isDraft
      author {
        id
        name
      }
    }
    adminListUsers(filters: $filters) {
      items {
        id
        name
      }
      total
    }
  }
`;

export const QUERY_ARTICLES = gql`
  query adminListPaginatedArticles($order: OrderArticlesInput!, $filters: FilterArticlesInput!) {
    adminListPaginatedArticles(order: $order, filters: $filters) {
      items {
        id
        title
        content
        coverUrl
        isFeatured
        publishedAt
        tags
        isVisible
        isDraft
        author {
          id
          name
        }
      }
      total
    }
  }
`;
