import { Typography } from "@mui/material";
import { Spacing } from "../../types/enum";

/**
 * Types
 */
interface Props {
  number: number;
}

const BulkInviteConfirmationMessage: React.FC<Props> = ({ number }: Props) => {
  return (
    <>
      <Typography variant="body2" color="textPrimary" mb={Spacing.sm}>
        <Typography component="span" variant="body2" fontWeight="bold">
          Are you sure?{" "}
        </Typography>
        You are about to invite{" "}
        <Typography component="span" variant="body2" fontWeight="bold">
          {number} participants
        </Typography>{" "}
        to this event. This cannot be undone.
      </Typography>
    </>
  );
};

export default BulkInviteConfirmationMessage;
