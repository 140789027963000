import React, { useCallback, useState } from "react";
import { useDebounce } from "react-use";

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSnackbar } from "notistack";
import { DEBOUNCE_TIME, DEFAULT_OFFSET } from "../helpers/const";
import { Spacing } from "../types/enum";
import { type TopicModel, useAdminListPaginatedTopicsLazyQuery } from "../types/graphql";

/**
 * Types
 */
interface Props {
  topicId: string;
  setTopicId: (topicId: string) => void;
}

const limit = 12;

const SelectTopic: React.FC<Props> = ({ topicId, setTopicId }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [text, setText] = useState("");
  const [offset, setOffset] = useState<number>(DEFAULT_OFFSET);

  const [fetchTopics, { data, loading, fetchMore }] = useAdminListPaginatedTopicsLazyQuery({
    fetchPolicy: "network-only",
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    },
  });

  const total = data?.adminListPaginatedTopics?.total ?? 0;
  const topics = data?.adminListPaginatedTopics?.items ?? [];

  useDebounce(
    () => {
      if (text) {
        fetchTopics({
          variables: {
            filters: {
              excludeIds: [topicId],
              text,
              limit,
              offset,
            },
          },
        });
      }
    },
    DEBOUNCE_TIME,
    [text],
  );

  const handleChange = (event: any) => {
    setText(event.target.value);
    if (!event.target.value) {
      setOffset(DEFAULT_OFFSET);
    }
  };

  const nextPage = useCallback(() => {
    if (total > offset + limit) {
      const newOffset = offset + limit;
      setOffset(newOffset);
      fetchMore({
        variables: {
          filters: {
            excludeIds: [topicId],
            text,
            limit,
            offset: newOffset,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return {
            adminListPaginatedTopics: {
              ...fetchMoreResult.adminListPaginatedTopics,
              items: [
                ...(prev.adminListPaginatedTopics?.items ?? []),
                ...(fetchMoreResult.adminListPaginatedTopics?.items ?? []),
              ],
            },
          };
        },
      });
    }
  }, [offset, total, fetchMore, text, topicId]);

  return (
    <>
      <Box my={Spacing.m}>
        <TextField
          fullWidth
          label="Search for topic by name or ID"
          variant="outlined"
          value={text}
          onChange={handleChange}
        />
      </Box>

      {loading && offset === 0 ? (
        <Box display="flex" justifyContent="center" mt={Spacing.xl}>
          <CircularProgress />
        </Box>
      ) : null}

      <Table>
        <TableBody>
          {topics.map((topic: TopicModel) => (
            <TableRow key={topic.id}>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={topic.id === topicId}
                  onClick={() => {
                    topic.id === topicId ? setTopicId("") : setTopicId(topic.id);
                  }}
                />
              </TableCell>

              <TableCell>
                <Box display="flex" alignItems="center">
                  <Typography variant="body2">{topic?.title}</Typography>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {total > limit + offset && (
        <Button variant="text" endIcon={<ExpandMoreIcon />} onClick={nextPage}>
          Show more
        </Button>
      )}
    </>
  );
};

export default SelectTopic;
