import axios, { type AxiosResponse } from "axios";
import { format } from "date-fns";
import { not, take } from "ramda";
import { EnglishLevel } from "../types/graphql";

const TITLE_CHARACTERS_LIMIT = 82;

export const noop = (): void => {};

export const truncateWithEllipses = (
  title: string,
  limit: number = TITLE_CHARACTERS_LIMIT,
): string => {
  if (title?.length > limit) {
    return `${take(limit, title)}...`;
  }
  return title;
};

export const upload = async (url: string, file: File): Promise<AxiosResponse<any>> => {
  return await axios(url, {
    method: "PUT",
    headers: {
      "Content-Type": file.type,
      "Content-type": "application/octet-stream",
    },
    data: file,
  });
};

export const staticFileUrl = (fileUrl: string | null | undefined): string | undefined => {
  if (fileUrl?.startsWith("http")) {
    return encodeURI(fileUrl);
  }
  if (fileUrl && import.meta.env.VITE_STATIC_URL) {
    return encodeURI(`${import.meta.env.VITE_STATIC_URL}/${fileUrl}`);
  }
  return undefined;
};

export const isThirdPartyTesting = () => {
  return import.meta.env.VITE_THIRD_PARTY_TESTING === "true";
};

export const transformUsersDataToCSV = (responseData: any) => {
  const ALL_EMAILS = "Users Emails";
  const DATE_COLUMNS = [
    "createdAt",
    "birthday",
    "lastOnlineAt",
    "pausedUntil",
    "currentPeriodStart",
    "currentPeriodEnd",
    "resumedAt",
    "cancelledAt",
  ];

  const SUBSCRIPTION_KEYS = [
    "status",
    "plan",
    "currentPeriodStart",
    "currentPeriodEnd",
    "resumesAt",
    "cancelledAt",
  ];
  const EXCLUDED_KEYS = [
    "customers",
    "pauseCredits",
    "showBirthday",
    "completedPercentage",
    "isVisible",
    "wantsNewsletter",
    "isActive",
  ];

  const RENAMED_COLUMN_IS_ACTIVE = "Confirmed Email";
  const mainUserProperties = Object.keys(responseData[0]).filter((columnName) =>
    not(EXCLUDED_KEYS.includes(columnName)),
  );

  const secondaryUserProperties = Object.keys(responseData[0]).filter(
    (columnName) => EXCLUDED_KEYS.includes(columnName) && columnName !== "customers",
  );
  const columnNames = [
    ...mainUserProperties,
    RENAMED_COLUMN_IS_ACTIVE,
    ...SUBSCRIPTION_KEYS,
    ...secondaryUserProperties,
    ALL_EMAILS,
  ];

  let emails: string[] = [];
  responseData.forEach((_item: any) => {});
  const rows = responseData.map((item: any, index: number) =>
    columnNames.map((columnName) => {
      const value = item[columnName];
      if (columnName === "email") {
        emails = [...emails, value];
      }
      if (columnName === RENAMED_COLUMN_IS_ACTIVE) {
        return item.isActive ? "Yes" : "No";
      }
      if (SUBSCRIPTION_KEYS.includes(columnName)) {
        const subscriptionFieldValues = item.customers[0]?.subscriptions
          ?.map((subscription: any) => subscription[columnName])
          .filter((value: number | null) => value != null);
        if (DATE_COLUMNS.includes(columnName) && subscriptionFieldValues) {
          return subscriptionFieldValues
            .map((value: number) => format(value, "yyyy-MM-dd"))
            .join(";");
        }
        if (columnName === "plan") {
          return subscriptionFieldValues?.map((value: any) => value?.item_price_id).join(";");
        }
        return subscriptionFieldValues?.join(";");
      }
      if (columnName === "cambridgeEnglishLevel" && value) {
        const EnglishLevels = Object.values(EnglishLevel);
        return EnglishLevels[value];
      }
      if (columnName === "nativeLanguage") {
        return value?.replaceAll(",", " ");
      }
      if (Array.isArray(value)) {
        const serializedArray = value.map((arrayItem) =>
          JSON.stringify(arrayItem).replace(",", " "),
        );
        return serializedArray.join("; ");
      }
      if (DATE_COLUMNS.includes(columnName) && value) {
        return format(value, "yyyy-MM-dd");
      }
      if (columnName === "occupation" && value) {
        return value.name;
      }
      if (columnName === ALL_EMAILS) {
        return index === responseData.length - 1 ? `"${emails.join(",")}"` : null;
      }
      return value;
    }),
  );

  return [columnNames, ...rows].map((row) => row.join(",")).join("\n");
};

/**
 * Debounce function was removed in Material UI v5.
 * The function below is provided the by Material UI documentation:
 * https://mui.com/x/react-date-pickers/lifecycle/#system-ServerInteraction.tsx
 */
export const debounce = (func: (...arg: any) => void, wait = 500) => {
  let timeout: NodeJS.Timeout;
  function debounced(...args: any) {
    const later = () => {
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  }

  debounced.clear = () => {
    clearTimeout(timeout);
  };

  return debounced;
};
