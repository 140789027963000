import { SnackbarProvider } from "notistack";
import React from "react";

import Apollo from "./Apollo";
import QueryProvider from "./QueryProvider";
import Router from "./Router";
import Theme from "./Theme";

const Root: React.FC = () => {
  return (
    <Theme>
      <QueryProvider>
        <Apollo>
          <SnackbarProvider>
            <Router />
          </SnackbarProvider>
        </Apollo>
      </QueryProvider>
    </Theme>
  );
};

export default Root;
