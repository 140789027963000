import React, { useCallback, useState } from "react";
import * as yup from "yup";

import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormLabel,
  IconButton,
  Link,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";

import CopyLinkToElement from "src/components/Topics/CopyLinkToElement.tsx";
import { useOnOffSwitch, useSortableList } from "../helpers/hooks";
import { Spacing } from "../types/enum";
import { type Element } from "../types/graphql";

/**
 * Types
 */
interface LinkModel {
  title: string;
  href: string;
}

interface Props {
  value: LinkModel[];
  setValue: (links: LinkModel[]) => void;
  parent?: Element;
  setElementMeta: (key: string, value: unknown) => void;
  id: string;
  topicId: string;
  lessonPath: string;
  step?: number | null;
}

/**
 * Schema
 */
const schema = yup.object().shape({
  title: yup.string().required(),
  href: yup.string().required(),
});

const LessonEditPageSectionLinks: React.FC<Props> = ({
  value,
  parent,
  setElementMeta,
  setValue,
  id,
  topicId,
  lessonPath,
  step,
}: Props) => {
  const [open, onOpen, onClose] = useOnOffSwitch();
  const [openEditTitle, onOpenEditTitle, onCloseEditTitle] = useOnOffSwitch();
  const [linksTitle, setLinksTitle] = useState<string>(parent?.meta?.linksTitle || "");

  const { renderer, register, handleSubmit, onSubmit } = useSortableList<LinkModel>({
    schema,
    data: value,
    render: (item) => {
      return (
        <Box display="flex" flexDirection="column">
          <Link href={item.href} target="_blank">
            <Typography variant="body2" color="textPrimary">
              {item.title}
            </Typography>
            <Typography variant="caption" color="textPrimary">
              {item.href}
            </Typography>
          </Link>
        </Box>
      );
    },
    onAfterSubmit: onClose,
    setValue,
    keyFn: (item) => item.href + item.title,
  });

  const updateLinksTitle = useCallback(() => {
    setElementMeta("linksTitle", linksTitle);
    onCloseEditTitle();
  }, [linksTitle, onCloseEditTitle, setElementMeta]);

  return (
    <>
      <FormControl fullWidth>
        <Box display="flex" alignItems="center" mb={Spacing.m} justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <FormLabel>{parent?.meta?.linksTitle ?? "Links"}</FormLabel>
            <Box ml={Spacing.m}>
              <IconButton size="large" onClick={onOpenEditTitle}>
                <SvgIcon fontSize="small">
                  <EditIcon />
                </SvgIcon>
              </IconButton>
            </Box>
          </Box>
          <CopyLinkToElement id={id} topicId={topicId} lessonPath={lessonPath} step={step} />
        </Box>

        {renderer}

        <Box mt={Spacing.s}>
          <Button fullWidth variant="contained" onClick={onOpen}>
            Create link
          </Button>
        </Box>

        <Dialog open={open} onClose={onClose}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
              <Box mb={Spacing.sm}>
                <Typography variant="body1" color="textPrimary">
                  Link
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Please input the link that you would like to add.
                </Typography>
              </Box>

              <Box mb={Spacing.sm}>
                <TextField fullWidth variant="outlined" label="Title" {...register("title")} />
              </Box>

              <TextField fullWidth variant="outlined" label="Link" {...register("href")} />
            </DialogContent>

            <DialogActions>
              <Button onClick={onClose}>Cancel</Button>
              <Button type="submit">Create</Button>
            </DialogActions>
          </form>
        </Dialog>
      </FormControl>

      <Dialog open={openEditTitle} onClose={onCloseEditTitle}>
        <DialogContent>
          <Box my={Spacing.s}>
            <Typography variant="body1" color="textPrimary">
              Links title
            </Typography>
          </Box>
          <FormControl fullWidth>
            <TextField
              label="Title"
              variant="outlined"
              value={linksTitle}
              onChange={(event) => {
                setLinksTitle(event.target.value);
              }}
            />
          </FormControl>
        </DialogContent>

        <DialogActions>
          <Button onClick={onCloseEditTitle}>Cancel</Button>
          <Button
            onClick={() => {
              updateLinksTitle();
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LessonEditPageSectionLinks;
