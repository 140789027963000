import { gql } from "@apollo/client";

export const FILTER_THEMES = gql`
  query adminListPaginatedThemes($filters: AdminFilterThemesInput!) {
    adminListPaginatedThemes(filters: $filters) {
      items {
        title
        id
        isCurrent
        isStandard
        isGrammarLibrary
        isTopicLibrary
        isResource
        topics {
          id
          title
        }
      }
      total
    }
  }
`;

export const QUERY_THEMES = gql`
  query adminListThemes {
    adminListThemes {
      title
      id
      isCurrent
      isStandard
      isGrammarLibrary
      isTopicLibrary
      isResource
      topics {
        id
        title
      }
    }
  }
`;

export const QUERY_THEME_AND_COVERS = gql`
  query adminGetThemeAndCovers($id: String!) {
    adminGetTheme(id: $id) {
      id
      title
      description
      isCurrent
      isStandard
      isGrammarLibrary
      isTopicLibrary
      isResource
      cover {
        id
        title
        primary {
          mobile
          desktop
        }
        secondary {
          mobile
          desktop
        }
        tertiary {
          mobile
          desktop
        }
      }
    }
    adminListCovers {
      id
      title
      primary {
        mobile
        desktop
      }
      secondary {
        mobile
        desktop
      }
      tertiary {
        mobile
        desktop
      }
    }
  }
`;
