import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import { isNil, not } from "ramda";
import React, { useEffect } from "react";
import {
  Controller,
  type ControllerRenderProps,
  type UseFormStateReturn,
  useForm,
} from "react-hook-form";
import * as yup from "yup";

import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormLabel,
  Grid,
  LinearProgress,
  TextField,
} from "@mui/material";

import { EDIT_ILLUSTRATION } from "../gql/mutations/illustration";

import { Sizing, Spacing } from "../types/enum";
import { type AttachmentModel } from "../types/generic";
import { type EditIllustrationInput, type IllustrationModel } from "../types/graphql";

import FileDropzone from "../components/FileDropzone";

/**
 * Types
 */
interface Props {
  illustration?: IllustrationModel;
}

interface FormValues {
  title: string;
  url: string;
}

/**
 * Schema
 */
const schema = yup.object().shape({
  title: yup.string().required(),
  url: yup.string().required(),
});

const IllustrationEditFormCont: React.FC<Props> = ({ illustration }: Props) => {
  const [editIllustration, { loading }] = useMutation(EDIT_ILLUSTRATION);

  const { register, control, formState, handleSubmit, trigger } = useForm<FormValues>({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      title: illustration?.title,
      url: illustration?.url ?? "",
    },
  });

  useEffect(() => {
    void trigger();
  }, [trigger]);

  const { enqueueSnackbar } = useSnackbar();

  const renderers = {
    file: ({
      field: { value, onChange },
      formState,
    }: {
      field: ControllerRenderProps<FormValues, "url">;
      formState: UseFormStateReturn<FormValues>;
    }) => {
      const error = not(isNil(formState?.errors?.url));

      return (
        <FileDropzone
          preview
          fileName={value}
          error={error ? "This field is required" : undefined}
          onDropFile={(attachment: AttachmentModel) => {
            onChange(attachment?.url);
          }}
        />
      );
    },
  };

  const onSubmit = async (form: EditIllustrationInput): Promise<void> => {
    const variables = {
      id: illustration?.id,
      input: {
        title: form?.title,
        url: form?.url,
      },
    };

    try {
      await editIllustration({ variables });
      enqueueSnackbar("Illustration successfully updated", { variant: "success" });
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return (
    <Card>
      {loading ? <LinearProgress /> : null}

      <CardContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid spacing={Spacing.m} container>
            <Grid item xs={Sizing.Full}>
              <FormControl fullWidth>
                <TextField
                  label="Title"
                  variant="outlined"
                  {...register("title")}
                  error={not(isNil(formState?.errors?.title))}
                  helperText={
                    not(isNil(formState?.errors?.title)) ? "This field is required" : undefined
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={Sizing.Full}>
              <FormControl fullWidth>
                <Box mb={Spacing.s}>
                  <FormLabel>Image</FormLabel>
                </Box>
                <Controller name="url" control={control} render={renderers.file} />
              </FormControl>
            </Grid>

            <Grid item xs={Sizing.Full}>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                disabled={not(formState.isValid)}
                fullWidth
              >
                Update illustration
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export default IllustrationEditFormCont;
