import { gql } from "@apollo/client";

export const SEARCH_EVENTS = gql`
  query adminSearchEvents($filters: AdminFilterEventsInput!) {
    adminSearchEvents(filters: $filters) {
      items {
        title
        id
        endDate
        coverUrl
        startDate
        organizer {
          id
          firstName
          lastName
        }
      }
      total
    }
  }
`;

export const GET_EVENT = gql`
  query getEventAdmin($id: String!) {
    getEventAdmin(id: $id) {
      id
      coverUrl
      createdAt
      description
      endDate
      isDraft
      isOnline
      isPublic
      kind
      meta
      location
      noParticipants
      recurring
      startDate
      title
      updatedAt
      videoUrl
      isForUnverified
      permissions
      englishLevel
      organizer {
        id
        name
        avatarUrl
      }
    }
  }
`;

export const GET_EVENT_AND_TOPICS = gql`
  query getEventAndTopics($id: String!) {
    getEventAdmin(id: $id) {
      id
      coverUrl
      createdAt
      description
      endDate
      eventCategory
      host {
        kind
      }
      isDraft
      isDeleted
      isOnline
      isPublic
      kind
      meta
      location
      noParticipants
      recurring
      englishLevel
      isForUnverified
      permissions
      englishLevel
      startDate
      title
      updatedAt
      videoUrl
      organizer {
        id
        name
        avatarUrl
      }
      topic {
        id
        title
      }
    }
    adminListTopics {
      id
      title
    }
  }
`;

export const GET_EVENT_PARTICIPANTS = gql`
  query getEventParticipants($id: String!) {
    getEventAdmin(id: $id) {
      id
      participants {
        id
        name
        createdAt
        email
        city
        isVerified
        country
        avatarUrl
        badge
      }
      invited {
        id
        name
        createdAt
        email
        city
        isVerified
        country
        avatarUrl
        badge
      }
      declined {
        id
        name
        createdAt
        email
        city
        isVerified
        country
        avatarUrl
        badge
      }
    }
  }
`;

export const GET_EVENT_COMMENTS = gql`
  query getEventComments($id: String!) {
    getEventAdmin(id: $id) {
      id
      comments {
        id
        message
        author {
          id
          avatarUrl
          name
        }
      }
    }
  }
`;
